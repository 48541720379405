import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { ServiceOrder } from '@secca/shared/models/service-order/service-order';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { RecoveryViewItem } from '@secca/shared/models/recovery-view-Item';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-recovery-item',
  templateUrl: './recovery-item.component.html',
  styleUrls: ['./recovery-item.component.scss'],
})
export class RecoveryItemComponent implements OnInit {
  @Output() deleteRecoveryItem: EventEmitter<InvoiceItemEvent> = new EventEmitter();
  @Output() invoiceNumberChange: EventEmitter<InvoiceItemEvent> = new EventEmitter();
  @Output() remittanceAdviceChange: EventEmitter<RemittanceItemEvent> = new EventEmitter();
  @Output() itemChange: EventEmitter<void> = new EventEmitter();

  @Input() showDelete: boolean;
  @Input() caseCreatedDate: Date;
  @Input() itemIndex: number;
  @Input() invoiceNumbers: DropdownDictionary[];
  @Input() remittanceAdvices: DropdownDictionary[];
  @Input() recoveryItem: RecoveryViewItem;
  @Input() serviceOrdersForRecovery: ServiceOrder[];
  @Input() caseId: string;
  @Input() isDisabled: boolean;
  @Input() deleteable: boolean;
  @Input() swedishOrFinnishRecovery: boolean = false;

  today: moment.Moment = moment.utc();

  currencyMaskOptions = {
    prefix: '',
    thousands: '.',
    decimal: ',',
    precision: 2,
    allowNegative: true,
    inputMode: CurrencyMaskInputMode.NATURAL,
  };

  constructor(
    public caseLockHelperService: CaseLockHelperService,
    public caseStateService: CaseStateService
  ) {}

  ngOnInit() {
  }

  focusElement(event: any) {
    event.target.select();
  }

  onInvoiceNumberChange(invoiceNumber: string) {
    if (!!invoiceNumber) {
      this.invoiceNumberChange.emit(this.createInvoiceItemEvent(invoiceNumber));
      this.itemChange.emit();
    }
  }

  onRemittanceAdviceChange(remittanceAdviceDocumentId: string) {
    this.recoveryItem.remittanceAdviceDocumentId = remittanceAdviceDocumentId;
    this.remittanceAdviceChange.emit(this.createRemittanceItemEvent(remittanceAdviceDocumentId));
    this.itemChange.emit();
  }

  isinvoiceNumberDropDownDisabled() {
    return this.caseStateService.isCaseClosed;
  }

  deleteItem(invoiceNumber: string) {
    this.deleteRecoveryItem.emit(this.createInvoiceItemEvent(invoiceNumber));
  }

  getHeightOfDeletebutton() {
    return 2;
  }

  showDeleteButton(): boolean {
    return this.recoveryItem?.isInvoiceItemGroupStart && !this.isRecoveryDisabled() && this.deleteable;
  }

  includeInvoiceItemClick(value: boolean) {
    this.recoveryItem.isInvoiceItemIncluded = value;
    this.itemChange.emit();
  }

  getServiceOrderName(serviceOrderId: string) {
    return !!serviceOrderId ? serviceOrderId + ' (' + this.getServiceOrderType(serviceOrderId) + ')' : '';
  }

  isRecoveryDisabled() {
    return this.isDisabled;
  }

  private getServiceOrderType(serviceOrderId: string) {
    return this.serviceOrdersForRecovery?.find(serviceOrder => serviceOrder.serviceOrderId === serviceOrderId)?.type;
  }

  private createInvoiceItemEvent(invoiceNumber: string) {
    return new InvoiceItemEvent({invoiceNumber, itemIndex: this.itemIndex})
  }

  private createRemittanceItemEvent(remittanceAdviceDocumentId: string) {
    return new RemittanceItemEvent({invoiceNumber:this.recoveryItem.supplierInvoiceNumber, remittanceAdviceDocumentId: remittanceAdviceDocumentId})
  }
}

export class InvoiceItemEvent {
  itemIndex: number;
  invoiceNumber: string;

  constructor(item?: Partial<InvoiceItemEvent>) {
    Object.assign(this, item);
  }
}

export class RemittanceItemEvent {
  invoiceNumber: string;
  remittanceAdviceDocumentId: string;

  constructor(item?: Partial<RemittanceItemEvent>) {
    Object.assign(this, item);
  }
}
