import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CaseEmail, CaseEmailAdapter } from './../../shared/models/case-email';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageFromAddress, MessageFromAddressAdapter } from '@secca/shared/models/message-from-address';
import {DeleteReason} from '@secca/shared/models/deleteReason';

@Injectable({
  providedIn: 'root'
})
export class CaseCommunicationService extends BaseService {

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private caseEmailAdapter: CaseEmailAdapter,
    private messageFromAddressAdapter: MessageFromAddressAdapter
  ) {
    super(settingsService);
  }

  public getCaseEmail(emailId: string): Observable<CaseEmail> {
    return this.http.get(this.baseURL + 'mails/by-email-id/' + emailId).pipe(map(item => this.caseEmailAdapter.adapt(item)));
  }

  public updateCaseEmail(caseEmail: CaseEmail): Observable<CaseEmail> {
    return this.http.put(this.baseURL + 'mails/update-case-email', caseEmail, {
      headers: this.jsonHeaders
    }).pipe(map(item => this.caseEmailAdapter.adapt(item)));
  }

  public deleteCaseEmail(emailId: number): Observable<void> {
    return this.http.delete(this.baseURL + 'mails/delete-case-email/' + emailId, {
      headers: this.jsonHeaders
    }).pipe(map(item => null));
  }

  public deleteCaseEmailWithDeleteReason(emailId: number, reason: string): Observable<void> {
    const deleteReasonObj = new DeleteReason({reason});
    return this.http.put(this.baseURL + 'mails/delete-case-email-delete-reason/' + emailId, deleteReasonObj, {
      headers: this.jsonHeaders
    }).pipe(map(item => null));
  }

  public editCaseHistoryWithDeleteReason(caseId: number, entryId: number, reason: string): Observable<void> {
    const deleteReasonObj = new DeleteReason({reason});
    return this.http.put(this.baseURL + 'casehistory/edit-case-history-delete-reason/' + caseId + '/' + entryId, deleteReasonObj, {
      headers: this.jsonHeaders
    }).pipe(map(item => null));
  }

  public deleteCaseHistoryDelete(caseId: number, entryId: number): Observable<void> {
    return this.http.delete(this.baseURL + 'casehistory/delete-case-history-delete/' + caseId + '/' + entryId, {
      headers: this.jsonHeaders
    }).pipe(map(item => null));
  }

  public deleteCaseFax(faxId: number): Observable<void> {
    return this.http.delete(this.baseURL + 'fax/delete-case-fax/' + faxId, {
      headers: this.jsonHeaders
    }).pipe(map(item => null));
  }

  public deleteCaseFaxWithDeleteReason(faxId: number, reason: string): Observable<void> {
    const deleteReasonObj = new DeleteReason({reason});
    return this.http.put(this.baseURL + 'fax/delete-case-fax-delete-reason/' + faxId, deleteReasonObj, {
      headers: this.jsonHeaders
    }).pipe(map(item => null));
  }

  public updateCaseFax(caseEmail: CaseEmail): Observable<CaseEmail> {
    return this.http.put(this.baseURL + 'fax/update-case-fax', caseEmail, {
      headers: this.jsonHeaders
    }).pipe(map(item => this.caseEmailAdapter.adapt(item)));
  }

  public updateCaseMessage(caseEmail: CaseEmail): Observable<CaseEmail> {
    return this.http.put(this.baseURL + 'sms/update-case-sms', caseEmail, {
      headers: this.jsonHeaders
    }).pipe(map(item => this.caseEmailAdapter.adapt(item)));
  }

  public getEmailBody(emailId: string): Observable<string> {
    return this.http.get<string>(this.baseURL + 'documents/email/sent/' + emailId);
  }

  public getCaseFax(faxId: string): Observable<CaseEmail> {
    return this.http.get(this.baseURL + 'fax/by-fax-id/' + faxId).pipe(map(item => this.caseEmailAdapter.adapt(item)));
  }

  public getCaseMessage(messageId: string): Observable<CaseEmail> {
    return this.http.get(this.baseURL + 'sms/by-sms-id/' + messageId).pipe(map(item => this.caseEmailAdapter.adapt(item)));
  }

  public getEmailAddresses(): Observable<MessageFromAddress[]> {
    return this.http.get<MessageFromAddress[]>(this.baseURL + 'mails/from-addresses').pipe(
      map(items =>
            items.map(item => this.messageFromAddressAdapter.adapt(item))
      )
    );
  }
  public getEmailAddressesByFeature(feature: string): Observable<MessageFromAddress[]> {
    return this.http.get<MessageFromAddress[]>(this.baseURL + 'mails/from-addresses/' + feature).pipe(
      map(items =>
            items.map(item => this.messageFromAddressAdapter.adapt(item))
      )
    );
  }
}
