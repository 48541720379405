import { StakeholderService } from '@secca/core/services/stakeholder.service';
import { UserService } from './../../../../../core/services/user.service';
import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UserDto } from '../../../../../shared/models/userDto';
import { MedicalHistory } from '../../../../../shared/models/medicalHistory';
import { AssessmentUsedInServiceOrders } from '../../../../../shared/models/medical-models/assessmentUsedInServiceOrders';
import { MedicalService } from '../../../../../core/services/medical.service';
import { ServiceOrderService } from '../../../../../core/services/service-order.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuditlogService } from 'src/app/core/services/auditlog.service';
import { DictionaryService } from 'src/app/core/services/dictionary.service';
import { Subscription } from 'rxjs';
import { Case } from '@secca/shared/models/case';
import { TranslateService } from '@ngx-translate/core';
import { AssessmentTypeEnum, MedicalHistoryType, MedicalNoteTypeEnum, PermissionEnum } from '@secca/shared/models/enums';
import { DialogViewerServiceInterface } from '@secca/core/services/dialog-viewer-service-interface';
import { DIALOG_VIEWER_TOKEN } from '@secca/core/services/token';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { ModalDialogComponent } from '@secca/shared/components/modal-dialog/modal-dialog.component';
import { ModalDialogConfiguration } from '@secca/shared/models/modal/modal-dialog-configuration';
import * as moment from 'moment';
import { SettingsService } from '@secca/core/services/settings.service';
import {MedicalPreAssessmentService} from "@secca/core/services/medical-pre-assessment.service";

@Component({
  selector: 'app-case-medical-history',
  templateUrl: './case-medical-history.component.html',
  styleUrls: ['./case-medical-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CaseMedicalHistoryComponent implements OnInit {
  _case: Case;
  private user: UserDto;

  @Input()
  set medicalHistory(medicalHistory: MedicalHistory[]) {
    this.thisMedicalHistory = medicalHistory;
    if (this.thisMedicalHistory !== undefined) {
      this.getListOfassessmentServiceOrderRelations();
    }

  }
  get medicalHistory(): MedicalHistory[] {
    return this.thisMedicalHistory;
  }

  @Input()
  set case(newCase: Case) {
    if (newCase !== undefined) {
      this._case = newCase;
    }
  }

  get case(): Case {
    return this._case;
  }

  get MedicalHistoryType() {
    return MedicalHistoryType;
  }

  thisMedicalHistory: MedicalHistory[];
  userImageURL: string;
  medicalAssessmentId: number;
  preAssessmentId: number;
  $loggedUserSubscr: Subscription;
  medicalDeleteAllowed: boolean;
  medicalPreDepartureDeleteAllowed: boolean;
  disablePopover = false;
  listOfmedicalAssessments: number[] = [];
  assessmentUsedInServiceOrders: AssessmentUsedInServiceOrders[];

  constructor(private medicalService: MedicalService,
              private modalService: NgbModal,
              private auditlogService: AuditlogService,
              private dictionaryService: DictionaryService,
              private translateService: TranslateService,
              private userService: UserService,
              private stakeholderService: StakeholderService,
              private dialogViewerService: CaseDialogViewerService,
              private permissionService: PermissionService,
              private serviceOrderService: ServiceOrderService,
              public settingsService: SettingsService,
              private medicalPreAssessmentService: MedicalPreAssessmentService) { }

  ngOnInit() {
    this.medicalDeleteAllowed = this.permissionService.checkUserPermission(PermissionEnum.MEDICAL_DELETE);
    this.medicalPreDepartureDeleteAllowed = this.permissionService.checkUserPermission(PermissionEnum.MEDICAL_PRE_ASSESSMENT_DELETE);

    this.$loggedUserSubscr = this.dictionaryService.getLoggedInUser().subscribe(user => {
      if (user != null) {
        this.user = user;
      }
    });
  }

  getListOfassessmentServiceOrderRelations() {
    if (this.medicalDeleteAllowed) {
      this.medicalHistory.forEach(item => {
        if (item.medicalHistoryType === MedicalHistoryType.regularMedicalAssessment) {
          this.listOfmedicalAssessments.push(item.id);
        }
      });

      this.serviceOrderService.getServiceOrdersUsedInMedicalAssessments(this.listOfmedicalAssessments).subscribe(
        result => {
          this.assessmentUsedInServiceOrders = result;
          this.medicalHistory.forEach(history => {
            if (history.medicalHistoryType === MedicalHistoryType.regularMedicalAssessment) {
              history.connectedServiceOrderIds = result.find(a => a.medicalAssessmentId === history.id).serviceOrderIds;
            }
          });
        }
      );
    }
    this.sortHistory();
  }

  sortHistory() {
    let idxTotal = this.medicalHistory.length;
    this.medicalHistory.forEach(item => {
      item.idx = idxTotal--;
    });
  }

  getUserImage(medicalHistory: MedicalHistory) {
    if (medicalHistory.medicalHistoryType === MedicalHistoryType.medicalReportSupplierPortal) {
      const stakeholderType = this.case.caseStakeholders.find(stakeholder => stakeholder.id === medicalHistory.reportSupplierPortal.caseStakeholderId)?.stakeholderType;
      if (stakeholderType) {
        return '/assets/icons/' + this.stakeholderService.addIconToCaseStakeholder(stakeholderType) + '.svg';
      }
    }
    if (medicalHistory.userInfo.userActive) {
      if (medicalHistory.userInfo.imageIsPresent) {
        return this.userService.getUserImagesUrl(medicalHistory.userInfo.userId.toString());
      } else {
        return '/assets/icons/Medical Person.svg';
      }
    } else {
      return '/assets/icons/DeactivatedUser.svg';
    }
  }

  headerClass(assessmentTypeEnum: AssessmentTypeEnum): string {
    let returnValue = '';
    switch ( assessmentTypeEnum ) {
      default:
      case AssessmentTypeEnum.LIGHT:
      case AssessmentTypeEnum.NIGHT:
      case AssessmentTypeEnum.REGULAR:
      case AssessmentTypeEnum.PREASSESSMENT:
        returnValue = 'medical-assessment';
          break;
      case AssessmentTypeEnum.CLAIMS:
        returnValue = 'claims-medical-review';
          break;
    }
    return returnValue;
  }

  resolveHeaderStringFromType(medicalHistory: MedicalHistory) {
    let withText = '';
    if (medicalHistory.repatriationInstructions) {
      withText = this.translateService.instant('medical-history-with-repatriation-instructions');
    }
    if (medicalHistory.repatriationPrognosis) {
      withText = this.translateService.instant('medical-history-with-repatriation-prognosis');
    }
    if (medicalHistory.decision) {
      withText = ' - ' + this.translateService.instant('medical-pre-assessment-decison-' + medicalHistory.decision);
    }

    return this.translateService.instant('medical-history-' + medicalHistory.medicalAssessmentType + '-assessment') + withText;
  }

  resolveNoteHeaderStringFromType(medicalNoteTypeEnum: MedicalNoteTypeEnum) {
    return this.translateService.instant('medical-history-note-' + medicalNoteTypeEnum);
  }

  openAssessment(id, index) {
    this.medicalAssessmentId = id;
    this.logMedicalAssessmentView(index);
    this.dialogViewerService.openMedicalAssessmentDialog(this.case, null, null, null, this.medicalAssessmentId, true);
  }

  openPreAssessment(id, index) {
    this.preAssessmentId = id;
    this.logPreAssessmentView(index);
    this.dialogViewerService.openPreMedicalAssessmentDialog(this.case, null, null, null, this.preAssessmentId, true);
  }



  deleteAssessment(item: MedicalHistory, deleteAllowed: boolean, index: number) {
    if (deleteAllowed) {
      const showCloseWarningMessageDialog = this.showCloseWarningMessageDialogAssessment();
      showCloseWarningMessageDialog.componentInstance.saveandcloseEvent.subscribe(emittedEvent => {
        if(emittedEvent) {
          this.medicalService.deleteMedicalAssessment(item.id, item.idx, emittedEvent).subscribe(
            result => {
              this.updateHistoryListAfterDelete(index);
            }
          );
        }
        showCloseWarningMessageDialog.close();
      });

      showCloseWarningMessageDialog.componentInstance.closeModalEvent.subscribe(
        emittedEvent => {
          showCloseWarningMessageDialog.close();
        },
        err => console.log(err)
      );
    }
  }

  deleteNote(item: MedicalHistory, index: number ) {
    let showCloseWarningMessageDialog;

    if (item.medicalNoteType === MedicalNoteTypeEnum.ADMISSION) {
      showCloseWarningMessageDialog = this.showCloseWarningMessageDialogAdmission();
    } else {
      showCloseWarningMessageDialog = this.showCloseWarningMessageDialogComment();
    }
    showCloseWarningMessageDialog.componentInstance.saveandcloseEvent.subscribe(emittedEvent => {
      if (emittedEvent) {
        this.medicalService.deleteMedicalNote(item.id, item.idx, emittedEvent).subscribe(
          result => {
            this.updateHistoryListAfterDelete(index);
          }
        );
      }
      showCloseWarningMessageDialog.close();
    });

    showCloseWarningMessageDialog.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        showCloseWarningMessageDialog.close();
      },
      err => console.log(err)
    );
  }

  deleteMedicalReport(item: MedicalHistory, index: number) {

    const showCloseWarningMessageDialog = this.showCloseWarningMessageDialogMedicalReport();
    showCloseWarningMessageDialog.componentInstance.saveandcloseEvent.subscribe(emittedEvent => {
      if (emittedEvent) {
        this.medicalService.deleteMedicalReport(item.reportSupplierPortal.id, item.idx, emittedEvent).subscribe(
          result => {
            this.updateHistoryListAfterDelete(index);
          }
        );
      }
      showCloseWarningMessageDialog.close();
    });

    showCloseWarningMessageDialog.componentInstance.closeModalEvent.subscribe(
      emittedEvent => {
        showCloseWarningMessageDialog.close();
      },
      err => console.log(err)
    );
  }

  deletePreAssessment(item: MedicalHistory, deleteAllowed: boolean, index: number) {
    if (deleteAllowed) {
      const showCloseWarningMessageDialog = this.showCloseWarningMessageDialogPreAssessment();
      showCloseWarningMessageDialog.componentInstance.saveandcloseEvent.subscribe(emittedEvent => {
        if(emittedEvent) {
          this.medicalService.deletePreAssessment(item.id, item.idx, emittedEvent).subscribe(
            result => {
              this.medicalPreAssessmentService.setMedicalPreAssessmentDecisionChanges(+this.case.id, result?.decision ? result.decision : null)
              this.updateHistoryListAfterDelete(index);
            }
          );
        }
        showCloseWarningMessageDialog.close();
      });

      showCloseWarningMessageDialog.componentInstance.closeModalEvent.subscribe(
        emittedEvent => {
          showCloseWarningMessageDialog.close();
        },
        err => console.log(err)
      );
    }
  }

  updateHistoryListAfterDelete(index: number) {
    this.medicalHistory[index].deletedOn = moment();
    this.sortHistory()
  }

  popOverText(id: number, serviceOrderIds: number[]) {
    const params = {
      medicalAssessmentId: id,
      serviceOrderId: serviceOrderIds
    };
    return this.translateService.instant('medical-history-popover-text', params);
  }

  logMedicalAssessmentView(index) {
    const logText = 'Case: ' + this.case.id + '. ' + this.user.initials.toUpperCase()
      + ' has viewed medical assesment with id ' + this.medicalAssessmentId + ' - index ' + (index + 1);
    this.auditlogService.createAuditlog(logText).subscribe(
      result => (result), error => console.log(error));
  }

  logPreAssessmentView(index) {
    const logText = 'Case: ' + this.case.id + '. ' + this.user.initials.toUpperCase()
      + ' has viewed pre-departure assesment with id ' + this.preAssessmentId + ' - index ' + (index + 1);
    this.auditlogService.createAuditlog(logText).subscribe(
      result => (result), error => console.log(error));
  }

  getMedicalNoteTextSplited(medicalNoteText) {
    return !!medicalNoteText ? medicalNoteText.split('\n') : '';
  }

  getCaseStakeholderName(item: MedicalHistory): string {
    return this.case.caseStakeholders.find(stakeholder => stakeholder.id === item.reportSupplierPortal.caseStakeholderId)?.companyName
  }

  openRawView(item: MedicalHistory) {
    this.dialogViewerService.openMedicalReportSupplierPortalDialog(item.reportSupplierPortal);
  }

  private showCloseWarningMessageDialogAssessment() : NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'medical-history-delete-assessment-warning-modal',
      labelTextAboveTextArea: 'medical-history-deleted-reason-for-deleting-medical-assessment',
      text: '',
      showTextArea: true,
      isBody: false,
      isFooter: true,
      placeholderText: 'text-inside-no-sensitive-data-text',
      saveandclose: 'medical-history-deleted-and-close',
    });
    return confirmCloseDialog;
  }

  private showCloseWarningMessageDialogAdmission() : NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'medical-history-delete-admission-warning-modal',
      labelTextAboveTextArea: 'medical-history-deleted-reason-for-deleting-medical-assessment',
      text: '',
      showTextArea: true,
      isBody: false,
      isFooter: true,
      placeholderText: 'text-inside-no-sensitive-data-text',
      saveandclose: 'medical-history-deleted-and-close',

    });
    return confirmCloseDialog;
  }

  private showCloseWarningMessageDialogComment() : NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'medical-history-delete-comment-warning-modal',
      labelTextAboveTextArea: 'medical-history-deleted-reason-for-deleting-medical-assessment',
      text: '',
      showTextArea: true,
      isBody: false,
      isFooter: true,
      placeholderText: 'text-inside-no-sensitive-data-text',
      saveandclose: 'medical-history-deleted-and-close',
    });
    return confirmCloseDialog;
  }

  private showCloseWarningMessageDialogMedicalReport() : NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'medical-history-delete-medical-report-warning-modal',
      labelTextAboveTextArea: 'medical-history-deleted-reason-for-deleting-medical-assessment',
      text: '',
      showTextArea: true,
      isBody: false,
      isFooter: true,
      placeholderText: 'text-inside-no-sensitive-data-text',
      saveandclose: 'medical-history-deleted-and-close',
    });
    return confirmCloseDialog;
  }

  private showCloseWarningMessageDialogPreAssessment() : NgbModalRef {
    const confirmCloseDialog = this.modalService.open(ModalDialogComponent);
    confirmCloseDialog.componentInstance.configuration = new ModalDialogConfiguration({
      header: 'medical-history-delete-pre-departure-warning-modal',
      labelTextAboveTextArea: 'medical-history-deleted-reason-for-deleting-medical-assessment',
      text: '',
      showTextArea: true,
      isBody: false,
      isFooter: true,
      placeholderText: 'text-inside-no-sensitive-data-text',
      saveandclose: 'medical-history-deleted-and-close',
    });
    return confirmCloseDialog;
  }
}
