import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DocumentService } from '@secca/core/services/document.service';
import { DocumentTemplate } from '@secca/shared/models/document-template';
import {
  DocumentTemplateFilter
} from '@secca/case/components/case-plans/case-plans/add-service-order/sub-components/sub-gop-details/documentTemplateFilter';
import { Message } from '@secca/case/components/case-output-management/models/message';
import { AttachmentTypeEnum, MessageChannelType, MessageFrom, RecoveryStatus } from '@secca/shared/models/enums';
import { OutputManagementService } from '@secca/case/components/case-output-management/services/output-management.service';
import { CaseMessageService } from '@secca/core/services/case-message.service';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { CaseService } from '@secca/core/services/case.service';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { AttachmentDto } from '@secca/shared/models/attachment-dto';
import { RecoveryState } from '../add-recovery/add-recovery.component';
import { Recovery } from '@secca/shared/models/recovery';
import { RecoveryService } from '@secca/core/services/recovery.service';
import { RecoveryViewItem } from '@secca/shared/models/recovery-view-Item';
import { Observable, tap } from 'rxjs';
import { FileService } from '@secca/core/services/file.service';
import {saveAs} from 'file-saver-es';
import { Case } from '@secca/shared/models/case';
import { PostHandlingsDto } from '@secca/shared/models/post-handlings-dto';

@Component({
  selector: 'app-recovery-document',
  templateUrl: './recovery-document.component.html',
  styleUrls: ['./recovery-document.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RecoveryDocumentComponent implements OnInit {

  @Input() recoveryState: RecoveryState;
  @Input() disabled: boolean;
  @Input() recoveryItems: RecoveryViewItem[];
  @Input() currentCase : Case;
  @Input() set recovery(value: Recovery) {
    this._recovery = value;
    this.checkTemplateReference();
  }
  get recovery() {
    return this._recovery;
  }
  private _recovery: Recovery;

  @Output() applicationPreviewed = new EventEmitter<void>();
  @Output() closeRecoveryModal = new EventEmitter<void>();
  @Output() templateChange = new EventEmitter<void>();

  templateNames = [];
  templateLanguages = [];
  private fullTemplateList;
  private templatesWithLanguages = {};

  constructor(private documentService: DocumentService,
              private outputManagementService: OutputManagementService,
              private dialogViewerService: CaseDialogViewerService,
              private caseMessageService: CaseMessageService,
              private caseService: CaseService,
              private recoveryService: RecoveryService,
              private fileService: FileService,
              public caseStateService: CaseStateService,
              public caseLockHelperService: CaseLockHelperService) {}

  ngOnInit(): void {
    const filter = new DocumentTemplateFilter('DOCUMENT', ['RECOVERY_POA_REQUEST','RECOVERY_APPLICATION'], null);
    this.documentService.postTemplatesByType(filter).subscribe(recoveryTemplates => {
      this.fullTemplateList = recoveryTemplates;
      this.mapToTemplateNameWithLanguagesList(recoveryTemplates);
      this.checkTemplateReference();
    });
  }

  checkTemplateReference() {
    if (this._recovery == null || this.fullTemplateList == null) {
      return;
    }
    if (!this.fullTemplateList.map(template => template.templateId).includes(this._recovery?.templateId)) {
      this.recovery.templateId = '';
      this.recovery.templateName = '';
      this.recovery.templateLanguage = '';
    } else {
      this.selectTemplateLanguages(this.recovery.templateName);
    }
  }

  selectTemplateLanguages(selectedTemplateName: string) {
    this.templateLanguages = this.templatesWithLanguages[selectedTemplateName];
    this.recovery.templateId = this.getTemplateId();
    this.templateChange.emit();
  }

  selectTemplateId() {
    this.recovery.templateId = this.getTemplateId();
    this.templateChange.emit();
  }

  preview(): Observable<void> {
    return this.recoveryService.preview(this.recovery.id).pipe(
      tap(data => {
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      this.dialogViewerService.openPreviewDocumentDialog(this.recovery.id, fileURL, this.currentCase.id, this.currentCase.caseNumber);
      this.applicationPreviewed.emit();
    }));
  }

  send(): Observable<void> {
    return this.recoveryService.store(this.recovery.id).pipe(
      tap(documentId => {
        const message = new Message();
        message.templateType = 'RECOVERY_APPLICATION';
        message.caseId = '' + this.recovery.caseId;
        message.messageChannelType = MessageChannelType.EMAIL;
        message.messageFrom = MessageFrom.EMAIL_SENDER_RECOVERY;
        message.recoveryId = this.recovery.id;
        message.postHandlings = new PostHandlingsDto({feature: 'RECOVERY_APPLICATION', recoveryId: this.recovery.id});
        if (documentId) {
          message.preSelectDocumentAttachments = [new AttachmentDto({documentId: documentId,
            attachmentType: AttachmentTypeEnum.GENERATED_FROM_TEMPLATE}) ];
        }

        const srMessage = new ServiceOrderMessageRequest({
          messageService: this.caseMessageService,
          message
        });

        this.openOuputManagementDialog(message, srMessage);
      }
    ));
  }

  save(): Observable<any> {
    return this.recoveryService.store(this.recovery.id);
  }

  private getTemplateId() {
    const template = this.fullTemplateList.find(
      templateResult =>
        templateResult.templateLanguage === this.recovery.templateLanguage &&
        templateResult.templateName === this.recovery.templateName
    );
    if (template) {
      return template.templateId;
    }
    return '';
  }

  private mapToTemplateNameWithLanguagesList(recoveryTemplates: DocumentTemplate[]) {
    const templateNames = new Set();
    recoveryTemplates.forEach(template => {
      if (this.templatesWithLanguages[template.templateName] == null) {
        this.templatesWithLanguages[template.templateName] = [];
      }
      this.templatesWithLanguages[template.templateName].push(template.templateLanguage);
      templateNames.add(template.templateName);
    });
    this.templateNames = Array.from(templateNames);
  }

  private openOuputManagementDialog(message: Message, srMessage: ServiceOrderMessageRequest) {
    this.caseService.getCase('' + this.recovery.caseId).subscribe(
      result => {
        message.caseNumber = '[' + result.caseNumber + '#' + this.recovery.id + ']';
        this.dialogViewerService.openOutputManagementDialog(result, message.messageChannelType, srMessage, null);
        this.closeRecoveryModal.emit();
      }
    );
  }
}
