import { IncidentStateService } from '@secca/core/state-services/incident-state-service';
import { EventCategory, ValidationTypeEnum, ShortcutEnum, TransportAssistanceType } from '@secca/shared/models/enums';
import { SimpleIncidentAdapter } from '@secca/shared/models/SimpleIncident';
import { Case } from '@secca/shared/models/case';
import { CaseStateService } from '@secca/core/state-services/case-state.service';
import { DictionaryService } from '@secca/core/services/dictionary.service';
import { Icd10Code } from '@secca/shared/models/icd10Code';
import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { IncidentService } from '@secca/core/services/incident.service';
import { CaseIncident } from 'src/app/shared/models/caseIncident';
import { CaseLocalTimeService } from '@secca/case/components/case-local-time/case-local-time.service';
import { UpdateTimeLineService } from 'src/app/core/services/updateTimeLine.service';
// noinspection ES6UnusedImports
import { DropDownComponent } from 'src/app/shared/components/drop-down/drop-down.component';
import { HelperService } from '@secca/core/services/helper.service';
import * as moment from 'moment';
import { CaseLockHelperService } from '@secca/core/services/case-lock-helper.service';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from '@secca/shared/decorators/auto-unsubscribe';
import { CaseValidationService } from '@secca/core/services/case-validation.service';
import { PermissionService } from '@secca/core/services/permission.service';
import { CausesLevel1DD } from '@secca/shared/models/CausesLevel1DD';
import { CausesLevel2DD } from '@secca/shared/models/CausesLevel2DD';
import { CausesLevel3DD } from '@secca/shared/models/CausesLevel3DD';
import { DisableableDropdownDictionary, DropdownDictionary } from '@secca/shared/models/dropdownDictionary';
import { MasterListService } from '@secca/core/services/masterlist-service';
import { IncidentEvent } from '@secca/shared/models/incidentEvent';
import { ShortcutService } from '@secca/core/services/shortcut.service';
import { TranslateService } from '@ngx-translate/core';
import { Icd10CodeWithStyle } from '@secca/shared/models/icd10CodeWithStyle';
import { CaseTypeCodes } from '@secca/shared/models/case-type';

export enum LocationTypes {
  CURRENT = 'CURRENT',
  INCIDENT = 'INCIDENT',
}
@AutoUnsubscribe
@Component({
  selector: 'app-case-basics-incident-details',
  templateUrl: './case-basics-incident-details.component.html',
  styleUrls: ['./case-basics-incident-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@AutoUnsubscribe
export class CaseBasicsIncidentDetailsComponent implements OnInit {
  locationTypes = LocationTypes;
  icd10Dictionary: Icd10Code[];
  selectedDiagnosis: Icd10Code[] = [];
  selectedSymptoms: Icd10Code[] = [];
  incident: CaseIncident;
  incidentCausesLevel1: CausesLevel1DD[];
  incidentCausesLevel2: CausesLevel2DD[];
  incidentCausesLevel3: CausesLevel3DD[];
  incidentLocationIsValid: boolean;
  currentLocationIsValid: boolean;
  medicalDiagnosis: boolean;
  medicalSymptoms: boolean;
  medicalPreAssessmentDiagnosis: boolean;
  medicalPreAssessmentSymptoms: boolean;
  tomorrow: moment.Moment = moment.utc().add(1, 'days');
  case: Case;
  _caseId: number;
  $caseSubscr: Subscription;
  $subscr1: Subscription;
  @Input() type: string;
  eventsDropdown: DropdownDictionary[];
  transportArrangedBySosList: DropdownDictionary[];
  incidentEvents: IncidentEvent[];
  selectedIncidentEvent: IncidentEvent;
  selectedIncidentDropdownActive: boolean = true;
  selectedIncidentDropdownName: string;
  private shortcutSubscription: Subscription;
  private caseInfoRefreshSubscription: Subscription;
  private incidentStateSubscription: Subscription;
  hideIncidentDropdown: boolean = false;
  incidentEventLockedByServiceOrder: boolean = false;
  winterSportHover = false;


  get screeningUpdateType() {
    return ScreeningUpdateType;
  }

  @ViewChild('caseCauseLevel1Component') caseCauseLevel1Component: DropDownComponent;
  @ViewChild('caseCauseLevel2Component') caseCauseLevel2Component: DropDownComponent;
  @ViewChild('caseCauseLevel3Component') caseCauseLevel3Component: DropDownComponent;
  @Output() eventName: EventEmitter<string> = new EventEmitter();

  @Input()
  set caseId(newCaseId: number) {
    if (newCaseId !== undefined) {
      this._caseId = newCaseId;
    }
  }

  get caseId(): number {
    return this._caseId;
  }

  get ValidationTypeEnum() {
    return ValidationTypeEnum;
  }

  constructor(
    private dictionaryService: DictionaryService,
    private incidentService: IncidentService,
    private ngZone: NgZone,
    private localTimeService: CaseLocalTimeService,
    private updateTimeLineService: UpdateTimeLineService,
    private helperService: HelperService,
    private caseLocalTimeService: CaseLocalTimeService,
    private caseStateService: CaseStateService,
    private simpleIncidentAdapter: SimpleIncidentAdapter,
    public caseLockHelperService: CaseLockHelperService,
    public permissionService: PermissionService,
    private incidentStateService: IncidentStateService,
    private caseValidationService: CaseValidationService,
    private masterListService: MasterListService,
    private shortcutService: ShortcutService,
    private translateService: TranslateService
  ) {
    this.$caseSubscr = this.caseStateService.getCase().subscribe(seccaCase => {
      this.case = seccaCase;
    });
  }

  public ngOnInit() {
    this.shortcutSubscription = this.shortcutService.addShortcut({ keys: ShortcutEnum.WinterSport }).subscribe(a => {
      this.winterSport();
    });
    this.caseInfoRefreshSubscription = this.caseStateService.getRefreshCaseInfo().subscribe(() => {
      this.reloadData();
    });
    this.selectedIncidentEvent = new IncidentEvent({id: null })
    this.transportArrangedBySosList = this.getDropdownListFromEnum(TransportAssistanceType, 'incident-event-transport-arranged-by-sos-');
    this.reloadData();
  }

  reloadData() {
    this.incidentService.getIncidentCausesForCase(this.case.id).subscribe(result => {
      this.incidentCausesLevel1 = result;
      this.initCauses();
    });

    this.incidentService.getCaseIncident(this.case.incident.id).subscribe(result => {
      this.incident = result;

      this.incidentService.incidentDateChanged('' + this.caseId, this.incident.incidentDate);
      this.loadDiagnosisAndSymptoms();
      this.initCauses();

      this.incidentStateSubscription = this.incidentStateService.getIncident().subscribe(
          incidentState => {
            if (incidentState && +incidentState.caseId === this.caseId) {
              this.incident = incidentState.incident;
            }
          }
      );
      if(this.type === 'EVENTS' && this.incident.incidentEvent !== null && this.incident.incidentEvent !== '0')  {
        this.incidentService.getIncidentEventLockedByServiceOrder(this.case.id, this.case.caseTypeCode).subscribe(
          result => {
            this.incidentEventLockedByServiceOrder = result
          }
        );
      }

      this.masterListService.getIncidentEvents().subscribe(result => {
        this.eventsDropdown = [];
        this.incidentEvents = result;

        this.incidentEvents.forEach(event => {
          if(event.businessKey === this.incident.incidentEvent) {
            this.hideIncidentDropdown = event.hiddenInEventDropDown;
          }
        })

        this.incidentEvents.sort((a, b) => {
          if (a.dateOfEvent === null || b.dateOfEvent === null) {
            return -1;
          }
          if (a.dateOfEvent < b.dateOfEvent) {
            return 0;
          }
          if (a.dateOfEvent > b.dateOfEvent) {
            return 1;
          }
        });

        this.incidentEvents.forEach(item => {
          if(!item.hiddenInEventDropDown) {
            this.eventsDropdown.push(new DisableableDropdownDictionary(item.businessKey, item.name, !item.active));
          }
        });
        if (this.incident.incidentEvent !== null && this.incident.incidentEvent !== '0') {
          this.selectedIncidentEvent = this.incidentEvents.find(x => x.businessKey ===  this.incident.incidentEvent);
          if (this.selectedIncidentEvent) {
            this.selectedIncidentDropdownActive = this.selectedIncidentEvent.active;
            this.selectedIncidentDropdownName = this.selectedIncidentEvent.name;
          }
          this.eventName.emit(this.selectedIncidentEvent?.name);
        }
      });
    });
  }

  openCauseLevel1Dropdown() {
    this.caseCauseLevel1Component.setFocus({ selectCause: true });
  }

  openCauseLevel2Dropdown() {
    this.caseCauseLevel2Component.setFocus({ selectCause: true });
  }

  openCauseLevel3Dropdown() {
    if(!!this.incident.causeLevel2Code && this.incidentCausesLevel3?.length !== 0) {
      this.caseCauseLevel3Component.setFocus({ selectCause: true });
    }
  }

  getEnumValue(eventCategory: EventCategory) {
    return Object.values(EventCategory)[Object.keys(EventCategory).indexOf(eventCategory)];

  }

  incidentCauseLevel1Changed(): void {
    this.initCausesLevel2();
    this.incident.causeLevel2Code = undefined;
    this.incident.causeLevel3Code = undefined;
    this.caseCauseLevel2Component.setFocus({ selectCause: true });
  }

  incidentCauseLevel2Changed(): void {
    this.initCausesLevel3();
    this.incident.causeLevel3Code = undefined;
    if(this.incidentCausesLevel3?.length !== 0) {
      this.caseCauseLevel3Component.setFocus({ selectCause: true });
    }
  }

  private initCauses(): void {
    this.initCausesLevel2();
    this.initCausesLevel3();
  }

  private initCausesLevel2(): void {
    if (this.incident && this.incident.causeLevel1Code && this.incidentCausesLevel1) {
      const selectedIncidentCauseLevel1 = this.incidentCausesLevel1.find(level1 => level1.id === this.incident.causeLevel1Code);
      this.incidentCausesLevel2 = selectedIncidentCauseLevel1 ? selectedIncidentCauseLevel1.level2Causes : [];
    }
  }

  private initCausesLevel3(): void {
    if (this.incident && this.incident.causeLevel2Code && this.incidentCausesLevel2) {
      const selectedIncidentCauseLevel2 = this.incidentCausesLevel2.find(level2 => level2.id === this.incident.causeLevel2Code);
      this.incidentCausesLevel3 = selectedIncidentCauseLevel2 ? selectedIncidentCauseLevel2.level3Causes : [];
    }
  }

  private markIfLocationIsValid(lt: LocationTypes, ref: ElementRef) {
    switch (lt) {
      case LocationTypes.INCIDENT:
        this.incident.incidentLocation.freeText = ref.nativeElement.value === '' ? null : ref.nativeElement.value;
        if (this.incident.incidentLocation.streetName !== undefined && this.incident.incidentLocation.streetName !== '') {
          this.incidentLocationIsValid = true;
        }
        break;
      case LocationTypes.CURRENT:
        this.incident.currentLocation.freeText = ref.nativeElement.value === '' ? null : ref.nativeElement.value;
        if (this.incident.currentLocation.streetName !== undefined && this.incident.currentLocation.streetName !== '') {
          this.currentLocationIsValid = true;
        }
        break;
    }
  }

  updateIncidentDate(incidentDate: moment.Moment) {
    this.incident.incidentDate = incidentDate;
    this.incidentService.updateeIncidentWithQueueService(this.incident, this.caseId);
    this.case.incident = this.simpleIncidentAdapter.adaptFromIncident(this.incident);
    this.case.incidentTimeZone = this.incident.incidentLocation.timeZoneId;
    this.case.currentTimeZone = this.incident.currentLocation.timeZoneId;
    this.caseStateService.sendCase(this.case);
    this.updateTimeLineService.changedIncident(this.incident);
    this.incidentStateService.sendIncident(this.incident, this.case.id);
    this.incidentService.incidentDateChanged('' + this.caseId, incidentDate);
  }

  updateSelectedIncidentEvent(event) {
    this.incident.incidentEvent = event;
    this.selectedIncidentEvent = this.incidentEvents.find(x => x.businessKey ===  this.incident.incidentEvent);
    this.eventName.emit(this.selectedIncidentEvent.name);
  }

  deleteIncidentEvent() {
    this.incident.incidentEvent = "0";
    this.incident.transportAssistance = null;
    this.selectedIncidentEvent = new IncidentEvent({})
    this.eventName.emit('');
    this.update();
  }

  popoverText() {
    return this.incident.winterSport ? this.translateService.instant('incident-winter-sport-popover-selected') : this.translateService.instant('incident-winter-sport-popover-not-selected');
  }

  winterSport() {
    this.incident.winterSport = !this.incident.winterSport;
    this.update();
  }

  update() {
    this.incidentService.updateeIncidentWithQueueService(this.incident, this.caseId);
    this.incidentStateService.sendIncident(this.incident, this.case.id);
  }

  updateFirstMedicalVisitDate(firstMedicalDate: moment.Moment) {
    this.incident.firstMedicalVisit = firstMedicalDate;
    this.updateIncident(ScreeningUpdateType.NONE);
  }

  updateIncident(screeningUpdate: ScreeningUpdateType) {
    this.updateCauseLevelText();
    this.incidentService.updateeIncidentWithQueueService(this.incident, this.caseId);
    this.case.incident = this.simpleIncidentAdapter.adaptFromIncident(this.incident);
    this.case.incidentTimeZone = this.incident.incidentLocation.timeZoneId;
    this.case.currentTimeZone = this.incident.currentLocation.timeZoneId;
    this.caseStateService.sendCase(this.case);
    this.incidentStateService.sendIncident(this.incident, this.case.id);

    if (screeningUpdate !== ScreeningUpdateType.NONE) {
      this.updateScreening(screeningUpdate);
    }
  }

  updateScreening(screeningUpdate: ScreeningUpdateType) {
    if (screeningUpdate === ScreeningUpdateType.DATE) {
      this.updateTimeLineService.changedIncident(this.incident);
    }
  }

  findExcludedElement(diagnoses: Icd10Code[], position: number) {
    return diagnoses != null && diagnoses[position] != null ? diagnoses[position].icd10Code : null;
  }

  private loadDiagnosisAndSymptoms() {
    if (!!this.incident.medicalPreAssessmentDiagnosis) {
      this.medicalPreAssessmentDiagnosis = true;
      this.selectedDiagnosis = Array.of(this.incident.medicalPreAssessmentDiagnosis);
    }
    else if (!!this.incident.medicalDiagnosis) {
      this.medicalDiagnosis = true;
      this.selectedDiagnosis = Array.of(this.incident.medicalDiagnosis);
    } else {
      this.selectedDiagnosis = !!this.incident.diagnosis ? Array.of(this.incident.diagnosis) : new Array<Icd10Code>();
    }

    if ((!!this.incident.medicalPreAssessmentSymptoms && this.incident.medicalPreAssessmentSymptoms.length > 0) ||
        (!!this.incident.medicalPreAssessmentSymptomsReported && this.incident.medicalPreAssessmentSymptomsReported.length > 0)) {
      this.medicalPreAssessmentSymptoms = true;
      const symptoms = this.incident.medicalPreAssessmentSymptoms?.map(s => new Icd10CodeWithStyle(s)).map(s => {s.redBoxStyle=true; return s});
      const symptomsReported = this.incident.medicalPreAssessmentSymptomsReported?.map(s => new Icd10CodeWithStyle(s));
      this.selectedSymptoms = [].concat(symptoms || [], symptomsReported || []);
    }
    else if (!!this.incident.medicalSymptoms && this.incident.medicalSymptoms.length > 0) {
      this.medicalSymptoms = true;
      this.selectedSymptoms = this.incident.medicalSymptoms;
    } else {
      this.selectedSymptoms = this.incident.symptoms;
    }
  }

  updateDiagnosis() {
    this.incident.diagnosis = !!this.selectedDiagnosis[0] ? this.selectedDiagnosis[0] : new Icd10Code();
    this.updateIncident(ScreeningUpdateType.NONE);
  }

  updateSymptoms() {
    this.incident.symptoms = this.selectedSymptoms;
    this.updateIncident(ScreeningUpdateType.NONE);
  }

  get freeTextFieldPlaceholder() {
    return this.helperService.freeTextFieldPlaceholder;
  }

  get diagnosisLabel() {
    return this.medicalDiagnosis ? 'case-basics-incident-main-medical-diagnosis' : 'case-basics-incident-main-diagnosis';
  }

  get symptomsLabel() {
    return this.medicalSymptoms ? 'case-basics-incident-reported-medical-symptoms' : 'case-basics-incident-reported-symptoms';
  }

  updateLocation(locationType: LocationTypes) {
    if (locationType === LocationTypes.INCIDENT) {
      this.incidentService.updateIncidentLocation(this.incident.id, this.incident.incidentLocation).subscribe(result => {
        this.case.incident.incidentCity = result.locality;
        this.case.incident.incidentCountry = result.country;
        this.case.incident.countryCodeAlpha2 = result.countryCodeAlpha2;
        this.case.incidentTimeZone = result.timeZoneId;
        this.caseLocalTimeService.setIncidentLocation(result.timeZoneId, result.locality, result.country, result.countryCodeAlpha2);
        this.incidentStateService.sendIncident(this.incident, this.case.id);
        this.caseValidationService.sendVerifyCaseNumberAssigned(this.caseId.toString());
        this.caseStateService.sendRefreshCaseType();
      });
    }

    if (locationType === LocationTypes.CURRENT) {
      this.incidentService.updateCurrentLocation(this.incident.id, this.incident.currentLocation).subscribe(result => {
        this.case.incident.currentCity = result.locality;
        this.case.incident.currentCountry = result.country;
        this.case.incident.currentCountryCodeAlpha2 = result.countryCodeAlpha2;
        this.case.incidentTimeZone = result.timeZoneId;
        this.caseLocalTimeService.setCurrentLocation(result.timeZoneId, result.locality, result.country, result.countryCodeAlpha2);
        this.incidentStateService.sendIncident(this.incident, this.case.id);
        this.caseStateService.sendRefreshCaseType();
      });
    }
  }

  private updateCauseLevelText(): void {
    if (this.incidentCausesLevel1 && this.incident) {
      const causeLevel1 = this.incidentCausesLevel1.find(level1 => level1.id === this.incident.causeLevel1Code);
      const causeLevel2 = causeLevel1?.level2Causes?.find(level2 => level2.id === this.incident.causeLevel2Code);
      const causeLevel3 = causeLevel2?.level3Causes?.find(level3 => level3.id === this.incident.causeLevel3Code);

      this.incident.causeLevel1Text = causeLevel1 ? causeLevel1.name : '';
      this.incident.causeLevel2Text = causeLevel2 ? causeLevel2.name : '';
      this.incident.causeLevel3Text = causeLevel3 ? causeLevel3.name : '';
    }
  }

  private getDropdownListFromEnum(enumItem: any, prefix: string) {
    return Object.keys(enumItem).map(
      key => new DropdownDictionary(enumItem[key], this.translateService.instant(prefix + key.toString().toLowerCase().replace(/_/g, '-')))
    );
  }

}

export enum ScreeningUpdateType {
  LOCATION = 'LOCATION',
  DATE = 'DATE',
  NONE = 'NONE',
}
