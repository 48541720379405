import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AccommodationComponent } from './case-plans/add-service-order/service-type-components/accommodation/accommodation.component';
import { AdviceCallRegistrationComponent } from './case-plans/add-service-order/service-type-components/advice-call-registration/advice-call-registration.component';
import { AdviseOnInsuranceTermsComponent } from './case-plans/add-service-order/service-type-components/advise-on-insurance-terms/advise-on-insurance-terms.component';
import { AmbulanceFlightComponent } from './case-plans/add-service-order/service-type-components/ambulance-flight/ambulance-flight.component';
import { AssistanceOrContactComponent } from './case-plans/add-service-order/service-type-components/assistance-or-contact/assistance-or-contact.component';
import { GeneralAdviceComponent } from './case-plans/add-service-order/service-type-components/general-advice/general-advice.component';
import { GroundAmbulanceComponent } from './case-plans/add-service-order/service-type-components/ground-ambulance/ground-ambulance.component';
import { GroundTransportComponent } from './case-plans/add-service-order/service-type-components/ground-transport/ground-transport.component';
import { HandlingOfMortalRemainsComponent } from './case-plans/add-service-order/service-type-components/handling-of-mortal-remains/handling-of-mortal-remains.component';
import { LuggageComponent } from './case-plans/add-service-order/service-type-components/luggage/luggage.component';
import { MedicalEscortComponent } from './case-plans/add-service-order/service-type-components/medical-escort/medical-escort.component';
import { MoneyTransferComponent } from './case-plans/add-service-order/service-type-components/money-transfer/money-transfer.component';
import { OtherRefundRequestComponent } from './case-plans/add-service-order/service-type-components/other-refund-request/other-refund-request.component';
import { ReferralToCustomerComponent } from './case-plans/add-service-order/service-type-components/referral-to-customer/referral-to-customer.component';
import { RegularFlightMedicalComponent } from './case-plans/add-service-order/service-type-components/regular-flight-medical/regular-flight-medical.component';
import { RegularFlightNonMedicalComponent } from './case-plans/add-service-order/service-type-components/regular-flight-non-medical/regular-flight-non-medical.component';
import { TreatmentComponent } from './case-plans/add-service-order/service-type-components/treatment/treatment.component';
import { DurationComponent } from './case-plans/add-service-order/sub-components/duration/duration.component';
import { DurationsComponent } from './case-plans/add-service-order/sub-components/durations/durations.component';
import { SubCoffinUrnComponent } from './case-plans/add-service-order/sub-components/sub-coffin-urn/sub-coffin-urn.component';
import { SubCommitmentTypeComponent } from './case-plans/add-service-order/sub-components/sub-commitment-type/sub-commitment-type.component';
import { SubFuneralHomesComponent } from './case-plans/add-service-order/sub-components/sub-funeral-homes/sub-funeral-homes.component';
import { SubGopDetailsComponent } from './case-plans/add-service-order/sub-components/sub-gop-details/sub-gop-details.component';
import { SubReferenceComponent } from './case-plans/add-service-order/sub-components/sub-reference/sub-reference.component';
import { SubStakeholderComponent } from './case-plans/add-service-order/sub-components/sub-stakeholder/sub-stakeholder.component';
import { SubStakeholdersComponent } from './case-plans/add-service-order/sub-components/sub-stakeholders/sub-stakeholders.component';
import { AddServiceOrderComponent } from './case-plans/add-service-order/add-service-order.component';
import { MedicalAssessmentTypeLeftSideComponent } from './case-plans/add-service-order/service-type-components/medical-assessment-type-left-side/medical-assessment-type-left-side.component';
import { MedicalAssessmentTypeComponent } from './case-plans/add-service-order/service-type-components/medical-assessment-type/medical-assessment-type.component';
import { GOPComponent } from './case-plans/add-service-order/sub-components/GOP/GOP.component';
import { SubAirwayBillComponent } from './case-plans/add-service-order/sub-components/sub-airway-bill/sub-airway-bill.component';
import { SubLuggageReferenceComponent } from './case-plans/add-service-order/sub-components/sub-luggage-reference/sub-luggage-reference.component';
import { SubRemarksFromSupplierComponent } from './case-plans/add-service-order/sub-components/sub-remarks-from-supplier/sub-remarks-from-supplier.component';
import { SubRemarksRefundComponent } from './case-plans/add-service-order/sub-components/sub-remarks-refund/sub-remarks-refund.component';
import { SubRemarksRulesAndFeesComponent } from './case-plans/add-service-order/sub-components/sub-remarks-rules-and-fees/sub-remarks-rules-and-fees.component';
import { SubRemarksToSupplierComponent } from './case-plans/add-service-order/sub-components/sub-remarks-to-supplier/sub-remarks-to-supplier.component';
import { SubTransportTypeComponent } from './case-plans/add-service-order/sub-components/sub-transport-type/sub-transport-type.component';
import { SubTravellersComponent } from './case-plans/add-service-order/sub-components/sub-travellers/sub-travellers.component';
import { SubTravelInfoComponent } from './case-plans/add-service-order/sub-components/travel-info/sub-travel-info.component';
import { CasePlansComponent } from './case-plans/case-plans.component';
import { PlanComponent } from './plan/plan.component';
import { MedicalAssessmentServiceOrderHoverComponent } from './plan/service-order-hover/medical-assessment-service-order-hover/medical-assessment-service-order-hover.component';
import { RegularFlightNonMedicalServiceOrderHoverComponent } from './plan/service-order-hover/regular-flight-non-medical-service-order-hover/regular-flight-non-medical-service-order-hover.component';
import { ServiceOrderHoverComponent } from './plan/service-order-hover/service-order-hover.component';
import { ServiceOrderComponent } from './plan/service-order/service-order.component';
import { SubMedifStatusComponent } from './case-plans/add-service-order/sub-components/sub-medif-status/sub-medif-status.component';
import { SubRepatriationDetailsComponent } from './case-plans/add-service-order/sub-components/sub-repatriation-details/sub-repatriation-details.component';
import { BaseServiceOrderHoverComponent } from './plan/service-order-hover/base-service-order-hover/base-service-order-hover.component';
import { CaseCoverageComponent } from './case-coverage/case-coverage.component';
import { LeftSideComponent } from './case-plans/add-service-order/left-side.component/left-side.component';
import { StakeholderComponent } from './case-plans/add-service-order/stakeholder/stakeholder.component';
import { GopSendRequestComponent } from './case-plans/add-service-order/sub-components/gop-send-request/gop-send-request.component';
import { SendItineraryComponent } from './case-plans/add-service-order/sub-components/send-itinerary/send-itinerary.component';
import { SubMedicalEscortComponent } from './case-plans/add-service-order/sub-components/sub-medical-escort/sub-medical-escort.component';
import { SubRepatriationFormMedicalComponent } from './case-plans/add-service-order/sub-components/sub-repatriation-form-medical/sub-repatriation-form-medical.component';
import { TemplatePickerComponent } from './template-picker/template-picker.component';
import { AddServiceOrderDialogComponent } from './case-plans/add-service-order/add-service-order-dialog/add-service-order-dialog.component';
import { UtilsModule } from 'src/app/utils/utils.module';
import { SharedModule } from '@secca/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SubRepatriationFormAmbulanceFlightComponent} from '@secca/case/components/case-plans/case-plans/add-service-order/sub-components/sub-repatriation-form-ambulance-flight/sub-repatriation-form-ambulance-flight.component';
import { MedicalPreAssessmentComponent } from './case-plans/add-service-order/service-type-components/medical-pre-assessment/medical-pre-assessment.component';
import { MedicalPreAssessmentLeftSideComponent } from './case-plans/add-service-order/service-type-components/medical-pre-assessment-left-side/medical-pre-assessment-left-side.component';
import {
  InvestigationComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/service-type-components/investigation/investigation.component';
import {
  TranslationComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/service-type-components/translation/translation.component';
import {
  InvestigationTypeLeftSideComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/service-type-components/investigation-type-left-side/investigation-type-left-side.component';
import {
  TranslationTypeLeftSideComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/service-type-components/translation-type-left-side/translation-type-left-side.component';
import { SearchServiceProviderComponent } from './case-plans/add-service-order/search-service-provider/search-service-provider.component';
import { SearchServiceProviderModalComponent } from '@secca/case/components/case-plans/case-plans/add-service-order/search-service-provider/search-service-provider-modal/search-service-provider-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { SupplierInfoComponent } from './case-plans/add-service-order/search-service-provider/supplier-info/supplier-info.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { PreDepartureAdviceComponent } from './case-plans/add-service-order/service-type-components/pre-departure-advice/pre-departure-advice.component';
import {
  CoordinationTransportComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/service-type-components/coordination-transport/coordination-transport.component';
import {
  CoordinationTransportTypeLeftSideComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/service-type-components/coordination-transport-type-left-side/coordination-transport-type-left-side.component';
import {
  SubServiceOrderComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/sub-components/sub-service-order/sub-service-order.component';
import {
  SubIccStakeholdersComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/sub-components/sub-icc-stakeholders/sub-icc-stakeholders.component';
import {
  SubIccStakeholderComponent
} from '@secca/case/components/case-plans/case-plans/add-service-order/sub-components/sub-icc-stakeholder/sub-icc-stakeholder.component';
import { SubEndUserCaseComponent } from './case-plans/add-service-order/sub-components/sub-end-user-case-component/sub-end-user-case.component';
import { SubEndUserRepatriationFormComponent } from './case-plans/add-service-order/sub-components/sub-end-user-repatriation-form/sub-end-user-repatriation-form.component';
import { EndUserLinkedCaseComponent } from './case-plans/add-service-order/service-type-components/coordination-transport/end-user-linked-case-component/end-user-linked-case.component';
import { SubRepatriationFormICC } from './case-plans/add-service-order/sub-components/sub-repatriation-form-icc/sub-repatriation-form-icc.component';
import { CoordinationTransportServiceOrderHoverComponent } from './plan/service-order-hover/coordination-transport-service-order-hover/coordination-transport-service-order-hover.component';
import { MedicalFlightAuthorizationLeftSideComponent } from './case-plans/add-service-order/service-type-components/medical-flight-authorization-left-side/medical-flight-authorization-left-side.component';
import { MedicalFlightAuthorizationComponent } from './case-plans/add-service-order/service-type-components/medical-flight-authorization/medical-flight-authorization.component';
import { SuggesterComponent } from './case-plans/add-service-order/search-service-provider/search-service-provider-modal/suggester/suggester.component';
import { NewServiceOrderHoverComponent } from './plan/new-service-order-hover/new-service-order-hover.component';
import { SingleItemDisplayComponent } from './case-plans/add-service-order/search-service-provider/search-service-provider-modal/single-item-display/single-item-display.component';
import { MajorIncidentComponent } from './case-plans/add-service-order/service-type-components/major-incident/major-incident.component';
import { SubMajorIncidentStakeholdersComponent } from './case-plans/add-service-order/sub-components/sub-major-incident-stakeholders/sub-major-incident-stakeholders.component';
import { SubMajorIncidentStakeholderComponent } from './case-plans/add-service-order/sub-components/sub-major-incident-stakeholder/sub-major-incident-stakeholder.component';
import { MajorIncidentServiceOrderHoverComponent } from './plan/service-order-hover/major-incident-service-order-hover/major-incident-service-order-hover.component';
import {NgxCurrencyModule} from "ngx-currency";

@NgModule({
  declarations: [
    TreatmentComponent,
    AccommodationComponent,
    RegularFlightNonMedicalComponent,
    RegularFlightMedicalComponent,
    AssistanceOrContactComponent,
    OtherRefundRequestComponent,
    AmbulanceFlightComponent,
    MedicalEscortComponent,
    HandlingOfMortalRemainsComponent,
    GroundTransportComponent,
    GroundAmbulanceComponent,
    LuggageComponent,
    ReferralToCustomerComponent,
    AdviseOnInsuranceTermsComponent,
    GeneralAdviceComponent,
    AdviceCallRegistrationComponent,
    MoneyTransferComponent,
    SubReferenceComponent,
    SubGopDetailsComponent,
    SubCommitmentTypeComponent,
    DurationsComponent,
    DurationComponent,
    SubCoffinUrnComponent,
    SubFuneralHomesComponent,
    SubStakeholdersComponent,
    SubStakeholderComponent,
    SubServiceOrderComponent,
    SubIccStakeholdersComponent,
    SubIccStakeholderComponent,
    CasePlansComponent,
    PlanComponent,
    ServiceOrderComponent,
    AddServiceOrderComponent,
    ServiceOrderHoverComponent,
    MedicalAssessmentServiceOrderHoverComponent,
    RegularFlightNonMedicalServiceOrderHoverComponent,
    SubRemarksRulesAndFeesComponent,
    SubLuggageReferenceComponent,
    SubAirwayBillComponent,
    SubRemarksToSupplierComponent,
    SubRemarksRefundComponent,
    SubRemarksFromSupplierComponent,
    SubTransportTypeComponent,
    MedicalAssessmentTypeLeftSideComponent,
    MedicalAssessmentTypeComponent,
    SubTravellersComponent,
    SubTravelInfoComponent,
    GOPComponent,
    SubRepatriationDetailsComponent,
    BaseServiceOrderHoverComponent,
    SubMedifStatusComponent,
    LeftSideComponent,
    CaseCoverageComponent,
    TemplatePickerComponent,
    SubRepatriationFormMedicalComponent,
    SubMedicalEscortComponent,
    StakeholderComponent,
    GopSendRequestComponent,
    SendItineraryComponent,
    AddServiceOrderDialogComponent,
    SubRepatriationFormAmbulanceFlightComponent,
    MedicalPreAssessmentComponent,
    MedicalPreAssessmentLeftSideComponent,
    InvestigationComponent,
    InvestigationTypeLeftSideComponent,
    TranslationComponent,
    TranslationTypeLeftSideComponent,
    MedicalPreAssessmentComponent,
    MedicalPreAssessmentLeftSideComponent,
    SearchServiceProviderComponent,
    SearchServiceProviderModalComponent,
    SupplierInfoComponent,
    PreDepartureAdviceComponent,
    CoordinationTransportComponent,
    CoordinationTransportTypeLeftSideComponent,
    SubEndUserCaseComponent,
    SubEndUserRepatriationFormComponent,
    EndUserLinkedCaseComponent,
    SubRepatriationFormICC,
    CoordinationTransportServiceOrderHoverComponent,
    MedicalFlightAuthorizationLeftSideComponent,
    MedicalFlightAuthorizationComponent,
    SuggesterComponent,
    NewServiceOrderHoverComponent,
    SingleItemDisplayComponent,
    MajorIncidentComponent,
    SubMajorIncidentStakeholdersComponent,
    SubMajorIncidentStakeholderComponent,
    MajorIncidentServiceOrderHoverComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UtilsModule,
        SharedModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatIconModule,
        MatSliderModule,
        NgOptimizedImage,
        MatProgressBarModule,
        NgxCurrencyModule
    ],
  exports: [
    AddServiceOrderComponent,
    ServiceOrderHoverComponent,
    CasePlansComponent,
    NewServiceOrderHoverComponent,
    SupplierInfoComponent
  ]
})
export class CasePlansModule { }
