  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-header-title">
        {{ 'policy-lookup-lookup-title' | translate }}
      </div>
      <div class="modal-buttons">
        <div (click)="close()">
          <img src="/assets/icons/Close White.svg" />
        </div>
      </div>
    </div>

    <div class="policy-lookup-modal-body">
      <div class="policy-holder">
        <div>
          <img class="clearfix" src="/assets/icons/Policy Holder.svg" alt="">
          <div class="title">{{ policyHolderName }}
            <div class="float-end success" *ngIf="matchByPh"></div>
          </div>
          <div class="ssn">{{ policyHolder.person ? policyHolder.person.nationalId : '' }}</div>
          <div class="address">{{ phAddressLine }}</div>
        </div>
        <div>
          <img class="clearfix" src="/assets/icons/End-User.svg" alt="">
          <div class="title">{{ endUserName }}
            <div class="float-end success" *ngIf="matchByEu"></div>
          </div>
          <div class="ssn">{{ endUser.person ? endUser.person.nationalId : '' }}</div>
          <div class="address">{{ euAddressLine }}</div>
        </div>
      </div>
      <div class="lookup row">
        <div class="overview col-4">
          <div class="policy-search">
            <app-input
              *ngIf="!insurance.creditCard"
              [name]="'policy-lookup-policy-number' | translate"
              [withImage]="true"
              (imageClicked)="performLookup($event)"
              [value]="insurance.policyReference"></app-input>
            <app-input-credit-card
              *ngIf="insurance.creditCard"
              [name]="'case-basics-insurance-creditCardNo' | translate"
              [value]="insurance.creditCardNo"
              [validate]="true"
              [withImage]="true"
              (imageClicked)="performLookup($event)"
            ></app-input-credit-card>
          </div>
          <div class="result-list">
            <div class="searching" *ngIf="!lookupResponse">
              <div class="searching-text">{{ 'policy-lookup-searching' | translate }}</div>
              <img src="/assets/icons/waitForSearchresult.gif" alt="Searching...">
            </div>
            <div class="results" *ngIf="lookupResponse">
              <div *ngIf="isPolicySelected" class="result" [ngClass]="showSelectedPolicy ? 'chosen' : ''"
                   (click)="onShowSelectedPolicy()">
                <div class="indicator" [ngClass]="insurance.insuranceLookupPolicy?.matchQuality.toLowerCase()"></div>
                <div class="selected-indicator">{{ 'policy-lookup-selected-policy' | translate }}</div>
                <div class="insurance-type">{{ insurance.insuranceLookupPolicy?.product.customerProductName}}</div>
                <div class="reference-number">{{ insurance.insuranceLookupPolicy?.policyNumber }}</div>
              </div>
              <div class="result" [ngClass]="!showSelectedPolicy && chosen === idx ? 'chosen' : ''" *ngFor="let policy of lookupResponse.results; let idx = index"
                   (click)="onShowLookupPolicy(idx)">
                <div class="indicator" [ngClass]="policy.matchQuality.toLowerCase()"></div>
                <div class="insurance-type">{{ policy.product.customerProductName || 'policy-lookup-no-product-found' | translate }}</div>
                <div class="reference-number">{{ policy.policyNumber }}</div>
              </div>
              <div *ngIf="!lookupResponse.error && !policyFound" class="no-matches-found">{{ 'policy-lookup-no-matches' | translate }}</div>
            </div>
          </div>
        </div>
        <div class="details col-8">
          <div class="details-container" *ngIf="lookupResponse">
            <app-policy-lookup-integration-status [executions]="lookupResponse.executions"></app-policy-lookup-integration-status>
            <app-policy-lookup-contact [contactDetails]="lookupResponse.profileContactInfo"></app-policy-lookup-contact>
            <div *ngIf="!policyFound">
              <ng-container *ngIf="lookupResponse.error">
                <app-alert-box [title]="('policy-lookup-integration-status-failed' | translate)"
                               [type]="'danger'">
                  <div>Error of type: {{lookupResponse.error.type}} Error message: {{lookupResponse.error.message}}</div>
                </app-alert-box>
              </ng-container>
            </div>
            <ng-container *ngIf="policyFound || isPolicySelected">
              <div class="button-container">
                <button *ngIf="policyDetails && !showSelectedPolicy" class="btn select"
                        [disabled]="isPolicySelected || !policyDetails?.product?.productId || !policyDetails.product.customerProductName"
                        (click)="showWarningIfPolicyChange(policyDetails)">{{ 'policy-lookup-select-result' | translate }}</button>
                <button *ngIf="showSelectedPolicy" class="btn remove" (click)="showWarningIfPolicyChange(null)">{{ 'policy-lookup-remove-result' | translate }}</button>
              </div>
              <app-policy-lookup-details [policy]="policyDetails"></app-policy-lookup-details>
            </ng-container>

          </div>
          <div class="filler" *ngIf="!lookupResponse && !policyFound"></div>
        </div>
      </div>
    </div>
  </div>
