import { Component, Inject, ViewChild } from '@angular/core';
import { IMinimizable } from '@secca/shared/interfaces/minimizable';
import { IKeyboardEnabled } from '@secca/shared/interfaces/keyboard-enabled';
import { IFocusEnabled } from '@secca/shared/interfaces/focus-enabled';
import { DialogMinimizableDirective } from '@secca/shared/directives/dialog-minimizable.directive';
import { MinimizableDialogType } from '@secca/shared/enums/minimizable-dialog-type-enum';
import { DialogSelectableDirective } from '@secca/shared/directives/dialog-selectable.directive';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DialogBoundryService } from '@secca/core/services/dialog-boundry.service';
import { DialogHelperUtilService } from '@secca/core/services/dialog-helper-util.service';

import { AutoCompleteTypeEnum } from '@secca/shared/components/drop-down-countries-auto-complete/auto-complete-type-enum';
import { CountryDetails } from '@secca/shared/models/country';
import {
  CountryInfoComponent
} from '@secca/case/components/case-country-info/case-country-info-dialog/country-info/country-info.component';
import {
  CaseCountryInfoDialogInitData
} from '@secca/case/components/case-country-info/case-country-info-dialog/case-country-info-dialog-init-data';
import { AdditionalInfo, IAdditionalInfo } from '@secca/shared/interfaces/additional-info';
import { CountryInfo, CountrySearchResponse } from '@secca/shared/models/SupplierSearchResponse';
import { ProviderService } from '@secca/core/services/providerService';

@Component({
  selector: 'app-case-country-info-dialog',
  templateUrl: './case-country-info-dialog.component.html',
  styleUrls: ['./case-country-info-dialog.component.scss']
})
export class CaseCountryInfoDialogComponent implements IAdditionalInfo, IMinimizable, IKeyboardEnabled, IFocusEnabled {
  @ViewChild(DialogMinimizableDirective) minimizedDirective: DialogMinimizableDirective;
  @ViewChild(DialogSelectableDirective) selectableDirective: DialogSelectableDirective;
  @ViewChild(CountryInfoComponent) wrappedInstance: CountryInfoComponent;
  protected readonly MinimizableDialogType = MinimizableDialogType;

  data: CaseCountryInfoDialogInitData;
  countryBasics: CountryDetails;
  countryInfo: CountryInfo;

  constructor(private dialogRef: MatDialogRef<CaseCountryInfoDialogComponent>,
              public dialogBoundryService: DialogBoundryService,
              private dialogHelperUtilService: DialogHelperUtilService,
              private supplierService: ProviderService,
              @Inject(MAT_DIALOG_DATA) data: CaseCountryInfoDialogInitData) {
    this.data = data;
    this.countryBasics = this.countryDetails(data);
    this.updateCountryInfo(data.countryCode);
  }

  public scrollToElement(selector: string) {
    setTimeout(() => {
      const shownCountryInfo = document.querySelector('mat-dialog-container:not(.mat-dialog-hide) app-country-info');
      const element = shownCountryInfo?.querySelector(selector);
      if (!!element) {
        element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      }
    });
  }

  countryDetails(data: CaseCountryInfoDialogInitData): CountryDetails {
    return {code: data.countryCode, countryName: data.country} as CountryDetails;
  }

  get AutoCompleteTypeEnum() {
    return AutoCompleteTypeEnum;
  }

  countryChange(country: CountryDetails) {
    this.updateCountryInfo(country.code);
  }
  updateCountryInfo(countryCode: string){
    this.supplierService.searchCountry(countryCode).subscribe({
        next: (country: CountrySearchResponse) => {
          this.countryInfo = country.country;
        },
        error: (error) => {
          console.error(`Error getting "${countryCode}" country data: `, error.message);
          this.countryInfo = {};
        },
        complete: () => {
          this.scrollToElement('.country-data-summary');
        }
      }
    );

  }

  getFocusElement() {
    return this.selectableDirective?.focusElement;
  }

  keyboardClose(): void {
    this.wrappedInstance.close();
  }

  minimize(): void {
    this.minimizedDirective.minimize();
  }

  getMinimizeType(): MinimizableDialogType {
    return this.minimizedDirective.appDialogMinimizable;
  }

  getAdditionalInfo(): AdditionalInfo {
    return {
      caseId: this.data.caseId,
      hoverTitle: this.data.caseNumber || '',
      hoverText: this.countryBasics.countryName
    };
  }

  close() {
    this.dialogHelperUtilService.close(this.dialogRef, this.data.caseId );
  }
}
