<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-title">
      {{ 'case-country-info-modal-title' | translate }} - {{ countryBasics.countryName }} ({{ countryBasics.code }})
    </div>
    <div class="modal-buttons">
      <div (click)="close()">
        <img src="/assets/icons/Close White.svg" alt="X" />
      </div>
    </div>
  </div>

  <div class="modal-content-body">

    <div class="modal-left-column">
      <ul class="country-data-menu">
        <li class="country-data-menu-item" (click)="scrollToElement('.country-data-summary')">{{ 'case-country-info-section-summary' | translate }}</li>
        <li class="country-data-menu-item" (click)="scrollToElement('.country-data-advice')">{{ 'case-country-info-section-advice' | translate }}</li>
        <li class="country-data-menu-item" (click)="scrollToElement('.country-data-medical')">{{ 'case-country-info-section-medical' | translate }}</li>
        <li class="country-data-menu-item" (click)="scrollToElement('.country-data-documents')">{{ 'case-country-info-section-documents' | translate }}</li>
        <li class="country-data-menu-item" (click)="scrollToElement('.country-data-general')">{{ 'case-country-info-section-general' | translate }}</li>
      </ul>
    </div>

    <div class="modal-body">

      <div class="country-data-card country-data-summary">
        <div class="card-title">
          <div class="status-block"></div>
          <div class="title">{{ 'case-country-info-section-summary' | translate }}</div>
        </div>
        <div class="card-content">
          <div>
            <label>{{ 'case-country-info-name' | translate }}</label>
            <span>{{ countryInfo?.country ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-attention' | translate }}</label>
            <span>{{ countryInfo?.attention ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-important' | translate }}</label>
            <span>{{ countryInfo?.importantInformation ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-handlingmortal' | translate }}</label>
            <span>{{ countryInfo?.handlingOfMortalRemains ?? '' }}</span>
          </div>
        </div>
      </div>

      <div class="country-data-card country-data-advice">
        <div class="card-title">
          <div class="status-block"></div>
          <div class="title">{{ 'case-country-info-section-advice' | translate }}</div>
        </div>
        <div class="card-content">
          <div>
            <label>{{ 'case-country-info-riskrating' | translate }}</label>
            <span>{{ countryInfo?.medicalRiskRating ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-emergency' | translate }}</label>
            <span>{{ countryInfo?.emergencyService ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-evacuation' | translate }}</label>
            <span>{{ countryInfo?.evacuationRepatriation ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-acprocesses' | translate }}</label>
            <span>{{ countryInfo?.acProcesses ?? '' }}</span>
          </div>
        </div>
      </div>

      <div class="country-data-card country-data-medical">
        <div class="card-title">
          <div class="status-block"></div>
          <div class="title">{{ 'case-country-info-section-medical' | translate }}</div>
        </div>
        <div class="card-content">
          <div>
            <label>{{ 'case-country-info-referralac' | translate }}</label>
            <span>{{ countryInfo?.referralAc ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-referraluser' | translate }}</label>
            <span>{{ countryInfo?.referralEndUser ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-publichealth' | translate }}</label>
            <span>{{ countryInfo?.publicHealthCare ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-privatehealth' | translate }}</label>
            <span>{{ countryInfo?.privateHealthCare ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-ehicaccepted' | translate }}</label>
            <span>{{ countryInfo?.ehicAcceptedWithinPublicHealthCare ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-ehiccopay' | translate }}</label>
            <span>{{ countryInfo?.ehicCopayment ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-ehiccopayremark' | translate }}</label>
            <span>{{ countryInfo?.ehicCopaymentRemark ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-reciprocalagreement' | translate }}</label>
            <span>{{ countryInfo?.reciprocalHealthCareTreatyAgreement ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-reciprocalagreementremark' | translate }}</label>
            <span>{{ countryInfo?.reciprocalHealthCareTreatyAgreementRemark ?? '' }}</span>
          </div>
        </div>
      </div>

      <div class="country-data-card country-data-documents">
        <div class="card-title">
          <div class="status-block"></div>
          <div class="title">{{ 'case-country-info-section-documents' | translate }}</div>
        </div>
        <div class="card-content">
          <div *ngFor="let doc of countryDocuments" class="document">
            <div><a href="{{ doc.doc_documentUrl ?? '' }}" target="_blank">{{ doc.doc_description ?? '(no description)' }}</a></div>
            <div class="valid">{{ 'case-country-info-valid' | translate }}: {{ formatDate(doc.doc_validFrom) }} - {{ formatDate(doc.doc_validTo) }}</div>
          </div>
        </div>
      </div>

      <div class="country-data-card country-data-general">
        <div class="card-title">
          <div class="status-block"></div>
          <div class="title">{{ 'case-country-info-section-general' | translate }}</div>
        </div>
        <div class="card-content">
          <div>
            <label>{{ 'case-country-info-alias' | translate }}</label>
            <span>{{ countryInfo?.alias ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-alpha2' | translate }}</label>
            <span>{{ countryInfo?.isoAlpha2 ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-alpha3' | translate }}</label>
            <span>{{ countryInfo?.isoAlpha3 ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-network-manager' | translate }}</label>
            <span>{{ countryInfo?.responsibleNetworkManager ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-capital' | translate }}</label>
            <span>{{ countryInfo?.capital ?? '' }}</span>
          </div>
          <div>
            <label>{{ 'case-country-info-schengenmember' | translate }}</label>
            <span>{{ countryInfo?.schengenMembership ?? '' }}</span>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>
