import { Injectable } from '@angular/core';
import { ApplicationSettings } from '../../shared/models/application.settings';
import { SettingsLoaderService } from './settings.loader.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public static pageSizeForPagination: number = 150;
  private configuration: ApplicationSettings;

  constructor(private settingsLoaderService: SettingsLoaderService) {}

  getBffEndpoint(): string {
    return this.settingsLoaderService.configuration.bffEndpoint;
  }

  getKeyCloakUrl(): string {
    return this.settingsLoaderService.configuration.keyCloakUlr;
  }

  isProduction(): boolean {
    return this.settingsLoaderService.configuration.isProduction;
  }

  isTest(): boolean {
    return this.settingsLoaderService.configuration.isTest;
  }

  isLocal(): boolean {
    return !this.isProduction() && !this.isTest();
  }

  getCaseManagementSystemURL(): string {
    return this.settingsLoaderService.configuration.caseManagementSystemEndpoint;
  }

  getGoogleTimezoneAPIURL() : string {
    return this.settingsLoaderService.configuration.googleTimezoneAPIURL;
  }

  getGoogleAPIKey() : string {
    return this.settingsLoaderService.configuration.googleAPIKey;
  }

  isSsoDisabled(): boolean {
    return this.settingsLoaderService.configuration.ssoDisabled;
  }

  getProviderURL(): string {
    return this.settingsLoaderService.configuration.providerEndpoint;
  }

  getOpenCasesMaxCount(): number {
    return !!this.settingsLoaderService.configuration.openCasesMaxCount ? this.settingsLoaderService.configuration.openCasesMaxCount : 15;
  }

  getOpenCasesWarningThreshold(): number {
    return !!this.settingsLoaderService.configuration.openCasesWarningThreshold ? this.settingsLoaderService.configuration.openCasesWarningThreshold : 10;
  }

  isZoomed(): boolean {
    return localStorage.getItem('zoomed') === 'true';
  }
  isNewServiceProviderEnabled(): boolean {
    return !!this.settingsLoaderService.configuration.newSupplierEnabled ?  this.settingsLoaderService.configuration.newSupplierEnabled : true;
  }
}
