import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CountryDocListItem, CountryInfo } from '@secca/shared/models/SupplierSearchResponse';
import { CountryDetails } from '@secca/shared/models/country';

@Component({
  selector: 'app-country-info',
  templateUrl: './country-info.component.html',
  styleUrls: ['./country-info.component.scss']
})
export class CountryInfoComponent {
  @Input() countryBasics: CountryDetails;
  @Input() countryInfo: CountryInfo;
  @Output() closeCountryInfo = new EventEmitter<string>();

  dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  public formatDate(ts: string): string {
    if (ts) {
      return new Date(ts).toLocaleDateString(undefined, this.dateOptions);
    }
    return '';
  }

  public scrollToElement(selector: string) {
    setTimeout(() => {
      const shownCountryInfo = document.querySelector('mat-dialog-container:not(.mat-dialog-hide) app-country-info');
      const element = shownCountryInfo?.querySelector(selector);
      if (!!element) {
        element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      }
    });
  }

  get countryDocuments(): CountryDocListItem[] {
    return this.countryInfo?.doc_list || [];
  }

  close() {
    this.closeCountryInfo.emit('close');
  }
}
