import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LinkedCaseCreate } from '@secca/shared/models/linkedCaseCreate';
import { LinkedCasesList, LinkedCasesListAdapter } from '@secca/shared/models/linkedCasesList';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class CaseLinkedCasesService extends BaseService  {

  constructor(private http: HttpClient,
              private settingsService: SettingsService,
              private linkedCasesListAdapter: LinkedCasesListAdapter) {
    super(settingsService);
   }

  private customerProductChanges = {};

  public getLinkedCasesOnCase(caseId: string): Observable<LinkedCasesList[]> {
    return this.http
      .get(this.baseURL + 'linked-cases/findLinkBySeccaCaseId/' + caseId, { headers: this.jsonHeaders })
      .pipe(map((data: LinkedCasesList[]) => data.map(item => this.linkedCasesListAdapter.adapt(item))));
  }

  public countLinkedCasesByCaseId(caseId: string): Observable<number> {
    return this.http
      .get<number>(this.baseURL + 'linked-cases/countLinkedCasesByCaseId/' + caseId, { headers: this.jsonHeaders });
  }

  public linkCases(linkedCaseCreate: LinkedCaseCreate): Observable<LinkedCasesList[]> {
    return this.http
    .post<LinkedCasesList[]>(this.baseURL + 'linked-cases', linkedCaseCreate, { headers: this.jsonHeaders });
  }


  public deleteCasesFromLinkedGroup(id: number, deletedFrom: number): Observable<LinkedCasesList[]> {
    return this.http
    .delete<LinkedCasesList[]>(this.baseURL + 'linked-cases/deleteLinkedCase/' + id + '/' + deletedFrom);
  }

  public getLinkedCasesChanges(caseId: string): Observable<number> {
    this.initCustomerProductChangeBehaviorSubject(caseId);
    return this.customerProductChanges[caseId].asObservable();
  }

  public linkedCasesChanged(caseId: string, numberOfLinkes: number) {
    this.initCustomerProductChangeBehaviorSubject(caseId);
    this.customerProductChanges[caseId].next(numberOfLinkes);
  }

  private initCustomerProductChangeBehaviorSubject(caseId: string) {
    if (this.customerProductChanges[caseId] == null) {
      this.customerProductChanges[caseId] = new BehaviorSubject(undefined);
    }
  }
}



