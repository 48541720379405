export enum CaseBasicsInfoUserTypeEnum {
  EndUser,
  PolicyHolder,
  Reporter,
}

export enum ShortcutEnum {
  KeyboardShortcuts = 'control.k',

  NavigateToTheBoard = 'control.g',
  NavigateToTheGlobalSearch = 'control.g',
  NavigateToTheNewCase = 'control.s',

  NavigateToBoardCases = 'control.1',
  NavigateToBoardTask = 'control.2',
  NavigateToBoardSalesOrder = 'control.3',

  NavigateToTaskToDo = 'control.o',
  NavigateToTaskWeb = 'control.b',
  NavigateToTaskRefund = 'control.e',
  NavigateToTaskSupplierInvoice = 'control.u',

  NavigateToTable = 'home',
  NavigateTableRowUp = 'arrowUp',
  NavigateTableRowDown = 'arrowDown',
  NavigateTableRowEnter = 'enter',
  NavigateTableRowSpace = 'control.space',
  NavigateTableRowAltEnter = 'alt.enter',
  NavigateTableRowCtrlEnter = 'control.enter',

  BoardTaskReassignActivate = 'control.r',
  BoardTaskReassignProcess = 'alt.r',

  BoardNavigateToTheSearchOrCreateCase = 'control.shift.s',

  ModalsMinimizedContainerFocus = 'control.j',
  ModalsToggleOpenDialogs = 'control.i',
  ModalsToggleOpenDialogsReverse = 'control.shift.i',
  ModalOpenRecentCases = 'control.q',

  NavigateToCaseTask = 'alt.t',
  NavigateToCaseBasics = 'alt.b',
  NavigateToCaseSummary = 'alt.c',
  NavigateToCasePlan = 'alt.p',
  NavigateToCaseMedical = 'alt.m',
  NavigateToCaseEconomy = 'alt.e',
  NavigateToCaseStakeholders = 'alt.s',
  NavigateToCaseDocuments = 'alt.d',

  OutputManagementEmail = 'alt.j',
  OutputManagementSms = 'alt.k',

  CaseConsent = 'alt.r',
  CaseNotes = 'alt.n',

  OpenGuideDocument = 'alt.g',
  OpenTermsDocument = 'alt.h',

  CaseBasicsInfoUserLookup = 'alt.l',
  CaseBasicsInsuranceLookup = 'alt.o',

  ServiceOrderCopyId = 'alt.i',

  CaseEconomyRefundsAndSupplierInvoices = 'alt.u',
  AddEconomyRefund = 'alt.a',

  AddTask = 'alt.a',
  AddService = 'alt.a',
  AddRefund = 'alt.f',
  AddMedicalNote = 'alt.a',
  AddStakeholder = 'alt.a',
  SaveAction = 'alt.v',
  EconomyServiceOrdersExpandAll = 'alt.q',
  WinterSport = 'alt.w',

  CaseEconomyReRecovery = 'alt.r',
  AddEconomyRecovery = 'alt.a',
}

export enum NoteTypeEnum {
  phone = 'PHONE',
  case = 'CASE',
  customer = 'CUSTOMER',
  supplier = 'SUPPLIER',
}

export enum HandlingAreaStatusEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  REOPENED = 'REOPENED',
  NON_ACTIVE = 'NON_ACTIVE',
}

export enum PatientType {
  INPATIENT = 'INPATIENT',
  OUTPATIENT = 'OUTPATIENT',
}

export enum AcStatusEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  REOPENED = 'REOPENED',
}

export enum CallType {
  ingoing = 'INCOMING',
  outgoing = 'OUTGOING',
}

export enum Team {
  'RESPONSIVE',
  'CARING',
}

export enum InputTypePattern {
  all,
  lettersOnly,
  digitsOnly,
  lettersOnlyWithSpace,
  digitsAndComma,
  noSpecialCharacters,
}

export enum UserTeam {
  all = 'ADT01',
  costControl = 'ADT09',
  other = 'ADT22',
}

export enum StakeholderTypeEnum {
  endUser = 'END_USER',
  policyHolder = 'POLICYHOLDER',
  reporter = 'REPORTER',
  person = 'PERSON',
  medicalAdvisor = 'MEDICAL_ADVISOR',
  crisisPsychologist = 'CRISIS_PSYCHOLOGIST',
  dentist = 'DENTIST',
  doctorOutpatientClinic = 'DOCTOR_OUTPATIENT_CLINIC',
  hospital = 'HOSPITAL',
  pharmacy = 'PHARMACY',
  hotel = 'HOTEL',
  airAmbulanceCompany = 'AIR_AMBULANCE_COMPANY',
  airline = 'AIRLINE',
  charterFights = 'CHARTER_FLIGHTS',
  schedulesFights = 'SCHEDULED_FLIGHTS',
  airport = 'AIRPORT',
  ambulanceCompany = 'AMBULANCE_COMPANY',
  taxi = 'TAXI',
  escortDoctor = 'ESCORT_DOCTOR',
  escortNurse = 'ESCORT_NURSE',
  assistanceContact = 'ASSISTANCE_CONTACT',
  billingOfficeAdministration = 'BILLING_OFFICE_ADMINISTRATION',
  sosServiceOffice = 'SOS_SERVICE_OFFICE',
  travelAgency = 'TRAVEL_AGENCY',
  churchesAndPriests = 'CHURCHES_AND_PRIESTS',
  embassyConsulate = 'EMBASSY_CONSULATE',
  funeralCompany = 'FUNERAL_COMPANY',
  lawyers = 'LAWYERS',
  policeAndRescueSearch = 'POLICE_AND_RESCUE_SEARCH',
  shippingAndCargoCompany = 'SHIPPING_AND_CARGO_COMPANY',
  coTraveller = 'COTRAVELLER',
  medicalEscort = 'MEDICALESCORT',
  nonMedicalEscort = 'NON_MEDICAL_ESCORT',
  other = 'OTHER',
  gop = 'GOP',
  patientsDoctorHospital = 'PATIENTS_DOCTOR_HOSPITAL',
  customer = 'CUSTOMER',
  company = 'COMPANY',
  sosAccounting = 'SOS_ACCOUNTING',
}

export enum ValidationTypeEnum {
  email = 'email',
  emailOrEmpty = 'emailOrEmpty',
  emailAndValid = 'emailAndValid',
  emailAndValidOrEmpty = 'emailAndValidOrEmpty',
  sms = 'sms',
  numbers = 'numbers',
  empty = 'empty',
  required = 'required',
  nationalID = 'nationalID',
  insuranceLevel = 'insuranceLevel',
  default = 'default',
}

export enum ProviderRatingEnum {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  NONE = 'NONE',
}

export enum ProviderOwnershipEnum {
  Public = 'Public',
  Private = 'Private',
  Both = 'Both',
  Other = 'Other'
}

export abstract class StakeholderTypeOrder {
  static getStakeholderTypeOrder(a: string): number {
    switch (a) {
      case StakeholderTypeEnum.endUser:
        return 1;
      case StakeholderTypeEnum.policyHolder:
        return 2;
      case StakeholderTypeEnum.reporter:
        return 3;
      case StakeholderTypeEnum.person:
        return 4;
      case StakeholderTypeEnum.coTraveller:
        return 5;
      case StakeholderTypeEnum.escortDoctor:
        return 6;
      case StakeholderTypeEnum.escortNurse:
        return 6;
    }
    return 100;
  }
}

export enum BalanceSheetEntryEnum {
  REFUND = 'REFUND',
  SUPPLIER_INVOICE = 'SUPPLIER_INVOICE',
  RECOVERY_PAYMENT = 'RECOVERY_PAYMENT'
}

export enum RecoveryEntryEnum {
  RECOVERY = 'RECOVERY'
}

export enum BalanceSheetStatusEnum {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  WARNING = 'WARNING',
  CANCELLED = 'CANCELLED',
}

export enum BalanceSheetEventEnum {
  EXPECTED = 'EXPECTED',
  COMMITMENT = 'COMMITMENT',
  INITIAL = 'INITIAL',
  AWAITING = 'AWAITING',
  UPDATED = 'UPDATED',
  ONGOING = 'ONGOING',
  HANDLED = 'HANDLED',
  CREDITOR_PAID = 'CREDITOR_PAID',
  INVOICING = 'INVOICING'
}

export enum CostTypeEnum {
  SERVICE_WITH_RESERVE = 1,
  REFUND = 2,
  NO_CLAIMS_COST = 3,
}

export enum DefaultCoveragesAlwaysPresentEnum {
  ACUTE_COMMITTED_BEFORE_POLICY_SELECTION = -1,
  EXTRAORDINARY_COVERAGES = -2,
  NOT_AN_INSURANCE_COST = -3,
  UNKNOWN = -99,
}

export enum PreferredChannel {
  mobilePhone = 'Mobile phone',
  fixedPhone = 'Fixed phone',
  fax = 'Fax',
  email = 'Email',
  sms = 'SMS',
}

export enum ServiceTypeEnum {
  TREATMENT = 'TREATMENT',
  MEDICAL_ASSESSMENT = 'MEDICAL_ASSESSMENT',
  ACCOMMODATION = 'ACCOMMODATION',
  REGULAR_FLIGHT_NON_MEDICAL = 'REGULAR_FLIGHT_NON_MEDICAL',
  REGULAR_FLIGHT_MEDICAL = 'REGULAR_FLIGHT_MEDICAL',
  AMBULANCE_FLIGHT = 'AMBULANCE_FLIGHT',
  GROUND_AMBULANCE = 'GROUND_AMBULANCE',
  GROUND_TRANSPORT = 'GROUND_TRANSPORT',
  MEDICAL_ESCORT = 'MEDICAL_ESCORT',
  HANDLING_OF_MORTAL_REMAINS = 'HANDLING_OF_MORTAL_REMAINS',
  AGENT = 'AGENT',
  MONEY_TRANSFER = 'MONEY_TRANSFER',
  LUGGAGE = 'LUGGAGE',
  REFERRAL_TO_CUSTOMER = 'REFERRAL_TO_CUSTOMER',
  ADVISE_ON_INSURANCE_TERMS = 'ADVISE_ON_INSURANCE_TERMS',
  GENERAL_ADVICE = 'GENERAL_ADVICE',
  OTHER_REFUND_REQUEST = 'OTHER_REFUND_REQUEST',
  ASSISTANCE_OR_CONTACT = 'ASSISTANCE_OR_CONTACT',
  ADVICE_CALL_REGISTRATION = 'ADVICE_CALL_REGISTRATION',
  MEDICAL_PRE_ASSESSMENT = 'MEDICAL_PRE_ASSESSMENT',
  INVESTIGATION = 'INVESTIGATION',
  TRANSLATION = 'TRANSLATION',
  PRE_DEPARTURE_TRAVEL_SERVICE = 'PRE_DEPARTURE_TRAVEL_SERVICE',
  COORDINATION_TRANSPORT = 'COORDINATION_TRANSPORT',
  MEDICAL_FLIGHT_AUTHORIZATION = 'MEDICAL_FLIGHT_AUTHORIZATION',
  MAJOR_INCIDENT = 'MAJOR_INCIDENT'
}

export class ServiceTypeEnumIcon {
  static getIcon(type: ServiceTypeEnum) {
    switch (type) {
      case ServiceTypeEnum.TREATMENT:
        return 'service-order-type/Treatment.svg';
      case ServiceTypeEnum.MEDICAL_ASSESSMENT:
        return 'service-order-type/Medical Assessment.svg';
      case ServiceTypeEnum.MEDICAL_PRE_ASSESSMENT:
        return 'service-order-type/Medical Pre Assessment.svg';
      case ServiceTypeEnum.ACCOMMODATION:
        return 'service-order-type/Accomodation.svg';
      case ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL:
        return 'service-order-type/Regular Flight - Non-medical.svg';
      case ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL:
        return 'service-order-type/Regular Flight - Medical.svg';
      case ServiceTypeEnum.ASSISTANCE_OR_CONTACT:
        return 'service-order-type/Assistance or Contact.svg';
      case ServiceTypeEnum.AMBULANCE_FLIGHT:
        return 'service-order-type/Ambulance Flight.svg';
      case ServiceTypeEnum.GROUND_AMBULANCE:
        return 'service-order-type/Ambulance.svg';
      case ServiceTypeEnum.GROUND_TRANSPORT:
        return 'service-order-type/Ground Transport.svg';
      case ServiceTypeEnum.MEDICAL_ESCORT:
        return 'service-order-type/Medical Escort Fee.svg';
      case ServiceTypeEnum.HANDLING_OF_MORTAL_REMAINS:
        return 'service-order-type/Handling of mortal remains.svg';
      case ServiceTypeEnum.REFERRAL_TO_CUSTOMER:
        return 'service-order-type/Referral to Customer.svg';
      case ServiceTypeEnum.AGENT:
        return 'service-order-type/Agent.svg';
      case ServiceTypeEnum.LUGGAGE:
        return 'service-order-type/Luggage.svg';
      case ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS:
        return 'service-order-type/Advise on insurance terms.svg';
      case ServiceTypeEnum.GENERAL_ADVICE:
        return 'service-order-type/General Advice.svg';
      case ServiceTypeEnum.MONEY_TRANSFER:
        return 'service-order-type/Money Transfer.svg';
      case ServiceTypeEnum.OTHER_REFUND_REQUEST:
        return 'service-order-type/OtherRefund.svg';
      case ServiceTypeEnum.ADVICE_CALL_REGISTRATION:
        return 'service-order-type/Advice call registration.svg';
      case ServiceTypeEnum.INVESTIGATION:
        return 'service-order-type/Investigation.svg';
      case ServiceTypeEnum.TRANSLATION:
        return 'service-order-type/Translation.svg';
      case ServiceTypeEnum.PRE_DEPARTURE_TRAVEL_SERVICE:
        return 'service-order-type/Pre-departure advice.svg';
      case ServiceTypeEnum.COORDINATION_TRANSPORT:
        return 'service-order-type/Coordination transport.svg';
      case ServiceTypeEnum.MEDICAL_FLIGHT_AUTHORIZATION:
        return 'service-order-type/Medical Flight Authorization.svg';
      case ServiceTypeEnum.MAJOR_INCIDENT:
        return 'service-order-type/major incident.svg';
      default:
        return 'service-order-type/Other.svg';
    }
  }

  static getBackGroundColor(type: ServiceTypeEnum): string {
    const color = this.getBackGroundColorEnum(type);
    return this.getColorString(color);
  }

  static getBackGroundColorEnum(type: ServiceTypeEnum): ServiceTypeIconBackgroundColor {
    switch (type) {
      case ServiceTypeEnum.TREATMENT:
        return ServiceTypeIconBackgroundColor.red;
      case ServiceTypeEnum.MEDICAL_ASSESSMENT:
        return ServiceTypeIconBackgroundColor.red;
      case ServiceTypeEnum.MEDICAL_PRE_ASSESSMENT:
        return ServiceTypeIconBackgroundColor.red;
      case ServiceTypeEnum.ACCOMMODATION:
        return ServiceTypeIconBackgroundColor.green;
      case ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL:
        return ServiceTypeIconBackgroundColor.blue;
      case ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL:
        return ServiceTypeIconBackgroundColor.blue;
      case ServiceTypeEnum.AMBULANCE_FLIGHT:
        return ServiceTypeIconBackgroundColor.blue;
      case ServiceTypeEnum.GROUND_AMBULANCE:
        return ServiceTypeIconBackgroundColor.blue;
      case ServiceTypeEnum.GROUND_TRANSPORT:
        return ServiceTypeIconBackgroundColor.blue;
      case ServiceTypeEnum.MEDICAL_ESCORT:
        return ServiceTypeIconBackgroundColor.red;
      case ServiceTypeEnum.HANDLING_OF_MORTAL_REMAINS:
        return ServiceTypeIconBackgroundColor.gray;
      case ServiceTypeEnum.ASSISTANCE_OR_CONTACT:
        return ServiceTypeIconBackgroundColor.purple;
      case ServiceTypeEnum.AGENT:
        return ServiceTypeIconBackgroundColor.purple;
      case ServiceTypeEnum.LUGGAGE:
        return ServiceTypeIconBackgroundColor.purple;
      case ServiceTypeEnum.REFERRAL_TO_CUSTOMER:
        return ServiceTypeIconBackgroundColor.purple;
      case ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS:
        return ServiceTypeIconBackgroundColor.purple;
      case ServiceTypeEnum.ADVICE_CALL_REGISTRATION:
        return ServiceTypeIconBackgroundColor.purple;
      case ServiceTypeEnum.GENERAL_ADVICE:
        return ServiceTypeIconBackgroundColor.purple;
      case ServiceTypeEnum.MONEY_TRANSFER:
        return ServiceTypeIconBackgroundColor.purple;
      case ServiceTypeEnum.OTHER_REFUND_REQUEST:
        return ServiceTypeIconBackgroundColor.yellow;
      case ServiceTypeEnum.INVESTIGATION:
        return ServiceTypeIconBackgroundColor.blue;
      case ServiceTypeEnum.TRANSLATION:
        return ServiceTypeIconBackgroundColor.blue;
      case ServiceTypeEnum.PRE_DEPARTURE_TRAVEL_SERVICE:
        return ServiceTypeIconBackgroundColor.darkBlue;
      case ServiceTypeEnum.COORDINATION_TRANSPORT:
        return ServiceTypeIconBackgroundColor.darkGreen;
      case ServiceTypeEnum.MEDICAL_FLIGHT_AUTHORIZATION:
        return ServiceTypeIconBackgroundColor.red;
      case ServiceTypeEnum.MAJOR_INCIDENT:
        return ServiceTypeIconBackgroundColor.brown;
      default:
        return ServiceTypeIconBackgroundColor.gray;
    }
  }

  private static getColorString(color: ServiceTypeIconBackgroundColor): string {
    switch (color) {
      case ServiceTypeIconBackgroundColor.red:
        return '#F50A37';
      case ServiceTypeIconBackgroundColor.blue:
        return '#23AFDC';
      case ServiceTypeIconBackgroundColor.green:
        return '#34D1AA';
      case ServiceTypeIconBackgroundColor.purple:
        return '#905C9F';
      case ServiceTypeIconBackgroundColor.gray:
        return '#979DB1';
      case ServiceTypeIconBackgroundColor.lightGray:
        return '#979DB1';
      case ServiceTypeIconBackgroundColor.yellow:
        return '#ffd36f';
      case ServiceTypeIconBackgroundColor.white:
        return '#ffffff';
      case ServiceTypeIconBackgroundColor.darkBlue:
        return '#00318d';
      case ServiceTypeIconBackgroundColor.brown:
        return '#b97a57';
      default:
        // grey
        return '#979DB1';
    }
  }
}

export enum ServiceTypeIconBackgroundColor {
  red,
  blue,
  green,
  purple,
  gray,
  lightGray,
  yellow,
  white,
  darkBlue,
  darkGreen,
  brown,
}

export enum IODirection {
  input = 'INPUT',
  output = 'OUTPUT',
}

export enum LocationTypes {
  start = 1,
  end = 2,
}

export enum CommitmentTypes {
  GUARANTEE_OF_PAYMENT = 'COMT01', // GOP - Guarantee of Payment',
  VOB_VERIFICATION_OF_BENEFITS = 'COMT02', // VOB - Verification of benefits',
  EHIC_PARTIAL_GOP = 'COMT03', // EHIC - Partial GOP',
  EHIC_FULL_COVERAGE = 'COMT04', // EHIC or other convention - Fully covered',
  PAY_AND_CLAIM_SOS = 'COMT05', // Pay and claim - SOS Claims',
  PAY_AND_CLAIM_CUSTOMER = 'COMT06', // Pay and claim - Customer',
  BOOKING = 'COMT07', // Booking',
  COMMITTED = 'COMT08', // Committed',
  EXPECTED = 'COMT09', // Expected',
  LIGHT_MEDICAL_REVIEW = 'COMT010', // Light medical review',
  REGULAR_MEDICAL_ASSESSMENT = 'COMT011', // Regular medical assessment',
  NIGHT_SHIFT_MEDICAL_ASSESSMENT = 'COMT012', // Night shift medical assessment',
  ESCORT_DOCTOR = 'COMT013', // Escort doctor',
  ESCORT_NURSE = 'COMT014', // Escort nurse',
  NON_MEDICAL_ESCORT = 'COMT015', // Non-medical escort',
  CRISIS_PSYCHOLOGIST = 'COMT016', // Crisis psychologist',
  CRISIS_NURSE = 'COMT017', // Crisis nurse',
  TRAVEL_RELATED = 'COMT018', // Travel related',
  NOT_TRAVEL_RELATED = 'COMT019', // Not travel related',
  PAY_AND_CLAIM_SOS_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS = 'COMT020', // Pay and claim / SOS Claims - NO policy lookup - NO advice on insurance terms',
  PAY_AND_CLAIM_CUSTOMER_NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS = 'COMT021', // Pay and claim / Customer - NO policy lookup - NO advice on insurance terms',
  PAY_AND_CLAIM_SOS_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN = 'COMT022', // Pay and claim / SOS Claims - Policy lookup DONE - Advice on insurance terms GIVEN',
  PAY_AND_CLAIM_CUSTOMER_POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN = 'COMT023', // Pay and claim / Customer - Policy lookup DONE - Advice on insurance terms GIVEN',
  NO_POLICY_LOOKUP_REFERAL_TO_CUSTOMER = 'COMT024', // NO policy lookup - Referral to customer',
  NO_POLICY_LOOKUP_NO_ADVICE_ON_INSURANCE_TERMS = 'COMT025', // NO policy lookup  - NO advice on insurance terms',
  POLICY_LOOKUP_DONE_ADVICE_ON_INSURANCE_TERMS_GIVEN = 'COMT026', // Policy lookup DONE - Advice on insurance terms GIVEN',
  NOT_TRAVEL_SOS_RELATED = 'COMT027', // NOT travel / SOS related',
  CLAIMS_MEDICAL_REVIEW = 'COMT028',
  PRE_DEPARTURE_REVIEW = 'COMT035'
}

export enum StatusTypes {
  EXPECTED = 'EXPECTED',
  NOT_COVERED = 'NOT_COVERED',
  COMMITTED = 'COMMITTED',
  PARTIALLY_COMMITTED = 'PARTIALLY_COMMITTED',
  CANCELLED = 'CANCELLED',
}


export enum MajorIncidentCategory {
  BUS = 'BUS',
  CHARTER_FLIGHT = 'CHARTER_FLIGHT',
  COMMERCIAL_FLIGHT = 'COMMERCIAL_FLIGHT',
  FERRY = 'FERRY',
  HELICOPTER = 'HELICOPTER',
  TRAIN = 'TRAIN',
}

export enum TaskStatus {
  toDo = 'TODO',
  done = 'DONE',
  supplierInvoice = 'SUPPLIER_INVOICE',
  incomingCases = 'INCOMING_CASES',
  refund = 'REFUND',
  preDeparture = 'PRE_DEPARTURE',
  inbox = 'INBOX',
  recovery = 'RECOVERY',
  recoveryPayment = 'RECOVERY_PAYMENT',
}

export enum TaskType {
  manual = 'MANUAL',
  automatic = 'AUTOMATIC',
  medical = 'MEDICAL',
  sms = 'SMS',
  email = 'EMAIL',
  fax = 'FAX',
  mms = 'MMS',
  customerNote = 'CUSTOMER_NOTE',
  suplierInvoice = 'SUPPLIER_INVOICE',
  refund = 'REFUND',
  consent = 'CONSENT',
  external = 'EXTERNAL',
  supplierNote = 'SUPPLIER_NOTE',
  process = 'PROCESS',
  processGopRequest = 'PROCESS_GOP_REQUEST',
  medicalPreAssessment = 'MEDICAL_PRE_ASSESSMENT',
  recovery = 'RECOVERY',
  recoveryPayment = 'RECOVERY_PAYMENT',
}

export class TaskTypeEnumIconSmall {
  static getSmallIcon(type: TaskType, status: TaskStatus, size = '') {
    return TaskTypeEnumIcon.getIcon(type, status, 'small/');
  }
}

export class TaskTypeEnumIcon {
  static getIcon(type: TaskType, status: TaskStatus, size = '') {
    let result = '';
    const path = '/assets/icons/task/';
    switch (type) {
      case TaskType.medical:
        result = `task-medical`;
        break;
      case TaskType.email:
        result = 'email';
        break;
      case TaskType.fax:
        result = 'fax';
        break;
      case TaskType.mms:
        result = 'mms';
        break;
      case TaskType.sms:
        result = 'sms';
        break;
      case TaskType.customerNote:
      case TaskType.supplierNote:
        result = 'customer-note';
        break;
      case TaskType.automatic:
        result = 'task-auto';
        break;
      case TaskType.manual:
        result = 'task';
        break;
      case TaskType.suplierInvoice:
        result = 'economy-task';
        break;
      case TaskType.refund:
        result = 'refund-task';
        break;
      case TaskType.consent:
        result = 'consent';
        break;
      case TaskType.external:
        result = 'external-case';
        break;
      case TaskType.process:
      case TaskType.processGopRequest:
        result = 'process';
        break;
      case TaskType.medicalPreAssessment:
        result = 'medical-pre-assessment';
        break;
      case TaskType.recovery:
        result = 'recovery';
        break;
      case TaskType.recoveryPayment:
        result = 'recovery-payment';
        break;
    }
    if (status === TaskStatus.toDo) {
      result += '-todo.svg';
    } else {
      result += '-done.svg';
    }
    return path + size + result;
  }
}

export enum SupplierInvoiceStatus {
  invoiceReceived = 'INVOICE_RECEIVED',
  awaitingAccounting = 'AWAITING_ACCOUNTING',
  invoiceCorrected = 'INVOICE_CORRECTED',
  ongoingCostControl = 'ONGOING_COST_CONTROL',
  rejected = 'REJECTED',
  approved = 'APPROVED',
  error = 'ERROR',
  supplierPaid = 'SUPPLIER_PAID'
}

export enum RefundStatus {
  REFUND_REGISTERED = 'REFUND_REGISTERED',
  PENDING_INFORMATION = 'PENDING_INFORMATION',
  INFORMATION_RECEIVED = 'INFORMATION_RECEIVED',
  ONGOING_CLAIMS_HANDLING = 'ONGOING_CLAIMS_HANDLING',
  AWAITING_SECOND_APPROVER = 'AWAITING_SECOND_APPROVER',
  AWAITING_SEND_LETTER = 'AWAITING_SEND_LETTER',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  PAID = 'PAID',
  ERROR = 'ERROR',
  DELETED = 'DELETED',
}

export enum RecoveryPaymentStatus {
  RECEIVED = 'RECEIVED',
  AWAITING_ACCOUNTING = 'AWAITING_ACCOUNTING',
  CORRECTED = 'CORRECTED',
  ONGOING = 'ONGOING',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  ERROR = 'ERROR',
  FINALIZED = 'FINALIZED'
}

export enum RefundAccountType {
  NEMKONTO = 'NEMKONTO',
  OTHER = 'OTHER',
  FOREIGN = 'FOREIGN',
}

export enum RecoveryStatusIndex {
  REQUEST_POA = 1,
  POA_REQUEST_SENT = 2,
  POA_SIGNED = 3,
  APPLICATION_SENT = 4,
  APPLICATION_FOLLOW_UP = 5,
  SETTLED = 6,
  CANCELLED = 6,
  PAYMENT_IN_AS400 = 7
}

export enum RecoveryStatus {
  REQUEST_POA = 'REQUEST_POA',
  POA_REQUEST_SENT = 'POA_REQUEST_SENT',
  SEND_POA_FIRST_REMINDER = 'SEND_POA_FIRST_REMINDER',
  POA_FIRST_REMINDER_SENT = 'POA_FIRST_REMINDER_SENT',
  SEND_POA_SECOND_REMINDER = 'SEND_POA_SECOND_REMINDER',
  POA_SECOND_REMINDER_SENT = 'POA_SECOND_REMINDER_SENT',
  POA_FOLLOW_UP = 'POA_FOLLOW_UP',
  POA_SIGNED = 'POA_SIGNED',
  SEND_APPLICATION = 'SEND_APPLICATION',
  APPLICATION_SENT = 'APPLICATION_SENT',
  APPLICATION_FOLLOW_UP = 'APPLICATION_FOLLOW_UP',
  CANCELLED = 'CANCELLED',
  SETTLED = 'SETTLED',
  PAYMENT_IN_AS400 = 'PAYMENT_IN_AS400'
}

export enum ServiceOrderStatus {
  expected = 'EXPECTED',
  committed = 'COMMITTED',
  invoiceReceived = 'INVOICE_RECEIVED',
  awaitingAccounting = 'AWAITING_ACCOUNTING',
  invoiceCorrected = 'INVOICE_CORRECTED',
  ongoingCostControl = 'ONGOING_COST_CONTROL',
  approved = 'APPROVED',
  supplierPaid = 'SUPPLIER_PAID',
  customerCharged = 'CHARGED',
  cancelled = 'CANCELLED',
  draft = 'DRAFT',
  pendingInformation = 'PENDING_INFORMATION',
  paid = 'PAID',
}

export enum QuantityUnit {
  days = 'DAY',
  medicalVisit = 'MV',
  pcs = 'PCS',
  hours = 'HR',
  km = 'KM',
}

export enum SortOrder {
  asc = 'ASC',
  desc = 'DESC',
}

export enum FeeTypeEnum {
  PRIMARY = 'Primary case fee',
  SECONDARY = 'Secondary case fee',
}

export enum LimitTypeEnum {
  REASONABLE = 'REASONABLE',
  AMOUNT = 'AMOUNT',
  NUMBER_OF_PERSONS = 'NUMBER_OF_PERSONS',
  DURATION = 'DURATION',
  QUANTITY = 'QUANTITY',
  AGE = 'AGE',
}

export enum LimitFromTypeEnum {
  INCIDENT_DATE = 'INCIDENT_DATE',
  FIRST_MEDICAL_VISIT = 'FIRST_MEDICAL_VISIT',
}

export enum LimitPersonTypeEnum {
  PERSON = 'PERSON',
}

export enum LimitQuantityTypeEnum {
  TREATMENTS = 'TREATMENTS',
}

export enum CoverageTypeEnum {
  BASIC = 'BASIC',
  SUPPLEMENT = 'SUPPLEMENT',
}

export enum CoveragePeriodUnitEnum {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

export enum MessageChannelType {
  FAX = 'FAX',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export enum ValidationCauses {
  INCIDENT_LOCATION = 'Incident location'
}

export enum MessageFrom {
  EMAIL_SENDER_SOS = 'EMAIL_SENDER_SOS',
  EMAIL_SENDER_ADM = 'EMAIL_SENDER_ADM',
  EMAIL_SENDER_CLAIMS = 'EMAIL_SENDER_CLAIMS',
  EMAIL_SENDER_RECOVERY = 'EMAIL_SENDER_RECOVERY',
  EMAIL_SENDER_ATTORNEY = 'EMAIL_SENDER_ATTORNEY',
  EMAIL_SENDER_CLAIMS_CORPORATE = 'EMAIL_SENDER_CLAIMS_CORPORATE',
  EMAIL_SENDER_PRE_DEPARTURE = 'EMAIL_SENDER_PRE_DEPARTURE'
}

export enum SavingTypedInField {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

export enum GopMethodEnum {
  UNSPECIFIED = 'UNSPECIFIED',
  DIRECT_PROVIDER = 'DIRECT_PROVIDER',
  VIA_AGENT_OFFICE = 'VIA_AGENT_OFFICE',
  COPY_AGENT_OFFICE = 'COPY_AGENT_OFFICE',
}

export enum GopMethodEnumSearchSupplier {
  UNSPECIFIED = 'UNSPECIFIED',
  DIRECT_PROVIDER = 'Direct to provider',
  VIA_AGENT_OFFICE = 'GOP via agent / SOS Service Office',
  COPY_AGENT_OFFICE = 'Copy of GOP to agent / SOS Service Office',
}


export enum PreferredChannelEnum {
  FIXED_PHONE = 'Fixed phone',
  MOBILE_PHONE = 'Mobile phone',
  SMS = 'SMS',
  FAX = 'Fax',
  EMAIL = 'Email',
}

export enum SupplierInvoiceGroupEnum {
  ambulance_flight = 1,
  spain = 2,
  thailand = 3,
  egencia = 4,
  gmmi = 5,
  other = 6,
}

export enum PermissionHideTypeEnum {
  DISABLE,
  HIDE,
  REMOVE,
}

export enum PermissionEnum {
  NONE = '',
  AC_STATUS_UPDATE = 'AC Status - Update',
  CASE_BASIC_CREATE = 'Case Basic - Create',
  CASE_BASIC_UPDATE = 'Case Basic - Update',
  CASE_BASIC_DELETE = 'Case Basic - Delete',
  CASE_BASIC_READ = 'Case Basic - Read',
  CASE_SUMMARY_FULL_DETAILS_READ = 'Case Summary full details - Read',
  CASE_SUMMARY_READ = 'Case Summary - Read',
  COMMUNICATION_CREATE = 'Communication - Create',
  COMMUNICATION_UPDATE = 'Communication - Update',
  COMMUNICATION_DELETE = 'Communication - Delete',
  COMMUNICATION_READ = 'Communication - Read',
  COMMUNICATION_GDPR_DELETE = 'Communication GDPR - Delete',
  CUSTOMER_AND_SOS_SERVICE_CREATE = 'Customer and SOS Service - Create',
  CUSTOMER_AND_SOS_SERVICE_UPDATE = 'Customer and SOS Service - Update',
  CUSTOMER_AND_SOS_SERVICE_DELETE = 'Customer and SOS Service - Delete',
  CUSTOMER_AND_SOS_SERVICE_READ = 'Customer and SOS Service - Read',
  DOCUMENT_CREATE = 'Document - Create',
  DOCUMENT_UPDATE = 'Document - Update',
  DOCUMENT_DELETE = 'Document - Delete',
  DOCUMENT_READ = 'Document - Read',
  NOTE_CREATE = 'Note - Create',
  NOTE_UPDATE = 'Note - Update',
  NOTE_DELETE = 'Note - Delete',
  NOTE_READ = 'Note - Read',
  SALES_ORDER_CREATE = 'Sales Order - Create',
  SALES_ORDER_UPDATE = 'Sales Order - Update',
  SALES_ORDER_DELETE = 'Sales Order - Delete',
  SALES_ORDER_READ = 'Sales Order - Read',
  SEARCH_READ = 'Search - Read',
  PLANS_CREATE = 'Plans - Create',
  PLANS_UPDATE = 'Plans - Update',
  PLANS_DELETE = 'Plans - Delete',
  PLANS_READ = 'Plans - Read',
  STAKEHOLDER_CREATE = 'Stakeholder - Create',
  STAKEHOLDER_UPDATE = 'Stakeholder - Update',
  STAKEHOLDER_DELETE = 'Stakeholder - Delete',
  STAKEHOLDER_READ = 'Stakeholder - Read',
  SUPPLIER_INVOICE_CREATE = 'Supplier Invoice - Create',
  SUPPLIER_INVOICE_UPDATE = 'Supplier Invoice - Update',
  SUPPLIER_INVOICE_DELETE = 'Supplier Invoice - Delete',
  SUPPLIER_INVOICE_READ = 'Supplier Invoice - Read',
  TASK_CREATE = 'Task - Create',
  TASK_UPDATE = 'Task - Update',
  TASK_DELETE = 'Task - Delete',
  TASK_READ = 'Task - Read',
  TASK_SUPPLIER_INVOICE_READ = 'Task Supplier Invoice - Read',
  TASK_SUPPLIER_INVOICE_UPDATE = 'Task Supplier Invoice - Update',
  MEDICAL_CREATE = 'Medical - Create',
  MEDICAL_UPDATE = 'Medical - Update',
  MEDICAL_DELETE = 'Medical - Delete',
  MEDICAL_READ = 'Medical - Read',
  MEDICAL_PRE_ASSESSMENT_CREATE = 'Medical pre-assessment - Create',
  MEDICAL_PRE_ASSESSMENT_UPDATE = 'Medical pre-assessment - Update',
  MEDICAL_PRE_ASSESSMENT_DELETE = 'Medical pre-assessment - Delete',
  MEDICAL_PRE_ASSESSMENT_READ = 'Medical pre-assessment - Read',
  MEDICAL_ESCORT_SUPPLIER_INVOICE_CREATE = 'Medical escort supplier Invoice - Create',
  MEDICAL_ESCORT_SUPPLIER_INVOICE_UPDATE = 'Medical escort supplier Invoice - Update',
  MEDICAL_ESCORT_SUPPLIER_INVOICE_DELETE = 'Medical escort supplier Invoice - Delete',
  MEDICAL_ESCORT_SUPPLIER_INVOICE_READ = 'Medical escort supplier Invoice - Read',
  TEMPLATES_AND_BUSINESS_RULES_READ = 'Templates and business rules - Read',
  REFUND_CREATE = 'Refund - Create',
  REFUND_UPDATE = 'Refund - Update',
  REFUND_READ = 'Refund - Read',
  REFUND_DELETE = 'Refund - Delete',
  RECOVERY_CREATE = 'Recovery - Create',
  RECOVERY_UPDATE = 'Recovery - Update',
  RECOVERY_READ = 'Recovery - Read',
  RECOVERY_DELETE = 'Recovery - Delete',
}

export enum ContentType {
  text = 'TEXT',
  html = 'HTML',
}

export enum CommunicationDirection {
  IN_BOUND = 'IN_BOUND',
  OUT_BOUND = 'OUT_BOUND',
  IN_OUT_BOUND = 'IN_OUT_BOUND',
}

export enum AssessmentBasesOn {
  medicalReport = 'MEDICAL_REPORT',
  phoneCall = 'PHONE_CALL',
  medicalHistoryJournal = 'MEDICAL_HISTORY_JOURNAL',
  acScreening = 'AC_SCREENING',
  caseSummary = 'CASE_SUMMARY',
}

export enum MedicalHistoryType {
  regularMedicalAssessment = 'REGULAR_MEDICAL_ASSESSMENT',
  acScreening = 'AC_SCREENING',
  medicalNote = 'MEDICAL_NOTE',
  medicalReportSupplierPortal = 'MEDICAL_REPORT_SUPPLIER_PORTAL',
  medicalPreDeparture = 'MEDICAL_PRE_DEPARTURE',
}

export enum InputType {
  text = 'text',
  password = 'password',
  number = 'number',
}

export enum CaseDocumentTagEnum {
  MEDICAL_INFO = 'Medical info',
  TRAVEL_INFO = 'Travel info',
  PASSPORT_AND_ID = 'Passport and ID',
  INSURANCE_INFO = 'Insurance info',
  GOP_VOB = 'GOP / VOB',
  GOP_VOB_CANCEL = 'GOP / VOB cancelled',
  CLAIM_FORM = 'Claim form',
  RECEIPT = 'Receipt',
  CONSENT = 'Consent accepted',
  CONSENT_REJECTED = 'Consent rejected',
  MEDIF = 'MEDIF',
  CLAIM_LETTER = 'Claim letter',
  MEDICAL_PRE_ASSESSMENT = 'Medical pre-assessment',
  RECOVERY_DECISION = 'Recovery decision',
  RECOVERY_POA_REQUEST = 'Recovery POA Request',
  RECOVERY_POA_FIRST_REMINDER = 'Recovery POA Request First Reminder',
  RECOVERY_POA_SECOND_REMINDER = 'Recovery POA Request Second Reminder',
  RECOVERY_POA_SIGNED = 'Recovery POA Signed',
  RECOVERY_APPLICATION = 'Recovery Application',
  RECOVERY_NOT_POSSIBLE = 'Recovery not possible',
  INVESTIGATION = 'Investigation report',
  TRANSLATION = 'Translation report',
  SUPPLIER_INVOICE = 'Supplier invoice',
  RECOVERY_PAYMENT = 'Recovery payment',
  ESCORT_TRAVEL_EXPENSES = 'Escort travel expenses',
  MEDICAL_ESCORT_INVOICE = 'Medical escort invoice',
  CUSTOMER_INVOICE = 'Customer invoice',
  REMITTANCE_ADVICE = 'Remittance advice',
}

export enum TravelPurposeEnum {
  LEISURE = 'Holiday / Leisure',
  BUSINESS = 'Business',
  EXPATRIATION = 'Expatriation',
  STUDYING = 'Studying',
  MEDICAL_TREATMENT = 'Medical treatment',
  SPORT = 'Sport (extreme, professional, competition, etc)',
  TRAINEE = 'Trainee / Volunteer (unpaid)',
  AU_PAIR = 'Au pair',
  EXPEDITION = 'Expedition',
}

export enum MedicalAssessmentBasedOnEnum {
  MEDICAL_REPORT = 'MEDICAL_REPORT',
  VOICE = 'VOICE',
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  CASE_INFORMATION = 'CASE_INFORMATION',
  VIDEO_CALL = 'VIDEO_CALL',
  WEB = 'WEB'
}

export enum MedicalActionTypeEnum {
  X_RAY = 'X_RAY',
  CT_SCAN = 'CT_SCAN',
  MR_SCAN = 'MR_SCAN',
  ULTRASOUND_SCAN = 'ULTRASOUND_SCAN',
  SURGERY = 'SURGERY',
  GASTROSCOPY = 'GASTROSCOPY',
  COLONOSCOPY = 'COLONOSCOPY',
  ECHO = 'ECHO',
  CORONARY_ANGIOGRAPHY = 'CORONARY_ANGIOGRAPHY',
  ANGIOPLASTY = 'ANGIOPLASTY',
  TREADMILL_TEST = 'TREADMILL_TEST',
  DOPPLERSCAN = 'DOPPLERSCAN',
  EEG = 'EEG',
  WOUND_DRESSING = 'WOUND_DRESSING',
  OTHER = 'OTHER',
}

export enum MedicalExaminationsTypeEnum {
  ACUTELY = 'ACUTELY',
  NOT_ACUTELY = 'NOT_ACUTELY',
}

export enum MedicalAdmissionTypeEnum {
  REGULAR = 'REGULAR',
  INTENSIVE = 'INTENSIVE',
}

export enum EquipmentItemEnum {
  URINARY_BLADDER_CATHETER_KIT = 'URINARY_BLADDER_CATHETER_KIT',
  CHEST_DRAINAGE_KIT = 'CHEST_DRAINAGE_KIT',
  INTUBATION_KIT = 'INTUBATION_KIT',
  PROPAQ_LT = 'PROPAQ_LT',
  PROPAQ_MD = 'PROPAQ_MD',
  OTHER = 'OTHER',
}

export enum EscortEnum {
  DOCTOR = 'DOCTOR',
  NURSE = 'NURSE',
  NON_MEDICAL_ESCORT = 'NON_MEDICAL_ESCORT',
}

export enum ExtraSpaceEnum {
  SEAT_IN_FRONT = 'SEAT_IN_FRONT',
  SIDE_BY_SIDE_TWO = 'SIDE_BY_SIDE_TWO',
  SIDE_BY_SIDE_TREE = 'SIDE_BY_SIDE_TREE',
  RECLINING_SEAT = 'RECLINING_SEAT',
  OTHER = 'OTHER',
}

export enum IntensiveCarePatientEnum {
  NOT_SEA_LEVEL = 'NOT_SEA_LEVEL',
  SEA_LEVEL = 'SEA_LEVEL',
}

export enum IntensiveCareStepCategoryEnum {
  STEP_ZERO = 'STEP_ZERO',
  STEP_ONE_A = 'STEP_ONE_A',
  STEP_ONE_B = 'STEP_ONE_B',
  STEP_TWO_A = 'STEP_TWO_A',
  STEP_TWO_B = 'STEP_TWO_B',
  STEP_TREE = 'STEP_TREE',
  STEP_TREE_A = 'STEP_TREE_A',
  STEP_TREE_B = 'STEP_TREE_B',
  STEP_TREE_C = 'STEP_TREE_C',
  STEP_FOUR_A = 'STEP_FOUR_A',
  STEP_FOUR_B = 'STEP_FOUR_B',
  STEP_FOUR_C = 'STEP_FOUR_C',
  STEP_FIVE = 'STEP_FIVE',
}

export enum MedicalEscortSpecialtyEnum {
  NO_PARTICULAR_SPECIALIZATION_REQUIRED = 'NO_PARTICULAR_SPECIALIZATION_REQUIRED',
  ANESTHESIOLOGY = 'ANESTHESIOLOGY',
  PSYCHIATRY = 'PSYCHIATRY',
  OTHER = 'OTHER',
}

export enum MedicationItemEnum {
  PAIN_KIT_ONE = 'PAIN_KIT_ONE',
  PAIN_KIT_TWO = 'PAIN_KIT_TWO',
  CARDIO_PULMONARY_KIT = 'CARDIO_PULMONARY_KIT',
  ICU_KIT = 'ICU_KIT',
  OTHER = 'OTHER',
}

export enum OxygenAvailabilityEnum {
  CONTINUOUSLY = 'CONTINUOUSLY',
  ON_DEMAND = 'ON_DEMAND',
}

export enum RepatriationFormEnum {
  INTENSIVE_CARE_PATIENT = 'INTENSIVE_CARE_PATIENT',
  STRETCHER = 'STRETCHER',
  EXTRA_SPACE = 'EXTRA_SPACE',
  SITTING_SICK_IN_ECONOMY_CLASS_SEAT = 'SITTING_SICK_IN_ECONOMY_CLASS_SEAT',
  REGULAR_PASSENGER = 'REGULAR_PASSENGER',
  TOO_SOON_TO_ESTIMATE = 'TOO_SOON_TO_ESTIMATE',
}

export enum RepatriationMediumEnum {
  IN_FLIGHT = 'IN_FLIGHT',
  ON_GROUND = 'ON_GROUND',
  IN_FLIGHT_AND_ON_GROUND = 'IN_FLIGHT_AND_ON_GROUND',
}

export enum TransitEnum {
  GROUND_TRANSPORT = 'GROUND_TRANSPORT',
  LIFT_VAN = 'LIFT_VAN',
  AMBULANCE = 'AMBULANCE',
}

export enum WheelchairNeedEnum {
  CARRY_TO_RAMP = 'CARRY_TO_RAMP',
  CARRY_UP_AND_DOWN_STEP = 'CARRY_UP_AND_DOWN_STEP',
  CARRY_TO_CHAIR = 'CARRY_TO_CHAIR',
  NO = 'NO',
}

export enum CastSideEnum {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export enum UserDepartmentEnum {
  OTHER = 'OTHER',
}

export enum MeansOfTransportationEnum {
  AIRLINE = 'AIRLINE',
  CAR = 'CAR',
  BUS = 'BUS',
  TRAIN = 'TRAIN',
  CRUISE_FERRY = 'CRUISE_FERRY',
  BOAT = 'BOAT',
  MOTORCYCLE = 'MOTORCYCLE',
  BICYCLE = 'BICYCLE',
}

export enum AccommodationEnum {
  HOTEL = 'HOTEL',
  RENTED_APARTMENT = 'RENTED_APARTMENT',
  CAMPING = 'CAMPING',
  PRIVATE_HOME = 'PRIVATE_HOME',
  RELATIVE = 'RELATIVE',
  TENT = 'TENT',
}

export enum PhoneNumberType {
  Fixed = 'FIXED',
  Mobile = 'MOBILE',
  Fax = 'FAX',
}

export enum ConsentStatusEnum {
  CONSENT_REQUEST_NOT_SENT = 'CONSENT_REQUEST_NOT_SENT',
  REQUESTED = 'REQUESTED',
  REQUESTED_MANUALLY = 'REQUESTED_MANUALLY',
  REQUESTED_MANUALLY_AGAIN = 'REQUESTED_MANUALLY_AGAIN',
  ORAL = 'ORAL',
  CONSENT_NOT_POSSIBLE = 'CONSENT_NOT_POSSIBLE',
  CONSENT_ACCEPTED = 'CONSENT_ACCEPTED',
  CONSENT_ACCEPTED_MAN = 'CONSENT_ACCEPTED_MAN',
  CONSENT_ACCEPTED_WEB = 'CONSENT_ACCEPTED_WEB',
  CONSENT_REJECTED = 'CONSENT_REJECTED',
  UNKNOWN = 'UNKNOWN',
  RESEND_MANUALLY = 'RESEND_MANUALLY',
  MANUALLY = 'MANUALLY',
  AUTO_FAILED = 'AUTO_FAILED',
}

export enum ConsentRequestEnum {
  AUTO = 'AUTO',
  MANUALLY = 'MANUALLY',
  ORAL = 'ORAL',
  CONSENT_NOT_POSSIBLE = 'CONSENT_NOT_POSSIBLE',
  RESEND_MANUALLY = 'RESEND_MANUALLY',
  CONSENT_ACCEPTED = 'CONSENT_ACCEPTED',
  CONSENT_REJECTED = 'CONSENT_REJECTED',
  CONSENT_ACCEPTED_MAN = 'CONSENT_ACCEPTED_MAN'
}

export enum AssessmentTypeEnum {
  REGULAR = 'REGULAR',
  LIGHT = 'LIGHT',
  NIGHT = 'NIGHT',
  CLAIMS = 'CLAIMS',
  NONE = 'NONE',
  PREASSESSMENT = 'PREASSESSMENT',
  PREASSESSMENT_TRAVEL = 'PREASSESSMENT_TRAVEL',
  PREASSESSMENT_CANCELLATION = 'PREASSESSMENT_CANCELLATION'
}

export enum HandlingAreaType {
  CASE_HANDLER = 'CASE_HANDLER',
  MEDICAL = 'MEDICAL',
  TRANSPORT = 'TRANSPORT',
  COST_CONTROL = 'COST_CONTROL',
  CLAIMS = 'CLAIMS',
  RECOVERY = 'RECOVERY',
}

export enum ERROR_TYPE {
  ERROR = 'ERROR',
  HTTP_ERROR = 'HTTP_ERROR',
}

export enum AssigneeEnum {
  MEDICAL_STAFF = 'MEDICAL_STAFF',
  MEDICAL_ADVICE = 'MEDICAL_ADVICE',
  PRE_DEPARTURE_MEDICAL_ADVICE = 'PRE_DEPARTURE_MEDICAL_ADVICE'
}

export enum TransportTypeEnum {
  TAXI = 'TAXI',
  RENTAL_CAR = 'RENTAL_CAR',
  FERRY = 'FERRY',
  TRAIN = 'TRAIN',
  FUNERAL_CAR = 'FUNERAL_CAR',
  GROUND_AMBULANCE = 'GROUND_AMBULANCE',
  LIFT_VAN = 'LIFT_VAN',
  HELICOPTER = 'HELICOPTER',
  OTHER_RESCUE_TRANSPORT = 'OTHER_RESCUE_TRANSPORT',
}

export enum MortalRemainsType {
  NOT_DECIDED = 'NOT_DECIDED',
  CREMATION = 'CREMATION',
  EMBALMING = 'EMBALMING',
}

export enum MedicalNoteTypeEnum {
  COMMENT = 'COMMENT',
  ADMISSION = 'ADMISSION',
  PRE_ASSESSMENT_COMMENT = 'PRE_ASSESSMENT_COMMENT'
}

export enum PlansAccommodationTypeEnum {
  HOTEL = 'HOTEL',
  APARTMENT = 'APARTMENT',
  OTHER = 'OTHER',
}

export enum SalesOrderItemTypeEnum {
  FEE = 'FEE',
  SUPPLIER_INVOICE = 'SUPPLIER_INVOICE',
  MEDICAL_ESCORT = 'MEDICAL_ESCORT',
  REFUND = 'REFUND',
  RECOVERY_PAYMENT = 'RECOVERY_PAYMENT',
  RECOVERY_FEE = 'RECOVERY_FEE',
}

export enum AttachmentTypeEnum {
  FROM_TEMPLATE = 'FROM_TEMPLATE',
  GENERATED_FROM_TEMPLATE = 'GENERATED_FROM_TEMPLATE',
  FROM_USER = 'FROM_USER',
}

export enum FeatureEnum {
  DEFAULT = 'DEFAULT',
  RECOVERY_POA_REQUEST = 'RECOVERY_POA_REQUEST',
  RECOVERY_FIRST_REMINDER = 'RECOVERY_FIRST_REMINDER',
  RECOVERY_SECOND_REMINDER = 'RECOVERY_SECOND_REMINDER',
  RECOVERY_APPLICATION = 'RECOVERY_APPLICATION',
}

export enum DocumentTypeEnum {
  ATTACHMENT = 'ATTACHMENT',
  DOCUMENT = 'DOCUMENT',
  MEDIF = 'MEDIF',
}

export enum FinancialStatusEnum {
  OPEN = 'OPEN',
  PARTLY_INVOICED = 'PARTLY_INVOICED',
  INVOICED_AND_CLOSED = 'INVOICED_AND_CLOSED',
  ON_HOLD_AWAITING_INFO = 'ON_HOLD_AWAITING_INFO',
  CLOSED_NO_INVOICE = 'CLOSED_NO_INVOICE',
}

export enum MedicalEscortExpenseItemEnum {
  SI36 = 'SI36',
  SI37 = 'SI37',
  SI38 = 'SI38',
  SI43 = 'SI43',
}

export enum SalesOrderStatusEnum {
  NOT_INVOICED = 'NOT_INVOICED',
  SENT_TO_ERP = 'SENT_TO_ERP',
  INVOICED = 'INVOICED',
  PAID = 'PAID',
  ERROR = 'ERROR',
  COMPLETED = 'COMPLETED',
}

export enum CustomerInvoiceJobStatusEnum {
  DEBTOR_NOT_FOUND = 'DEBTOR_NOT_FOUND',
  DEADLINE_PASSED = 'DEADLINE_PASSED',
  FINANCIAL_STATUS_NO_INVOICING = 'FINANCIAL_STATUS_NO_INVOICING',
  CUSTOMER_REFERENCE_MISSING = 'CUSTOMER_REFERENCE_MISSING',
  FINANCIAL_STATUS_ON_HOLD = 'FINANCIAL_STATUS_ON_HOLD',
  MANUAL_HANDLING_FINANCIAL_STATUS = 'MANUAL_HANDLING_FINANCIAL_STATUS',
  CASE_INFORMATION_MISSING = 'CASE_INFORMATION_MISSING',
  DEBTOR_SUSPENDED = 'DEBTOR_SUSPENDED',
  INVOICING_SUSPENDED = 'INVOICING_SUSPENDED',
  BELOW_INVOICING_THRESHOLD_ONGOING = 'BELOW_INVOICING_THRESHOLD_ONGOING',
  BELOW_INVOICING_THRESHOLD_DEPOSIT = 'BELOW_INVOICING_THRESHOLD_DEPOSIT',
  CASE_NOT_CLOSED_CASE_CLOSED = 'CASE_NOT_CLOSED_CASE_CLOSED',
  CASE_NOT_CLOSED_ONGOING = 'CASE_NOT_CLOSED_ONGOING',
  MANUAL_HANDLING_FEE_SUGGESTED = 'MANUAL_HANDLING_FEE_SUGGESTED',
  MANUAL_HANDLING_FEE_SUGGESTED_OLD = 'MANUAL_HANDLING_FEE_SUGGESTED_OLD',
  MANUAL_HANDLING_SAME_FEE = 'MANUAL_HANDLING_SAME_FEE',
  MANUAL_HANDLING_NO_FEE = 'MANUAL_HANDLING_NO_FEE',
  BELOW_INVOICING_THRESHOLD_TRIVIAL = 'BELOW_INVOICING_THRESHOLD_TRIVIAL',
  MAX_INVOICES_DEBTOR = 'MAX_INVOICES_DEBTOR',
  MAX_INVOICES_SOS = 'MAX_INVOICES_SOS',
  READY = 'READY',
  PROCESS_COMPLETED = 'PROCESS_COMPLETED',
  PROCESSED_MANUALLY = 'PROCESSED_MANUALLY',
  NOT_OLD_ENOUGH = 'NOT_OLD_ENOUGH',
}

export enum SosServiceCategoriesEnum {
  ADDITIONAL_MEDICAL_SERVICES = 'ADDITIONAL_MEDICAL_SERVICES',
  ADDITIONAL_MEDICAL_SERVICES_ESCORT = 'ADDITIONAL_MEDICAL_SERVICES_ESCORT',
  ALARM_CENTER = 'ALARM_CENTER',
  MEDICAL_FLIGHT_AUTHORIZATION = 'MEDICAL_FLIGHT_AUTHORIZATION',
  CLAIMS_TRAVEL = 'CLAIMS_TRAVEL',
  PRE_DEPARTURE_TRAVEL_SERVICE = 'PRE_DEPARTURE_TRAVEL_SERVICE',
  OTHER = 'OTHER',
  SHARE_OF_COST_CONTROL = 'SHARE_OF_COST_CONTROL',
  COMMUNICATION_COST = 'COMMUNICATION_COST',
}

export enum DocumentGroupChannel {
  EMAIL = 'EMAIL',
  FAX = 'FAX',
  MESSAGE = 'MESSAGE',
  WEB = 'WEB',
}

export enum HasOtherInsurance {
  YES = 'YES',
  NO = 'NO',
  NOT_SURE = 'NOT_SURE',
  NOT_ASKED = 'NOT_ASKED',
}

export enum SensitiveType {
  SENSITIVE = 'SENSITIVE',
  NOT_SENSITIVE = 'NOT_SENSITIVE',
}

export enum EventCategory {
  ATTACKS_INCL_TERROR = 'Attacks incl terror',
  BANKRUPTCY = 'Bankruptcy',
  EPIDEMIC_PANDEMIC_OUTBREAK = 'Epidemic/pandemic outbreak',
  GOVERNMENTAL_INCIDENT = 'Governmental incident',
  NATURAL_DISASTER = 'Natural disaster',
  SPORT_EVENT = 'Sport event',
  STRIKE = 'Strike',
  TRANSPORT_INCIDENT = 'Transport incident',
  WAR_CIVIL_UNREST = 'War/civil unrest',
  OTHER_EVENT = 'Other event',
}

/* export enum IsCoveredListEnum {
  PARTIALLY_COVERED = 'PARTIALLY_COVERED',
  FULLY_COVERED = 'FULLY_COVERED',
} */
export enum MedicalPreAssessmentStatusEnum {
  UPDATED = 'UPDATED',
  ONGOING = 'ONGOING',
  MEDICAL_EVALUATION = 'MEDICAL_EVALUATION',
  AWAITING = 'AWAITING',
  DONE = 'DONE',
  DRAFT = 'DRAFT',
  CANCELLED = 'CANCELLED'
}

export enum MedicalPreAssessmentDecisionEnum {
  PARTIALLY_COVERED = 'PARTIALLY_COVERED',
  FULLY_COVERED = 'FULLY_COVERED',
  CASE_HANDLING = 'CASE_HANDLING',
  REQUEST_INFORMATION = 'REQUEST_INFORMATION',
  DOCTOR = 'DOCTOR',
  NURSE = 'NURSE',
  CANCELLED = 'CANCELLED',
  CREATE_CASE_BASED_ON_WEB = 'CREATE_CASE_BASED_ON_WEB'
}

export enum MedicalPreAssessmentTypeEnum {
  PREASSESSMENT_TRAVEL = 'PREASSESSMENT_TRAVEL',
  PREASSESSMENT_CANCELLATION = 'PREASSESSMENT_CANCELLATION'
}

export enum MedicalPreAssessmentRelevantEnum {
  YES = 'YES',
  NO = 'NO'
}

export enum EconomyHistoryFilterType {
  CUSTOMER_INVOICE = 'CUSTOMER_INVOICE',
  CUSTOMER_INVOICE_ITEM = 'CUSTOMER_INVOICE_ITEM',
  RECOVERY_PAYMENT = 'RECOVERY_PAYMENT',
  RECOVERY_PAYMENT_ITEM = 'RECOVERY_PAYMENT_ITEM',
  REFUND = 'REFUND',
  REFUND_ITEM = 'REFUND_ITEM',
  SOS_SERVICE = 'SOS_SERVICE',
  SUPPLIER_INVOICE = 'SUPPLIER_INVOICE',
  SUPPLIER_INVOICE_ITEM = 'SUPPLIER_INVOICE_ITEM'
}

export enum CoordinationTypeEnum {
  AMBULANCE_FLIGHT_PER_QUOTE = 'AMBULANCE_FLIGHT_PER_QUOTE',
  AMBULANCE_FLIGHT_PRE_BOOKED = 'AMBULANCE_FLIGHT_PRE_BOOKED',
  MAJOR_INCIDENT = 'MAJOR_INCIDENT'
}

export enum TransportAssistanceType {
  YES = 'YES',
  NO = 'NO',
  TO_BE_DETERMINED = 'TO_BE_DETERMINED'
}

export enum ServiceTypeItemEnum {
  ONE_TO_ONE = 'ONE_TO_ONE',
  OTHER = 'OTHER',
  RECOVERY = 'RECOVERY'
}

export enum CaseDetails {
  REG =	'REG',
  AIR	= 'AIR',
  EXT	= 'EXT',
  SIT =	'SIT',
  EQU = 'EQU',
  STR =	'STR',
 	EQM = 'EQM',
  OXY = 'OXY',
  DOC =	'DOC',
	NUR =	'NUR',
	NME =	'NME',
	URN =	'URN',
	COF =	'COF',
	TBD =	'TBD',
	REM =	'REM',
	NIM =	'NIM',
	LIM =	'LIM',
	CLM =	'CLM',
	OUT =	'OUT',
	INP =	'INP',
}
