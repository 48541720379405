import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SettingsService } from '@secca/core/services/settings.service';
import { MessageTemplate, MessageTemplateAdapter } from '@secca/shared/models/message-template';
import { BaseService } from '@secca/core/services/base.service';
import { MessageBodyAdapter } from '@secca/case/components/case-output-management/models/messageBody';
import {MessageService} from '@secca/core/services/message.service';
import {ServiceOrderMessageRequest} from '@secca/shared/models/service-order/service-order-message-request';
import {DocumentTemplateFilter} from '@secca/case/components/case-plans/case-plans/add-service-order/sub-components/sub-gop-details/documentTemplateFilter';
import {MessageChannelType} from '@secca/shared/models/enums';
import {DeleteReason} from '@secca/shared/models/deleteReason';

@Injectable({
  providedIn: 'root'
})
export class CaseMessageService extends BaseService implements MessageService {

  constructor(private http: HttpClient,
              private messageTemplateAdapter: MessageTemplateAdapter,
              private emailBodyAdapter: MessageBodyAdapter,
              private settingsService: SettingsService) {
    super(settingsService);
  }

  public getMessageTemplates(request: ServiceOrderMessageRequest): Observable<MessageTemplate[]> {
    return this.http
      .post<MessageTemplate[]>(this.baseURL + `message-templates/by-filter`, this.prepareTemplateFilterBodyRequest(request))
      .pipe(map((data: any[]) => data.map(item => this.messageTemplateAdapter.adapt(item))));
  }

  public getEmailBody(request: ServiceOrderMessageRequest, templateStorageId: string): Observable<any> {
    return this.http.get(this.baseURL + 'documents/email/as/string', {
      params: this.prepareParamsForEmailBodyRequest(request.message.caseId, templateStorageId, request.message.receiver.id, request.message.refundId)
    }).pipe(map((data) => this.emailBodyAdapter.adapt(data)));
  }

  public getSmsBody(request: ServiceOrderMessageRequest, templateStorageId: string): Observable<any> {
    return this.http.get(this.baseURL + 'documents/sms/as/string', {
      params: this.prepareParamsForEmailBodyRequest(request.message.caseId, templateStorageId, request.message.receiver.id, null)
    }).pipe(map((data) => this.emailBodyAdapter.adapt(data)));
  }

  public deleteCaseMessageHistory(caseId: string, caseHistoryId: number): Observable<void> {
    return this.http.delete(this.baseURL + 'casehistory/delete-case-message/' + caseId + '/' + caseHistoryId, {
      headers: this.jsonHeaders
    }).pipe(map(item => null));
  }

  public deleteCaseMessageHistoryWithDeleteReason(caseId: string, caseHistoryId: number, reason: string): Observable<void> {
    const deleteReasonObj = new DeleteReason({reason});
    return this.http.put(this.baseURL + 'casehistory/delete-case-message-delete-reason/' + caseId + '/' + caseHistoryId, deleteReasonObj, {
      headers: this.jsonHeaders
    }).pipe(map(item => null));
  }

  public deleteCaseMessageId(caseMessageId: string): Observable<void> {
    return this.http.delete(this.baseURL + 'sms/delete-case-sms/' + caseMessageId, {
      headers: this.jsonHeaders
    }).pipe(map(item => null));
  }

  public deleteCaseMessageIdWithDeleteReason(caseMessageId: string, reason: string): Observable<void> {
    const deleteReasonObj = new DeleteReason({reason});
    return this.http.put(this.baseURL + 'sms/delete-case-sms-delete-reason/' + caseMessageId, deleteReasonObj,  {
      headers: this.jsonHeaders
    }).pipe(map(item => null));
  }

  private prepareParamsForEmailBodyRequest(caseId: string, templateStorageId: string, receiverId: string, refundId: number): HttpParams {
    let params = new HttpParams();
    params = params.append('caseId', caseId);
    params = params.append('templateStorageId', templateStorageId);
    if (receiverId) {
      params = params.append('receiverStakeholderId', receiverId);
    }
    if (refundId) {
      params = params.append('refundId', refundId);
    }
    return params;
  }

  private prepareTemplateFilterBodyRequest(messageRequest: ServiceOrderMessageRequest): DocumentTemplateFilter {
    return new DocumentTemplateFilter(this.resolveDocumentType(messageRequest.message.messageChannelType),
      messageRequest.message.templateType ? [messageRequest.message.templateType] : null, null);
  }

  private resolveDocumentType(channelType: MessageChannelType): string {
    switch (channelType) {
      case MessageChannelType.EMAIL:
      case MessageChannelType.FAX:
        return 'EMAIL_FAX';
      case MessageChannelType.SMS:
        return 'SMS';
      default:
    }
    return null;
  }

}
