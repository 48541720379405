<div appDialogSelectable appDialogKeyboardEnabled [enabledKeys]="enabledKeys">
  <mat-dialog-content (cdkDragStarted)="dialogBoundaryService.handleDragStart($event)" (cdkDragEnded)="dialogBoundaryService.handleDragEnd($event)" cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
    <div appDialogDragArea cdkDragHandle></div>

    <div class="task-message-modal-move-dialog-container">
      <div class="modal-header">
        <div class="modal-header-title">{{ 'dialog-move-recovery-payment-title' | translate }}</div>
        <div class="modal-buttons">
          <div (click)="close(undefined)">
            <img src="/assets/icons/Close White.svg" alt="Close window button"/>
          </div>
        </div>
      </div>

      <div class="modal-content-body">
        <div class="input-text-header">
          <div class="modal-text">
            {{ 'dialog-move-recovery-payment-text' | translate }}
          </div>

          <div *ngIf="shouldShowLookup">
            <div [ngClass]="isLookupSuccessful ? 'lookup-success' : 'lookup-no-success'"><span class="lookup-text">
              {{ (isLookupSuccessful ? 'dialog-message-modal-move-recovery-application-found' : 'dialog-message-modal-move-recovery-application-not-found') | translate }}</span></div>
          </div>
        </div>
        <input
          type="text"
          class="input-component"
          inputPattern [inputTypePattern]="InputTypePattern.digitsOnly"
          [placeholder]="'dialog-move-recovery-payment-text' | translate"
          [(ngModel)]="recoveryApplicationNumber"
          (ngModelChange)="recoveryApplicationNumberChanged($event)"
        />

        <div *ngIf="!!caseBehindRecoveryApplication" class="lookup-case-info">
          <div class="lookup-info">
            <div class="lookup-label">{{'task-message-modal-move-casetype' | translate }}</div>{{caseBehindRecoveryApplication?.caseTypeTranslated}}
          </div>

          <div class="lookup-info">
            <div *ngIf="!!caseBehindRecoveryApplication?.caseNumber" class="lookup-label">{{ 'dialog-move-recovery-payment-case-number' | translate }}</div>{{ caseBehindRecoveryApplication?.caseNumber }}
          </div>
        </div>

        <div class="button-container-row">
          <button class="modal-button" [class.modal-button-busy]="isMoveInProgress || !isAbleToMove" (click)="moveButtonClicked()" [disabled]="isMoveInProgress || !isAbleToMove">
            {{ 'case-document-table-move-document-move' | translate }}
            <img src="/assets/icons/waitForSearchresult.gif" class="spinner" [class.hide-spinner]="!isMoveInProgress" />
          </button>
        </div>
      </div>
      </div>

  </mat-dialog-content>
</div>
