import { Injectable } from '@angular/core';
import { TaskConsentDialogData } from '@secca/board/components/board-task/board-task-table/task-consent-dialog/task-consent-dialog-data.component';
import { TaskConsentDialogComponent } from '@secca/board/components/board-task/board-task-table/task-consent-dialog/task-consent-dialog.component';
import { TaskMessageDialogData } from '@secca/board/components/board-task/board-task-table/task-message-dialog/task-message-dialog-data.component';
import { TaskMessageDialogComponent } from '@secca/board/components/board-task/board-task-table/task-message-dialog/task-message-dialog.component';
import { TaskViewModel } from '@secca/board/components/board-task/models/task-view.model';
import { DialogHelperUtilService } from './dialog-helper-util.service';
import { TaskWebDialogData } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-dialog/task-web-dialog-data.component';
import { TaskWebDialogComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-dialog/task-web-dialog.component';
import {MatLegacyDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import { TaskDialogData } from '@secca/case/components/case-task/task-dialog/task-dialog-data.component';
import { TaskDialogComponent } from '@secca/case/components/case-task/task-dialog/task-dialog.component';
import { TaskModalModeEnum } from '@secca/case/components/case-task/task-modal-static/task-modal-static-mode.enum';
import { CaseNewDialogData } from '@secca/case/components/case-new/case-new-dialog/case-new-dialog-data.component';
import { CaseNewDialogComponent } from '@secca/case/components/case-new/case-new-dialog/case-new-dialog.component';
import { SearchCase } from '@secca/shared/models/searchCase';
import { OutputManagementDialogData } from '@secca/case/components/case-output-management/output-management-dialog/output-management-dialog-data.component';
import { OutputManagementDialogComponent } from '@secca/case/components/case-output-management/output-management-dialog/output-management-dialog.component';
import { Case } from '@secca/shared/models/case';
import { DigitalConsent } from '@secca/shared/models/digital-consent';
import { MessageChannelType } from '@secca/shared/models/enums';
import { ServiceOrderMessageRequest } from '@secca/shared/models/service-order/service-order-message-request';
import { KeyboardShortcutsDialogComponent } from '@secca/shared/components/keyboard-shortcuts-dialog/keyboard-shortcuts-dialog.component';
import { RecentCasesDialogComponent } from '@secca/shared/components/recent-cases-dialog/recent-cases-dialog.component';
import { TaskMessageMoveDialogComponent } from '@secca/board/components/board-task/board-task-table/task-message-move-dialog/task-message-move-dialog.component';
import { TaskMessageMoveDialogData } from '@secca/board/components/board-task/board-task-table/task-message-move-dialog/task-message-move-dialog-data.component';
import { CaseType } from '@secca/shared/models/case-type';
import { Channel } from '@secca/shared/models/channel';

@Injectable({
  providedIn: 'root',
})
export class DialogViewerService {
  constructor(private dialogHelperUtilService: DialogHelperUtilService) {}

  openTaskExternalDialog(taskViewModel: TaskViewModel, taskStatusDisabled?: boolean, hideSearchCreateButton?: boolean, createCaseOnly?: boolean, hasBackDrop?: boolean, navigatedToCaseEvent?: any): MatDialogRef<any> {
    const data: TaskWebDialogData = {
      taskViewModel,
      navigatedToCaseEvent,
      taskStatusDisabled,
      hideSearchCreateButton,
      createCaseOnly,
      isMinimizeIconHidden: hasBackDrop,
      disableShortcuts: hasBackDrop
    };

    const dialogConfig = {
      id: taskViewModel && taskViewModel.id ? `Task-${taskViewModel.id}` : undefined,
      width: '1558px',
      hasBackdrop: hasBackDrop,
      data
    };

    return this.dialogHelperUtilService.open(TaskWebDialogComponent, dialogConfig);
  }

  openTaskDialog(taskViewModel?: TaskViewModel, taskModalMode?: TaskModalModeEnum, autoFocus: boolean = true): MatDialogRef<any> {
    const data: TaskDialogData = {
      taskViewModel,
      taskModalMode: taskModalMode ? taskModalMode : TaskModalModeEnum.addNew,
    };

    const dialogConfig = {
      id: taskViewModel && taskViewModel.id ? `Task-${taskViewModel.id}` : undefined,
      width: '1150px',
      data,
      autoFocus: autoFocus
    };

    return this.dialogHelperUtilService.open(TaskDialogComponent, dialogConfig);
  }

  openTaskMessageDialog(taskViewModel: TaskViewModel, invokedFromTheCase?: boolean, supplierInvoiceMoved?: any, taskUpdated?: any): MatDialogRef<any> {
    const data: TaskMessageDialogData = {
      taskViewModel,
      invokedFromTheCase,
      supplierInvoiceMoved,
      taskUpdated,
    };

    const dialogConfig = {
      id: taskViewModel && taskViewModel.id ? `Task-${taskViewModel.id}` : undefined,
      width: '1558px',
      data
    };

    return this.dialogHelperUtilService.open(TaskMessageDialogComponent, dialogConfig);
  }

  openTaskConsentDialog(taskViewModel?: TaskViewModel, invokedFromTheCase?: boolean, taskUpdated?: any): MatDialogRef<any> {
    const data: TaskConsentDialogData = {
      taskViewModel,
      invokedFromTheCase,
      taskUpdated,
    };

    const dialogConfig = {
      id: taskViewModel && taskViewModel.id ? `Task-${taskViewModel.id}` : undefined,
      width: '1558px',
      data
    };

    return this.dialogHelperUtilService.open(TaskConsentDialogComponent, dialogConfig);
  }

  openTaskMessageMoveDialog(showServiceOrder?: boolean, isCaseNumberValid?: boolean, taskCaseId?: string, caseNumberEnteredEvent?: any, moveConfirmedEvent?: any): MatDialogRef<any> {
    const data: TaskMessageMoveDialogData = {
      showServiceOrder,
      isCaseNumberValid,
      taskCaseId,
      caseNumberEnteredEvent,
      moveConfirmedEvent,
      disableShortcuts: true
    };

    const dialogConfig = {
      width: '500px',
      data
    };

    return this.dialogHelperUtilService.openModal(TaskMessageMoveDialogComponent, dialogConfig);
  }

  openOutputManagementDialog(seccaCase: Case, messageChannelType: MessageChannelType,
    messageRequest: ServiceOrderMessageRequest,
    consent: DigitalConsent, messageSentEvent?: any, hasBackDrop: boolean = false): MatDialogRef<any> {
    const data: OutputManagementDialogData = {
      seccaCase,
      messageChannelType,
      messageRequest,
      consent,
      messageSentEvent,
      isMinimizeIconHidden: hasBackDrop,
      disableShortcuts: hasBackDrop
    };

    const dialogConfig = {
      width: '1150px',
      hasBackdrop: hasBackDrop,
      data
    };

    return this.dialogHelperUtilService.open(OutputManagementDialogComponent, dialogConfig);
  }

  openSearchCaseDialog(searchCase?: SearchCase): MatDialogRef<any> {
    const data: CaseNewDialogData = {
      searchCase,
      minimizeOnSelectCase: true
    };

    const dialogConfig = {
      id: 'SearchCase',
      width: '1810px',
      data
    };

    return this.dialogHelperUtilService.open(CaseNewDialogComponent, dialogConfig);
  }

  openClaimsSearchCaseDialog(searchCase?: SearchCase, taskViewModel?: TaskViewModel, navigatedToCaseEvent?: any): MatDialogRef<any> {
    const data: CaseNewDialogData = {
      searchCase,
      taskViewModel,
      navigatedToCaseEvent,
      showUpdateExistingCaseButton: true,
      isExternalTaskSearch: true,
      isMinimizeIconHidden: true,
      disableShortcuts: true,
    };

    const dialogConfig = {
      id: 'ClaimsSearchCase',
      width: '1810px',
      hasBackdrop: true,
      data,
    };

    return this.dialogHelperUtilService.open(CaseNewDialogComponent, dialogConfig);
  }

  openUnrelatedTaskSearchCaseDialog(searchCase?: SearchCase, taskViewModel?: TaskViewModel, navigatedToCaseEvent?: any): MatDialogRef<any> {
    const data: CaseNewDialogData = {
      searchCase,
      taskViewModel: taskViewModel,
      navigatedToCaseEvent,
      showUpdateExistingCaseButton: true,
      isUnrelatedTaskSearch: true,
      isMinimizeIconHidden: true,
      disableShortcuts: true,
    };

    const dialogConfig = {
      id: 'UnrelatedTaskSearchCase',
      width: '1810px',
      hasBackdrop: true,
      data,
    };

    return this.dialogHelperUtilService.open(CaseNewDialogComponent, dialogConfig);
  }

  openKeyboardShortcutsDialogComponent(): MatDialogRef<any> {
    const dialogConfig = {
      id: 'KeyboardShortcuts',
      width: '1500px',
    };

    return this.dialogHelperUtilService.open(KeyboardShortcutsDialogComponent, dialogConfig);
  }

  openRecentCasesDialogComponent(): MatDialogRef<any> {
    const dialogConfig = {
      id: 'OpenRecentCases',
      width: '1300px',
    };

    return this.dialogHelperUtilService.open(RecentCasesDialogComponent, dialogConfig);
  }

  openModalDialog(component: any, config: MatLegacyDialogConfig<any>): MatDialogRef<any> {
    return this.dialogHelperUtilService.openModal(component, config);
  }
}
