<div class="modal-content" xmlns:border-bottom="http://www.w3.org/1999/xhtml">
  <ng-container *ngIf="configuration">
    <div class="modal-header justify-content-between">
      <h2>
        {{ configuration.header | translate }}
      </h2>
      <div class="modal-close">
        <img class="cross-close" src="/assets/icons/Close White.svg" (click)="close(false)">
      </div>
    </div>
    <div class="body-1 modal-body" *ngIf="configuration.isBody">
      <div class="lane">
        <div class="lane text font-weight-bold">
          {{ configuration.title | translate }}
        </div>
        <div class="lane text">
          {{ configuration.text | translate }}
        </div>
      </div>
      <div class="aline"></div>
    </div>
    <div class="modal-body" *ngIf="configuration.isBody">
      <div class="lane text" *ngIf=configuration.isError>
        <strong>Error Code: </strong> <span>{{ configuration.errorCode  }} </span>
      </div>
      <div class="lane text " *ngIf='configuration.isId'>
        <strong>ID: </strong> <span>{{ configuration.id  }}</span>
      </div>
      <div class="lane text" *ngIf='configuration.isUrl'>
        <strong>Url: </strong> <span>{{ configuration.url  }}</span>
      </div>
      <div class="lane text ">
        <strong>Date and time: </strong> <span>{{ configuration.dateAndTime |  date:'short' }} </span>
      </div>
    </div>
    <div class="modal-footer" *ngIf="configuration.isFooter">
      <div class="lane w-100">
        <div class="text-footer float-start">
          {{ configuration.footer | translate }}
        </div>
        <div class="float-end">
          <button class="ok" (click)="close(false)">
            {{ configuration.ok | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
