import {Component, Inject, Input} from '@angular/core';
import {AutoUnsubscribe} from '@secca/shared/decorators/auto-unsubscribe';
import {IKeyboardEnabled} from "@secca/shared/interfaces/keyboard-enabled";
import {DialogBoundryService} from "@secca/core/services/dialog-boundry.service";
import {DialogHelperUtilService} from "@secca/core/services/dialog-helper-util.service";
import {ShortcutService} from "@secca/core/services/shortcut.service";
import {DialogKeyboardEnabledDirective} from "@secca/shared/directives/dialog-keyboard-enabled.directive";
import {asapScheduler, Observable, of, scheduled, Subject, Subscription, switchMap} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {InputTypePattern} from "@secca/shared/models/enums";
import {RecoveryService} from "@secca/core/services/recovery.service";
import {Recovery} from "@secca/shared/models/recovery";
import {HttpErrorResponse} from "@angular/common/http";
import {CaseService} from "@secca/core/services/case.service";
import {Case} from "@secca/shared/models/case";
import {TaskService} from "@secca/core/services/task.service";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import {Task} from "@secca/shared/models/task";
import {TaskMessageModalMovePaymentData} from "./task-message-modal-move-payment-data";

@Component({
  selector: 'app-task-message-modal-move-payment',
  templateUrl: './task-message-modal-move-payment.component.html',
  styleUrls: ['./task-message-modal-move-payment.component.scss'],
})
@AutoUnsubscribe
export class TaskMessageModalMovePaymentComponent implements IKeyboardEnabled {
  @Input() recoveryApplicationId: number;

  data: TaskMessageModalMovePaymentData;
  recoveryApplicationNumber: string;
  isMoveInProgress: boolean = false;
  isAbleToMove: boolean = false;
  shouldShowLookup: boolean = false;
  isLookupSuccessful: boolean = true;
  caseBehindRecoveryApplication: Case | null = null;
  private lastValidRecoveryApplicationNumber: string = undefined;
  private allNonNumericExpression: RegExp = /[^0-9]/g;
  private recoveryApplicationNumberChangeEvent = new Subject();
  private $inputSubscription: Subscription;


  constructor(private shortcutService: ShortcutService,
              private dialogHelperUtilService: DialogHelperUtilService,
              private recoveryService: RecoveryService,
              private caseService: CaseService,
              private taskService: TaskService,
              public dialogBoundaryService: DialogBoundryService,
              @Inject(MAT_DIALOG_DATA) data: TaskMessageModalMovePaymentData) {
    this.data = data;
    this.shortcutService.enableShortCuts();

    this.$inputSubscription = scheduled(this.recoveryApplicationNumberChangeEvent, asapScheduler).pipe(
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe((recoveryNumberString: string) => {
      const validRecoveryApplicationNumber = recoveryNumberString?.replace(this.allNonNumericExpression, '');

      if (validRecoveryApplicationNumber !== '' && validRecoveryApplicationNumber !== this.lastValidRecoveryApplicationNumber) {
        this.lastValidRecoveryApplicationNumber = validRecoveryApplicationNumber;
        const recoveryNumber = parseInt(recoveryNumberString, 10);

        if (isNaN(recoveryNumber) === false) {
          const application: Observable<Recovery> = recoveryService.getRecoveryById(recoveryNumber);

          application.subscribe({
            next: (theApplication: Recovery) => {
              this.shouldShowLookup = true;
              const theCase = caseService.getCase(theApplication.caseId.toString());

              theCase.subscribe({
                next: (aCase: Case) => {
                  if (aCase?.caseNumber !== null) {
                    this.isLookupSuccessful = true;
                    this.caseBehindRecoveryApplication = aCase;
                    this.isAbleToMove = true;
                  } else {
                    this.isLookupSuccessful = false;
                    this.isAbleToMove = false;
                  }
                }
                ,error: (anError: HttpErrorResponse) => {
                  this.caseBehindRecoveryApplication = null;
                  this.isLookupSuccessful = false;
                  this.isAbleToMove = false;
                }
              })
            }
            ,error: (anError: HttpErrorResponse) => {
              this.isLookupSuccessful = false;
              this.shouldShowLookup = true;
              this.isAbleToMove = false;
              this.caseBehindRecoveryApplication = null;
            }
          })
        }
      } else {
        if (validRecoveryApplicationNumber === '' ) {
          this.shouldShowLookup = false;
          this.caseBehindRecoveryApplication = null;
        }
      }
    });
  }

  recoveryApplicationNumberChanged(event: string) {
    this.recoveryApplicationNumberChangeEvent.next(event);
  }

  moveButtonClicked() {
    this.isMoveInProgress = true;
    this.taskService.moveUnhandledRecoveryPaymentToRecoveryApplication(this.data.originTaskId, +this.recoveryApplicationNumber)
      .subscribe({
        next: (task: Task) => {
          this.close('recoveryPaymentMoved');
        },
        error: (response: HttpErrorResponse) => {
          this.close(this.recoveryApplicationNumber);
        }
      });
  }

  close(value: string | number | undefined): void {
    this.isMoveInProgress = false;
    this.$inputSubscription.unsubscribe();
    this.dialogHelperUtilService.closeModalWithReturnValue(value);
  }

  keyboardClose(): void {
    this.dialogHelperUtilService.closeModal();
  }

  get enabledKeys(): string[] {
    return [DialogKeyboardEnabledDirective.ESCAPE_KEY];
  }

  protected readonly InputTypePattern = InputTypePattern;
}
