<div class="board-content">
  <ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="active" (navChange)="onTabChange($event.nextId)" #ngbNavInstance="ngbNav">
    <li [ngbNavItem]="TabsEnum.TO_DO" [id]=" TabsEnum.TO_DO ">
      <a ngbNavLink>
        <div class="board-content-tab-header">T<span class="shortcut-key">o</span> do {{getTaskTabCount(TabsEnum.TO_DO)}}</div>
      </a>

      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="px-2 mb-2">
            <div class="d-flex">
              <div *permission="PermissionEnum.TASK_UPDATE">
                <button class="reassign-button" (click)="reassignTasks()"
                  [ngClass]="numberOfReassignedTasksToDo === 0 && 'reassign-button-zero-check'">
                  <span class="shortcut-key">R</span>eassign ({{ numberOfReassignedTasksToDo }})
                </button>
              </div>
              <button class="icon-button" (click)="refreshToDoTasksClick()">
                <img src="../../../../assets/icons/refresh.svg" />
              </button>
            </div>
          </div>
        </div>
        <div>
          <div class="top-panel">
            <div class="d-flex">
              <div class="px-2">
                <div class="calendar">
                  <app-input-date-time #todoFromDate name="{{ 'board-task-from' | translate }}"
                    [placeholder]="dateTimePlaceholder" [(date)]="toDoTaskFilterRequest.fromDate" [withImage]="true"
                    [timeZone]="'local'" (dateChange)="loadTaskViewModels(TaskStatus.toDo, toDoTaskFilterRequest, true)">
                  </app-input-date-time>
                </div>
              </div>
              <div class="px-2">
                <div class="calendar">
                  <app-input-date-time name="{{ 'board-task-to' | translate }}" [placeholder]="dateTimePlaceholder"
                    [(date)]="toDoTaskFilterRequest.toDate" [withImage]="true" [timeZone]="'local'"
                    (dateChange)="loadTaskViewModels(TaskStatus.toDo, toDoTaskFilterRequest, true)"></app-input-date-time>
                </div>
              </div>
              <div class="px-2">
                <div class="task-type">
                  <div class="common-drop-down-arrow drop-down">
                    <app-drop-down [name]="'board-task-type' | translate"
                      [placeholder]="'board-task-placeholder-all' | translate" [items]="taskTypes"
                      [(selectedItemId)]="toDoTaskFilterRequest.taskTypes" [multiple]="true" [multiSelectedLabel]="
                        'board-task-filter-some-selected' | translate: { number: toDoTaskFilterRequest.taskTypes?.length }
                      " (selectedItemIdChange)="loadTaskViewModels(TaskStatus.toDo, toDoTaskFilterRequest, true)">
                    </app-drop-down>
                  </div>
                </div>
              </div>
              <div class="px-2">
                <div class="task-type">
                  <div class="common-drop-down-arrow drop-down">
                    <app-drop-down [name]="'board-task-incident-covered-status' | translate"
                      [placeholder]="'board-task-placeholder-all' | translate" [items]="taskIncidentCoveredStatus"
                      [(selectedItemId)]="toDoTaskFilterRequest.incidentCoveredStates" [multiple]="true"
                      [multiSelectedLabel]="
                        'board-task-filter-some-selected' | translate: { number: toDoTaskFilterRequest.incidentCoveredStates?.length }
                      " (selectedItemIdChange)="loadTaskViewModels(TaskStatus.toDo, toDoTaskFilterRequest, true)">
                    </app-drop-down>
                  </div>
                </div>
              </div>
              <div class="px-2">
                <span class="input-name">Cause</span>
                <div class="cause-container">
                  <div class="cause-dropdown">
                    <app-drop-down
                      [placeholder]="'board-task-placeholder-all' | translate"
                      #taskCaseCauseLevel1Component
                      [items]="taskCausesLevel1"
                      [(selectedItemId)]="toDoTaskFilterRequest.taskCauseLevel1Code"
                      [noBorderAndArrow]="true"
                      (focusin)="openTaskCauseLevel1Dropdown()"
                      (selectedItemIdChange)="taskCauseLevel1Changed()">
                    </app-drop-down>
                  </div>
                  <div class="cause-dropdown-separator">/</div>
                  <div class="cause-dropdown">
                    <app-drop-down
                      [placeholder]="'board-task-placeholder-all' | translate"
                      #taskCaseCauseLevel2Component
                      [items]="taskCausesLevel2"
                      [(selectedItemId)]="toDoTaskFilterRequest.taskCauseLevel2Code"
                      (focusin)="openTaskCauseLevel2Dropdown()"
                      [disabled]="!toDoTaskFilterRequest.taskCauseLevel1Code"
                      (selectedItemIdChange)="taskCauseLevel2Changed()"
                      [noBorderAndArrow]="true"
                      >
                    </app-drop-down>
                  </div>
                  <div class="cause-dropdown-separator">/</div>
                  <div class="cause-dropdown">
                    <app-drop-down
                      #taskCaseCauseLevel3Component
                      [placeholder]="'board-task-placeholder-all' | translate"
                      [items]="taskCausesLevel3"
                      [(selectedItemId)]="toDoTaskFilterRequest.taskCauseLevel3Code"
                      (focusin)="openTaskCauseLevel3Dropdown()"
                      [disabled]="taskCausesLevel3?.length === 0 || !toDoTaskFilterRequest.taskCauseLevel2Code || toDoTaskFilterRequest.taskCauseLevel2Code == ''"
                      [noBorderAndArrow]="true"
                      (selectedItemIdChange)="loadTaskViewModels(TaskStatus.toDo, toDoTaskFilterRequest, true)"
                      >
                    </app-drop-down>
                  </div>
                </div>
              </div>
              <div class="px-2 task-incident-event">
                <app-drop-down [name]="'board-task-incident-event' | translate"
                  [placeholder]="'board-task-placeholder-all' | translate"
                  [items]="incidentEventsDropdown"
                  [(selectedItemId)]="toDoTaskFilterRequest.eventIds"
                  [multiple]="true"
                  [multiSelectedLabel]="
                    'board-task-filter-some-selected' | translate: { number: toDoTaskFilterRequest.eventIds?.length }
                  " (selectedItemIdChange)="loadTaskViewModels(TaskStatus.toDo, toDoTaskFilterRequest, true)">
                </app-drop-down>
              </div>
              <div class="px-2">
                <div class="customer-profile">
                  <app-multiple-select-customer-profile
                  [name]="'board-task-table-customer-profile-multible' | translate"
                  [maxElements]="10"
                  [selectedItemsCustomerProfiles]="toDoTaskFilterRequest.customerProfileIds"
                  (selectedItemsChange)="updateToDoTaskCustomerProfile($event)"
                  #multipleSelectCustomerProfileComponent
                ></app-multiple-select-customer-profile>
                </div>
              </div>
              <div class="px-2">
                <button class="icon-button" (click)="clearToDoTaskFilter(true)">
                  <img src="../../../../assets/icons/clear-filter.svg" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <app-board-task-table [taskSorting]="toDoTaskSorting" (sortingAction)="sortingActionToDo($event)"
          [taskStatus]="TaskStatus.toDo" [taskViewModels]="toDoTaskFilterResponse?.taskViewModels"
          (updateTaskListAfterEdit)="updateTaskListAfterEdit()">
        </app-board-task-table>
        <app-pagination [currentPageIndex]="toDoTaskFilterRequest?.pageIndex"
          [totalNumber]="toDoTaskFilterResponse?.totalNumber" [pageSize]="toDoTaskFilterRequest?.pageSize"
          (pagingChangedEvent)="toDoPaginationChanged($event)">
        </app-pagination>
      </ng-template>
    </li>

    <li [ngbNavItem]="TabsEnum.INBOX" [id]=" TabsEnum.INBOX ">
      <a ngbNavLink>
        <div class="board-content-tab-header">{{ 'board-task-table-inbox-title' | translate }} {{getTaskTabCount(TabsEnum.INBOX)}}</div>
      </a>
      <ng-template ngbNavContent>
        <div>
          <div class="top-panel">
            <div class="d-flex">
              <div class="ms-auto px-2">
                <div class="reassign">
                  <div class="d-flex">
                    <div *permission="PermissionEnum.TASK_UPDATE">
                      <button class="reassign-button" (click)="reassignInboxTasks()"
                        [ngClass]="numberOfReassignTasksInbox === 0 && 'reassign-button-zero-check'">
                        <span class="shortcut-key">R</span>eassign ({{ numberOfReassignTasksInbox }})
                      </button>
                    </div>
                    <button class="icon-button" (click)="refreshInboxTasksClick()">
                      <img src="../../../../assets/icons/refresh.svg" />
                    </button>
                  </div>
                </div>
                <div class="nip-checkbox custom-checkbox">
                  <label class="checkbox">
                    <span class="nip-checkbox-label">{{ 'include-cases-with-no-incident-date' | translate }}</span>
                    <input type="checkbox"
                    [(ngModel)]="inboxTaskFilterRequest.noIncidentDate"
                    (change)="loadInboxTasks(false)"
                    [disabled]="!inboxTaskFilterRequest.incidentDatefrom && !inboxTaskFilterRequest.incidentDateTo"  />
                    <span class="checkmark nip-checkbox-checkmark"></span>
                  </label>
                </div>
              </div>
              <div class=" px-2">
                <div class="calendar">
                  <app-input-calendar
                    [withImage]="true"
                    [placeholder]="datePlaceholder"
                    [name]="'board-task-inbox-incident-start' | translate"
                    (dateChange)="loadInboxTasks(false)"
                    [(date)]="inboxTaskFilterRequest.incidentDatefrom"
                ></app-input-calendar>
                </div>
              </div>
              <div class="px-2">
                <div class="calendar">
                  <app-input-calendar
                    [withImage]="true"
                    [placeholder]="datePlaceholder"
                    [name]="'board-task-inbox-incident-end' | translate"
                    (dateChange)="loadInboxTasks(false)"
                    [(date)]="inboxTaskFilterRequest.incidentDateTo"
                ></app-input-calendar>
                </div>
              </div>
              <div class="px-2">
                <div class="task-type-inbox">
                  <div class="common-drop-down-arrow drop-down">
                    <app-drop-down
                      [name]="'board-task-type' | translate"
                      [placeholder]="'board-task-placeholder-all' | translate"
                      [items]="taskInboxTypes"
                      [(selectedItemId)]="inboxTaskFilterRequest.taskTypes"
                      [multiple]="true" [multiSelectedLabel]="'board-task-filter-some-selected' | translate: { number: inboxTaskFilterRequest.taskTypes?.length }"
                      (selectedItemIdChange)="loadInboxTasks(false)">
                    </app-drop-down>
                  </div>
                </div>
              </div>
              <div class="px-2">
                <div class="task-type-inbox">
                  <div class="common-drop-down-arrow drop-down">
                    <app-drop-down [name]="'board-task-related-status' | translate"
                      [placeholder]="'board-task-placeholder-all' | translate"
                      [items]="relatedCase"
                      [(selectedItemId)]="inboxTaskFilterRequest.relatedCase"
                      [multiSelectedLabel]="
                        'board-task-filter-some-selected' | translate: { number: inboxTaskFilterRequest.relatedCase?.length }
                      "(selectedItemIdChange)="loadInboxTasks(false)">
                    </app-drop-down>
                  </div>
                </div>
              </div>
              <div class="px-2">
                <span class="input-name">Cause</span>
                <div class="cause-container">
                  <div class="cause-dropdown">
                    <app-drop-down
                      [placeholder]="'board-task-placeholder-all' | translate"
                      #inboxTaskCaseCauseLevel1Component
                      [items]="inboxTaskCausesLevel1"
                      [(selectedItemId)]="inboxTaskFilterRequest.taskCauseLevel1Code"
                      [noBorderAndArrow]="true"
                      (focusin)="openInboxTaskCauseLevel1Dropdown()"
                      (selectedItemIdChange)="inboxTaskCauseLevel1Changed()">
                    </app-drop-down>
                  </div>
                  <div class="cause-dropdown-separator">/</div>
                  <div class="cause-dropdown">
                    <app-drop-down
                      [placeholder]="'board-task-placeholder-all' | translate"
                      #inboxTaskCaseCauseLevel2Component
                      [items]="inboxTaskCausesLevel2"
                      [(selectedItemId)]="inboxTaskFilterRequest.taskCauseLevel2Code"
                      (focusin)="openInboxTaskCauseLevel2Dropdown()"
                      [disabled]="!inboxTaskFilterRequest.taskCauseLevel1Code"
                      (selectedItemIdChange)="inboxTaskCauseLevel2Changed()"
                      [noBorderAndArrow]="true"
                      >
                    </app-drop-down>
                  </div>
                  <div class="cause-dropdown-separator">/</div>
                  <div class="cause-dropdown">
                    <app-drop-down
                      #inboxTaskCaseCauseLevel3Component
                      [placeholder]="'board-task-placeholder-all' | translate"
                      [items]="inboxTaskCausesLevel3"
                      [(selectedItemId)]="inboxTaskFilterRequest.taskCauseLevel3Code"
                      (focusin)="openInboxTaskCauseLevel3Dropdown()"
                      [disabled]="inboxTaskCausesLevel3?.length === 0 || !inboxTaskFilterRequest.taskCauseLevel2Code || inboxTaskFilterRequest.taskCauseLevel2Code == ''"
                      [noBorderAndArrow]="true"
                      (selectedItemIdChange)="loadInboxTasks(false)"
                      >
                    </app-drop-down>
                  </div>
                </div>
              </div>
              <div class="px-2">
                <button class="icon-button" (click)="clearInboxTaskFilter(true)">
                  <img src="../../../../assets/icons/clear-filter.svg" />
                </button>
              </div>
            </div>
            <div class="d-flex">
              <div class="ms-auto px-2">
                <div class="calendar">
                  <app-input-date-time
                    #todoFromDate
                    name="{{ 'board-task-due-date-from' | translate }}"
                    [placeholder]="datePlaceholder"
                    [(date)]="inboxTaskFilterRequest.from"
                    [withImage]="true"
                    [timeZone]="'local'"
                    (dateChange)="loadInboxTasks(false)"
                  ></app-input-date-time>
                </div>
              </div>
              <div class="px-2">
                <div class="calendar">
                  <app-input-date-time
                    name="{{ 'board-task-due-date-to' | translate }}"
                    [placeholder]="datePlaceholder"
                    [(date)]="inboxTaskFilterRequest.to"
                    [withImage]="true"
                    [timeZone]="'local'"
                    (dateChange)="loadInboxTasks(false)"
                  ></app-input-date-time>
                </div>
              </div>
              <div class="px-2 task-incident-event">
                <app-drop-down [name]="'board-task-incident-event' | translate"
                  [placeholder]="'board-task-placeholder-all' | translate"
                  [items]="incidentEventsDropdown"
                  [(selectedItemId)]="inboxTaskFilterRequest.eventIds"
                  [multiple]="true"
                  [multiSelectedLabel]="
                    'board-task-filter-some-selected' | translate: { number: inboxTaskFilterRequest.eventIds?.length }
                  " (selectedItemIdChange)="loadInboxTasks(false)">
                </app-drop-down>
              </div>
              <div class="px-2">
                <div class="customer-profile">
                  <app-multiple-select-customer-profile
                  [name]="'board-task-table-customer-profile-multible' | translate"
                  [maxElements]="10"
                  [selectedItemsCustomerProfiles]="inboxTaskFilterRequest.customerProfileIds"
                  (selectedItemsChange)="updateInboxTaskCustomerProfile($event)"
                  #multipleSelectCustomerProfileComponent
                ></app-multiple-select-customer-profile>
                </div>
              </div>

              <div class="px-2 ac-status">
                <app-drop-down
                  [name]="'board-case-table-team-status' | translate"
                  [placeholder]="'board-case-placeholder-all' | translate"
                  [items]="status"
                  [(selectedItemId)]="inboxTaskFilterRequest.statuses"
                  [multiple]="true"
                  [multiSelectedLabel]="
                    'board-task-filter-some-selected' | translate: { number: inboxTaskFilterRequest.statuses.length }
                  "
                  (selectedItemIdChange)="loadInboxTasks(false)"
                ></app-drop-down>
              </div>

              <div class="px-2 claims-status">
                <app-drop-down
                  [name]="'board-case-table-claim-status' | translate"
                  [placeholder]="'board-case-placeholder-not-relevant' | translate"
                  [items]="claimsDropDownStatus"
                  [(selectedItemId)]="claimStatus"
                  [multiple]="true"
                  [multiSelectedLabel]="claimsTypeSelectText()"
                  (selectedItemIdChange)="claimsTypeSelectChange()"
                ></app-drop-down>
              </div>
            </div>
          </div>
        </div>
        <app-board-task-inbox-table
          [taskSorting]="inboxTaskSorting"
          (sortingAction)="sortingActionInbox($event)"
          [taskViewModels]="inboxTaskFilterResponse?.inboxTaskModal"
        >
        </app-board-task-inbox-table>
        <app-pagination [currentPageIndex]="inboxTaskFilterRequest?.pageIndex"
          [totalNumber]="inboxTaskFilterResponse?.totalNumber" [pageSize]="inboxTaskFilterRequest?.pageSize"
          (pagingChangedEvent)="inboxPaginationChanged($event)">
        </app-pagination>
      </ng-template>
    </li>

    <li [ngbNavItem]="TabsEnum.INCOMING_CASES" [id]="TabsEnum.INCOMING_CASES">
      <a ngbNavLink>
        <div class="board-content-tab-header">
          We<span class="shortcut-key">b</span>
          <ng-container>{{getTaskTabCount(TabsEnum.INCOMING_CASES)}}</ng-container>
        </div>
      </a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="d-flex">
            <div class=" px-2">
              <div class="d-flex">
                <div *permission="PermissionEnum.TASK_UPDATE">
                  <button class="reassign-button" (click)="reassignIncomingCasesTasks()"
                    [ngClass]="numberOfReassignedIncomingCasesTasks === 0 && 'reassign-button-zero-check'">
                    <span class="shortcut-key">R</span>eassign ({{ numberOfReassignedIncomingCasesTasks }})
                  </button>
                </div>
                <button class="icon-button" (click)="refreshIncomingCasesTasksClick()">
                  <img src="../../../../assets/icons/refresh.svg" />
                </button>
              </div>
            </div>
            <div class="ms-auto px-2">
              <div class="calendar">
                <app-input-date-time name="{{ 'board-task-from' | translate }}" [placeholder]="dateTimePlaceholder"
                  [(date)]="incomingCasesTaskFilterRequest.fromDate" [withImage]="true" [timeZone]="'local'"
                  (dateChange)="loadIncomingCasesTaskViewModels(incomingCasesTaskFilterRequest, true)">
                </app-input-date-time>
              </div>
            </div>
            <div class="px-2">
              <div class="calendar">
                <app-input-date-time name="{{ 'board-task-to' | translate }}" [placeholder]="dateTimePlaceholder"
                  [(date)]="incomingCasesTaskFilterRequest.toDate" [withImage]="true" [timeZone]="'local'"
                  (dateChange)="loadIncomingCasesTaskViewModels(incomingCasesTaskFilterRequest, true)">
                </app-input-date-time>
              </div>
            </div>
            <div class="px-2">
              <div class="customer-profile">
                <app-multiple-select-customer-profile
                [name]="'board-task-table-customer-profile-multible' | translate"
                [maxElements]="10"
                [selectedItemsCustomerProfiles]="incomingCasesTaskFilterRequest.customerProfileIds"
                (selectedItemsChange)="updateIncomingCasesTaskCustomerProfile($event)"
                #multipleSelectCustomerProfileComponent
              ></app-multiple-select-customer-profile>
              </div>
            </div>
            <div class="px-2">
              <div class="task-type">
                <div class="common-drop-down-arrow drop-down">
                  <app-drop-down
                    [name]="'board-task-channel' | translate"
                    [placeholder]="'board-task-placeholder-all' | translate"
                    [items]="taskChannels"
                    [(selectedItemId)]="selectedChannelType"
                    [multiple]="true"
                    [multiSelectedLabel]="'board-task-filter-some-selected' | translate: { number: this.selectedChannelType?.length }"
                    (selectedItemIdChange)="incomingCasesTaskChannelChanged($event)">
                </app-drop-down>
                </div>
              </div>
            </div>
            <div class="px-2">
              <button class="icon-button" (click)="clearIncomingTaskFilter(true)">
                <img src="../../../../assets/icons/clear-filter.svg" />
              </button>
            </div>
          </div>
        </div>
        <app-board-task-incoming-cases-table [taskSorting]="incomingCasesTaskSorting"
          (sortingAction)="sortingActionIncomingCases($event)"
          [taskViewModels]="incomingCasesTaskFilterResponse?.taskViewModels">
        </app-board-task-incoming-cases-table>
        <app-pagination [currentPageIndex]="incomingCasesTaskFilterRequest?.pageIndex"
          [totalNumber]="incomingCasesTaskFilterResponse?.totalNumber"
          [pageSize]="incomingCasesTaskFilterRequest?.pageSize"
          (pagingChangedEvent)="incomingCasesPaginationChanged($event)">
        </app-pagination>
      </ng-template>
    </li>
    <li [ngbNavItem]="TabsEnum.PRE_DEPARTURE" [id]="TabsEnum.PRE_DEPARTURE">
      <a ngbNavLink>
        <div class="board-content-tab-header"> Pre-departure {{getTaskTabCount(TabsEnum.PRE_DEPARTURE)}}</div>
      </a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="d-flex">
            <div class="filter-btn-col">
              <div class="align-bottom">
                <span>
                  <button class="reassign-button-tab3"
                    *permission="PermissionEnum.TASK_UPDATE; hideType: PermissionHideTypeEnum.DISABLE"
                    (click)="reassignPreDepartureTasks()"
                    [ngClass]="numberOfReassignTasksPreDeparture === 0 && 'reassign-button-tab3-zero-check'">
                    <span class="shortcut-key">R</span>eassign ({{ numberOfReassignTasksPreDeparture }})
                  </button>
                </span>
                <button class="icon-button " (click)="refreshPreDepartureTasksTabClick()">
                  <img src="../../../../assets/icons/refresh.svg" />
                </button>
              </div>
            </div>
            <div class="ms-auto px-2">
              <div class="calendar">
                <app-input-date-time #todoFromDate name="{{ 'board-task-received-date' | translate }}"
                  [placeholder]="dateTimePlaceholder" [(date)]="preDepartureTaskFilterRequest.createdOnFrom" [withImage]="true"
                  [timeZone]="'local'" (dateChange)="loadPreDepartureTasks()">
                </app-input-date-time>
              </div>
            </div>
            <div class="px-2">
              <div class="calendar">
                <app-input-date-time name=" " [placeholder]="dateTimePlaceholder"
                  [(date)]="preDepartureTaskFilterRequest.createdOnTo" [withImage]="true" [timeZone]="'local'"
                  (dateChange)="loadPreDepartureTasks()"></app-input-date-time>
              </div>
            </div>
            <div class="px-2">
              <div class="customer-profile">
                <app-multiple-select-customer-profile
                [name]="'board-task-table-customer-profile-multible' | translate"
                [maxElements]="10"
                [selectedItemsCustomerProfiles]="preDepartureTaskFilterRequest.customerProfileIds"
                (selectedItemsChange)="updatePreDepartureTaskCustomerProfile($event)"
                #multipleSelectCustomerProfileComponent
              ></app-multiple-select-customer-profile>
              </div>
            </div>
            <div class="px-2">
              <app-drop-down [name]="'board-task-pre-departure-status' | translate"
                [placeholder]="'board-task-placeholder-all' | translate" [items]="filterablePreAssessmentStatus"
                [(selectedItemId)]="preDepartureTaskFilterRequest.status" [multiple]="true" [multiSelectedLabel]="
                  'board-task-filter-some-selected' | translate: { number: preDepartureTaskFilterRequest.status?.length }
                " (selectedItemIdChange)="loadPreDepartureTasks()"></app-drop-down>
            </div>
            <div class="px-2">
              <button class="icon-button" (click)="clearPreDepartureTaskFilter(true)">
                <img src="../../../../assets/icons/clear-filter.svg" />
              </button>
            </div>
          </div>
        </div>
        <app-board-task-pre-departure-tabel
          [taskSorting]="preDepartureTaskSorting"
          (sortingAction)="sortingActionPreDepartureTask($event)"
          [taskViewModels]="preDepartureTaskFilterResponse?.taskViewModels">
        </app-board-task-pre-departure-tabel>
      <app-pagination [currentPageIndex]="preDepartureTaskFilterRequest?.pageIndex"
        [totalNumber]="preDepartureTaskFilterResponse?.totalNumber" [pageSize]="preDepartureTaskFilterRequest?.pageSize"
        (pagingChangedEvent)="preDepartureTaskPaginationChanged($event)">
      </app-pagination>
      </ng-template>
    </li>
    <li [ngbNavItem]="TabsEnum.REFUND" [id]="TabsEnum.REFUND"
      *permission="PermissionEnum.REFUND_READ; hideType: PermissionHideTypeEnum.REMOVE">
      <a ngbNavLink>
        <div class="board-content-tab-header">R<span class="shortcut-key">e</span>fund {{getTaskTabCount(TabsEnum.REFUND)}}</div>
      </a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="row align-items-end filter-row">
            <div class="col filter-btn-col">
              <div class="align-bottom">
                <span>
                  <button class="reassign-button-tab3"
                    *permission="PermissionEnum.REFUND_UPDATE; hideType: PermissionHideTypeEnum.DISABLE"
                    (click)="reassignRefundTasks()"
                    [ngClass]="numberOfReassignTasksRefund === 0 && 'reassign-button-tab3-zero-check'">
                    <span class="shortcut-key">R</span>eassign ({{ numberOfReassignTasksRefund }})
                  </button>
                </span>
                <button class="icon-button " (click)="refreshRefundTasksClick()">
                  <img src="../../../../assets/icons/refresh.svg" />
                </button>
              </div>
            </div>
            <div class="ms-auto col filter-col">
              <app-input [withImage]="true" [name]="'board-task-refund-case-number' | translate"
                [placeholder]="'board-task-placeholder-all' | translate" [(value)]="refundTaskFilterRequest.caseNumber"
                (saveModel)="loadRefundTasksOnDemand()"></app-input>
            </div>
            <div class="col filter-col">
              <app-input [withImage]="true" [name]="'board-task-refund-refund-id' | translate"
                [placeholder]="'board-task-placeholder-all' | translate" [(value)]="refundTaskFilterRequest.refundID"
                (saveModel)="searchByRefundId()"></app-input>
            </div>
            <div class="col filter-col dateRange">
              <app-input-calender [name]="'board-task-due-date' | translate" [withRange]="true" [withImage]="true"
                [placeholder]="datePlaceholder" [(dateTimeRange)]="refundTaskFilterRequest.dateRange"
                (dateTimeRangeChange)="updateInsuranceCustomerProfile()">
              </app-input-calender>
            </div>
            <div class="col filter-col customer-profile-multible">
                <app-multiple-select-customer-profile
                [name]="'board-task-table-customer-profile-multible' | translate"
                [maxElements]="10"
                [selectedItemsCustomerProfiles]="refundTaskFilterRequest.customerProfileIds"
                (selectedItemsChange)="updateInsuranceCustomerProfile($event)"
                #multipleSelectCustomerProfileComponent
              ></app-multiple-select-customer-profile>
            </div>
            <div class="col filter-col">
              <app-drop-down
                [name]="'board-task-refund-refund-cause' | translate"
                #caseCauseLevel1Component
                [items]="taskCausesLevel1"
                [(selectedItemId)]="refundTaskFilterRequest.causeLevel1Code"
                [multiple]="true"
                [multiSelectedLabel]="'board-task-filter-some-selected' | translate: { number: refundTaskFilterRequest.causeLevel1Code.length }"
                [placeholder]="'board-task-placeholder-all' | translate"
                (focusin)="openCauseLevel1Dropdown()"
                (selectedItemIdChange)="refundEventDropdownChanged()"
                >
              </app-drop-down>
            </div>
            <div class="col filter-col refund-status">
              <app-drop-down
                [name]="'board-task-refund-refund-status' | translate"
                [closeOnSelect]="false"
                [placeholder]="'board-task-placeholder-all' | translate"
                [items]="filterableRefundStatus"
                [(selectedItemId)]="refundTaskFilterRequest.refundStatus"
                [multiple]="true"
                [multiSelectedLabel]="'board-task-filter-some-selected' | translate: { number: refundTaskFilterRequest.refundStatus.length }"
                (saveModel)="loadRefundTasksOnDemand()"></app-drop-down>
            </div>
            <div class="col filter-col">
              <app-drop-down
                [name]="'board-task-winter-sport' | translate"
                [placeholder]="'board-task-placeholder-all' | translate"
                [items]="snowEventTasks"
                [(selectedItemId)]="refundTaskFilterRequest.winterSport"
                (selectedItemIdChange)="loadRefundTasksOnDemand() ">
              </app-drop-down>
            </div>
            <div class="col filter-col">
              <app-drop-down
                [name]="'board-task-incident-event' | translate"
                [placeholder]="'board-task-placeholder-all' | translate"
                [items]="incidentEventsDropdown"
                [(selectedItemId)]="refundTaskFilterRequest.incidentEvents"
                [multiple]="true"
                [multiSelectedLabel]="'board-task-filter-some-selected' | translate: { number: refundTaskFilterRequest.incidentEvents.length }"
                (selectedItemIdChange)="refundEventDropdownChanged()">
              </app-drop-down>
            </div>
            <div class="col filter-btn-clear" >
              <button class="icon-button" (click)="clearRefundTaskFilter(true)">
                <img src="../../../../assets/icons/clear-filter.svg" />
              </button>
            </div>
          </div>
        </div>
        <app-board-task-refund-table [taskSorting]="refundTaskSorting"
          [taskViewModels]="refundTaskFilterResponse?.taskViewModels" (sortingAction)="sortingActionRefund($event)"
          (refundMovedEvent)="refreshRefundTasksTabClick()"></app-board-task-refund-table>
        <app-pagination [currentPageIndex]="refundTaskFilterRequest?.pageIndex"
          [totalNumber]="refundTaskFilterResponse?.totalNumber" [pageSize]="refundTaskFilterRequest?.pageSize"
          (pagingChangedEvent)="refundPaginationChanged($event)">
        </app-pagination>
      </ng-template>
    </li>

    <li [ngbNavItem]="TabsEnum.SUPPLIER_INVOICE" id="{{ TabsEnum.SUPPLIER_INVOICE }}">
      <a ngbNavLink>
        <div class="board-content-tab-header">
          S<span class="shortcut-key">u</span>pplier Invoice {{ getTaskTabCount(TabsEnum.SUPPLIER_INVOICE)}}
        </div>
      </a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="row align-items-end filter-row">
            <div class="col filter-btn-col-small">
                <span *permission="PermissionEnum.TASK_UPDATE">
                  <button class="reassign-button-tab3" (click)="reassignSupplierInvoiceTasks()"
                    [ngClass]="numberOfReassignTasksSupplierInvoice === 0 && 'reassign-button-tab3-zero-check'">
                    <span class="shortcut-key">R</span>eassign ({{ numberOfReassignTasksSupplierInvoice }})
                  </button>
                </span>
            </div><div class="col filter-col tab3-calendar">
            <app-input-calender [name]="'board-task-due-date' | translate" [withRange]="true" [withImage]="true"
                                [placeholder]="datePlaceholder" [(dateTimeRange)]="supplierInvoiceTaskFilterRequest.dateRange"
                                (dateTimeRangeChange)="filteringChanged()">
            </app-input-calender>
          </div>
            <div class="col filter-col">
              <app-drop-down [name]="'board-task-invoice-group' | translate"
                             [placeholder]="'board-task-placeholder-all' | translate" [items]="filterableInvoiceGroups"
                             [(selectedItemId)]="supplierInvoiceTaskFilterRequest.invoiceGroups" [multiple]="true"
                             [multiSelectedLabel]="
                  'board-task-filter-some-selected' | translate: { number: supplierInvoiceTaskFilterRequest.invoiceGroups?.length }
                " (selectedItemIdChange)="filteringChanged()"></app-drop-down>
            </div>
            <div class="col filter-col">
              <app-input [withImage]="true" [name]="'board-task-invoice-case-number' | translate"
                         [placeholder]="'board-task-placeholder-all' | translate"
                         [(value)]="supplierInvoiceTaskFilterRequest.caseNumber"
                         (valueChange)="filteringChanged()"></app-input>
            </div>
            <div class="col filter-col">
              <app-drop-down [name]="'board-task-service-type' | translate"
                             [placeholder]="'board-task-placeholder-all' | translate" [items]="filterableServiceTypes"
                             [(selectedItemId)]="supplierInvoiceTaskFilterRequest.serviceTypes" [multiple]="true"
                             [multiSelectedLabel]="
                  'board-task-filter-some-selected' | translate: { number: supplierInvoiceTaskFilterRequest.serviceTypes?.length }
                " (selectedItemIdChange)="filteringChanged()"></app-drop-down>
            </div>
            <div class="col filter-col">
              <app-drop-down-creditors [name]="'board-task-invoice-creditor' | translate"
                                       [placeholder]="'board-task-placeholder-all' | translate"
                                       [(selectedItemId)]="supplierInvoiceTaskFilterRequest.creditorId" [items]="creditors"
                                       (selectedItemIdChange)="filteringChanged()"></app-drop-down-creditors>
            </div>
            <div class="col filter-col">
              <app-input [withImage]="true" [name]="'board-task-invoice-id' | translate"
                         [placeholder]="'board-task-placeholder-all' | translate"
                         [(value)]="supplierInvoiceTaskFilterRequest.invoiceNumber" (saveModel)="filterOnInvoiceId()">
              </app-input>
            </div>
            <div class="col filter-col">
              <app-drop-down [name]="'board-task-invoice-status' | translate"
                             [placeholder]="'board-task-placeholder-all' | translate" [items]="filterableInvoiceStatus"
                             [(selectedItemId)]="supplierInvoiceTaskFilterRequest.invoiceStatuses" [multiple]="true"
                             [closeOnSelect]="false"
                             [multiSelectedLabel]="
                  'board-task-filter-some-selected' | translate: { number: supplierInvoiceTaskFilterRequest.invoiceStatuses?.length }
                " (saveModel)="filteringChanged()"></app-drop-down>
            </div>

            <div class="col filter-btn-clear" >
              <button class="icon-button" (click)="clearSupplierInvoiceTaskFilter(true)">
                <img src="../../../../assets/icons/clear-filter.svg" />
              </button>
            </div>
            <div class="col filter-btn-search" >
              <button class="icon-button icon-button-last" (click)="refreshSupplierInvoiceTasksClick()" [class.icon-button-disabled]="!searchUpdated">
                <img *ngIf="searchUpdated" src="../../../../assets/icons/refresh.svg" />
                <img *ngIf="!searchUpdated" src="../../../../assets/icons/refresh-red.svg" />
              </button>
            </div>
          </div>
        </div>
        <app-board-task-supplier-invoice-table [taskSorting]="supplierInvoiceTaskSorting"
          (sortingAction)="sortingActionSupplierInvoiceCases($event)"
          [taskViewModels]="supplierInvoiceTaskFilterResponse?.taskViewModels"
          (supplierInvoiceMovedEvent)="refreshSupplierInvoiceTasksClick()" (taskUpdatedEvent)="taskUpdate()">
        </app-board-task-supplier-invoice-table>
        <app-pagination [currentPageIndex]="supplierInvoiceTaskFilterRequest?.pageIndex"
          [totalNumber]="supplierInvoiceTaskFilterResponse?.totalNumber"
          [pageSize]="supplierInvoiceTaskFilterRequest?.pageSize"
          (pagingChangedEvent)="supplierInvoicePaginationChanged($event)">
        </app-pagination>
      </ng-template>
    </li>
    <li [ngbNavItem]="TabsEnum.RECOVERY" [id]="TabsEnum.RECOVERY"
        *permission="PermissionEnum.RECOVERY_READ; hideType: PermissionHideTypeEnum.REMOVE">
      <a ngbNavLink>
        <div class="board-content-tab-header">Recovery Application {{getTaskTabCount(TabsEnum.RECOVERY)}}</div>
      </a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="row align-items-end filter-row">
            <div class="col filter-btn-col">
              <div class="align-bottom">
                <span>
                  <button class="reassign-button-tab3"
                    *permission="PermissionEnum.RECOVERY_UPDATE; hideType: PermissionHideTypeEnum.DISABLE"
                    (click)="reassignRecoveryTasks()"
                    [ngClass]="numberOfReassignTasksRecovery === 0 && 'reassign-button-tab3-zero-check'">
                    <span class="shortcut-key">R</span>eassign ({{ numberOfReassignTasksRecovery }})
                  </button>
                </span>
              </div>
            </div>
            <div class="col filter-col dateRange">
              <app-input-calender [name]="'board-task-due-date' | translate" [withRange]="true" [withImage]="true"
                [placeholder]="datePlaceholder" [(dateTimeRange)]="recoveryTaskFilterRequest.dateRange"
                (dateTimeRangeChange)="recoverySearchFilterUpdated()">
              </app-input-calender>
            </div>
            <div class="col filter-col dateRange">
              <app-input-calender [name]="'board-task-recovery-table-application-deadline' | translate" [withRange]="true" [withImage]="true"
                [placeholder]="datePlaceholder" [(dateTimeRange)]="recoveryTaskFilterRequest.applicationDeadlineRange"
                (dateTimeRangeChange)="recoverySearchFilterUpdated()">
              </app-input-calender>
            </div>
            <div class="col filter-col customer-profile-multible">
                <app-multiple-select-customer-profile
                [name]="'board-task-table-customer-profile-multible' | translate"
                [maxElements]="10"
                [selectedItemsCustomerProfiles]="recoveryTaskFilterRequest.customerProfileIds"
                (selectedItemsChange)="updateRecoveryTaskCustomerProfile($event)"
                #multipleSelectCustomerProfileComponent
              ></app-multiple-select-customer-profile>
            </div>
            <div class="col filter-col">
              <app-input [withImage]="true" [name]="'board-task-recovery-table-recovery-id' | translate"
                [placeholder]="'board-task-placeholder-all' | translate" [(value)]="recoveryTaskFilterRequest.recoveryID"
                (change)="searchByRecoveyId()"></app-input>
            </div>
            <div class="col filter-col recovery-status">
              <app-drop-down
                [name]="'board-task-recovery-table-recovery-status' | translate"
                [closeOnSelect]="false"
                [placeholder]="'board-task-placeholder-all' | translate"
                [items]="filterableRecoveryStatus"
                [(selectedItemId)]="recoveryTaskFilterRequest.recoveryStatus"
                [multiple]="true"
                [multiSelectedLabel]="'board-task-filter-some-selected' | translate: { number: recoveryTaskFilterRequest.recoveryStatus.length }"
                (saveModel)="recoverySearchFilterUpdated()"></app-drop-down>
            </div>
            <div class="col filter-btn-clear" >
              <button class="icon-button" (click)="clearRecoveryTaskFilter(true)">
                <img src="../../../../assets/icons/clear-filter.svg" />
              </button>
            </div>
            <div class="col filter-btn-search" >
              <button class="icon-button icon-button-last" (click)="refreshRecoveryTasksTabClick()" [class.icon-button-disabled]="isRecoverySearchFilterUpdated">
                <img *ngIf="!isRecoverySearchFilterUpdated" src="../../../../assets/icons/refresh.svg" />
                <img *ngIf="isRecoverySearchFilterUpdated" src="../../../../assets/icons/refresh-red.svg" />
              </button>
            </div>
          </div>
        </div>
        <app-board-task-recovery-table [taskSorting]="recoveryTaskSorting"
          [taskViewModels]="recoveryTaskFilterResponse?.taskViewModels" (sortingAction)="sortingActionRecovery($event)"
          (recoveryMovedEvent)="refreshRecoveryTasksTabClick()"></app-board-task-recovery-table>
        <app-pagination [currentPageIndex]="recoveryTaskFilterRequest?.pageIndex"
          [totalNumber]="recoveryTaskFilterResponse?.totalNumber" [pageSize]="recoveryTaskFilterRequest?.pageSize"
          (pagingChangedEvent)="recoveryPaginationChanged($event)">
        </app-pagination>
      </ng-template>
    </li>
    <li [ngbNavItem]="TabsEnum.RECOVERY_PAYMENT" id="{{ TabsEnum.RECOVERY_PAYMENT }}">
      <a ngbNavLink>
        <div class="board-content-tab-header">
          Recovery Payment {{ getTaskTabCount(TabsEnum.RECOVERY_PAYMENT)}}
        </div>
      </a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="row align-items-end filter-row">
            <div class="col filter-btn-col-small">
                <span *permission="PermissionEnum.TASK_UPDATE">
                  <button class="reassign-button-tab3" (click)="reassignRecoveryPaymentTasks()"
                          [ngClass]="numberOfReassignTasksRecoveryPayment === 0 && 'reassign-button-tab3-zero-check'">
                    <span class="shortcut-key">R</span>eassign ({{ numberOfReassignTasksRecoveryPayment }})
                  </button>
                </span>
            </div>
            <div class="col filter-col tab3-calendar">
              <app-input-calender [name]="'board-task-due-date' | translate" [withRange]="true" [withImage]="true"
                                  [placeholder]="datePlaceholder" [(dateTimeRange)]="recoveryPaymentTaskFilterRequest.dateRange"
                                  (dateTimeRangeChange)="filteringChanged()">
              </app-input-calender>
            </div>
            <div class="col filter-col">
              <app-input [withImage]="true" [name]="'board-task-recovery-payment-case-number' | translate"
                         [placeholder]="'board-task-placeholder-all' | translate"
                         [(value)]="recoveryPaymentTaskFilterRequest.caseNumber"
                         (valueChange)="filteringChanged()"></app-input>
            </div>
            <div class="col filter-col">
              <app-drop-down-creditors [name]="'board-task-recovery-payment-creditor' | translate"
                                       [placeholder]="'board-task-placeholder-all' | translate"
                                       [(selectedItemId)]="recoveryPaymentTaskFilterRequest.creditorId" [items]="creditors"
                                       (selectedItemIdChange)="filteringChanged()"></app-drop-down-creditors>
            </div>
            <div class="col filter-col">
              <app-input [withImage]="true" [name]="'board-task-recovery-payment-id' | translate"
                         [placeholder]="'board-task-placeholder-all' | translate"
                         [(value)]="recoveryPaymentTaskFilterRequest.invoiceNumber" (saveModel)="filterOnInvoiceId()">
              </app-input>
            </div>
            <div class="col filter-col">
              <app-drop-down [name]="'board-task-recovery-payment-status' | translate"
                             [placeholder]="'board-task-placeholder-all' | translate" [items]="filterableRecoveryPaymentStatus"
                             [(selectedItemId)]="recoveryPaymentTaskFilterRequest.statuses" [multiple]="true"
                             [closeOnSelect]="false"
                             [multiSelectedLabel]="
                  'board-task-filter-some-selected' | translate: { number: recoveryPaymentTaskFilterRequest.statuses?.length }
                " (saveModel)="filteringChanged()"></app-drop-down>
            </div>

            <div class="col filter-btn-clear" >
              <button class="icon-button" (click)="clearRecoveryPaymentInvoiceTaskFilter(true)">
                <img src="../../../../assets/icons/clear-filter.svg" />
              </button>
            </div>
            <div class="col filter-btn-search" >
              <button class="icon-button icon-button-last" (click)="refreshRecoveryPaymentTasksClick()" [class.icon-button-disabled]="!searchUpdated">
                <img *ngIf="searchUpdated" src="../../../../assets/icons/refresh.svg" />
                <img *ngIf="!searchUpdated" src="../../../../assets/icons/refresh-red.svg" />
              </button>
            </div>
          </div>
        </div>
        <app-board-task-recovery-payment-table [taskSorting]="recoveryPaymentTaskSorting"
                                               (sortingAction)="sortingActionRecoveryPayment($event)"
                                               [taskViewModels]="recoveryPaymentTaskFilterResponse?.taskViewModels"
                                               (recoveryPaymentMovedEvent)="refreshRecoveryPaymentTasksClick()" (taskUpdatedEvent)="taskUpdate()">
        </app-board-task-recovery-payment-table>
        <app-pagination [currentPageIndex]="recoveryPaymentTaskFilterRequest?.pageIndex"
                        [totalNumber]="recoveryPaymentTaskFilterResponse?.totalNumber"
                        [pageSize]="recoveryPaymentTaskFilterRequest?.pageSize"
                        (pagingChangedEvent)="recoveryPaymentPaginationChanged($event)">
        </app-pagination>
      </ng-template>
    </li>
    <li [ngbNavItem]="TabsEnum.DONE" id="{{ TabsEnum.DONE }}">
      <a ngbNavLink>
        <div class="board-content-tab-header">{{ 'board-task-done' | translate }}</div>
      </a>
      <ng-template ngbNavContent>
        <div class="top-panel">
          <div class="d-flex">
            <div class="px-2">
              <button class="icon-button" (click)="refreshDoneTasksClick()">
                <img src="../../../../assets/icons/refresh.svg" />
              </button>
            </div>
            <div class="ms-auto px-2">
              <div class="task-latest">
                <div class="common-drop-down-arrow drop-down">
                  <span class="name">{{ 'board-task-weeks' | translate }}</span>
                  <ng-select [items]="latestWeeks" class="custom" bindValue="id" bindLabel="name" [clearable]="false"
                             [searchable]="false" [(ngModel)]="doneTaskFilterRequest.weekAgo"
                             (change)="loadTaskViewModels(TaskStatus.done, doneTaskFilterRequest, true)"></ng-select>
                </div>
              </div>
            </div>
            <div class="px-2">
              <div class="customer-profile">
                <app-multiple-select-customer-profile
                  [name]="'board-task-table-customer-profile-multible' | translate"
                  [maxElements]="10"
                  [selectedItemsCustomerProfiles]="doneTaskFilterRequest.customerProfileIds"
                  (selectedItemsChange)="updateDoneTaskCustomerProfile($event)"
                  #multipleSelectCustomerProfileComponent
                ></app-multiple-select-customer-profile>
              </div>
            </div>
            <div class="px-2">
              <div class="task-type">
                <div class="common-drop-down-arrow drop-down">
                  <span class="name">{{ 'board-task-type' | translate }}</span>
                  <ng-select [items]="taskTypes" class="custom" bindValue="id" bindLabel="name" [clearable]="false"
                    [searchable]="false" [(ngModel)]="doneTaskFilterRequest.taskType"
                    (change)="loadTaskViewModels(TaskStatus.done, doneTaskFilterRequest, true)"></ng-select>
                </div>
              </div>
            </div>
            <div class="px-2">
              <button class="icon-button" (click)="clearDoneTaskFilter(true)">
                <img src="../../../../assets/icons/clear-filter.svg"/>
              </button>
            </div>
          </div>
        </div>
        <app-board-task-table [taskSorting]="doneTaskSorting" (sortingAction)="sortingActionDone($event)"
          [taskStatus]="TaskStatus.done" [taskViewModels]="doneTaskFilterResponse?.taskViewModels"
          (updateTaskListAfterEdit)="updateTaskListAfterEdit()">
        </app-board-task-table>
        <app-pagination
          [currentPageIndex]="doneTaskFilterRequest?.pageIndex"
          [totalNumber]="doneTaskFilterResponse?.totalNumber"
          [pageSize]="doneTaskFilterRequest?.pageSize"
          (pagingChangedEvent)="donePaginationChanged($event)">
        </app-pagination>
        <div class="pagination-container"></div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
<ng-template #modalContent let-modal>
  <app-task-reassign [tasksForReassignTaskViewModel]="tasksForReassign" (tasksReassigned)="postReassign()">
  </app-task-reassign>
</ng-template>
