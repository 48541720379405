
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment-timezone';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe';
import { CaseService } from '@secca/core/services/case.service';
import { CaseLocalTimeService } from './case-local-time.service';
import { CaseDialogViewerService } from '@secca/case/case-dialog-viewer.service';
import { DialogStateService } from '@secca/core/state-services/dialog-state.service';
import {
  CaseCountryInfoDialogInitData
} from '@secca/case/components/case-country-info/case-country-info-dialog/case-country-info-dialog-init-data';
import { Case } from '@secca/shared/models/case';

@Component({
  selector: 'app-case-local-time',
  templateUrl: './case-local-time.component.html',
  styleUrls: ['./case-local-time.component.scss']
})
@AutoUnsubscribe
export class CaseLocalTimeComponent implements OnInit {
  @Input() seccaCase: Case;
  dateVal: moment.Moment;
  country: string;
  countryCodeAlpha2: string;
  city: string;
  timeZoneId: string;
  timeDifference: number;
  timeDifferenceStr: string;
  subscription;
  subscription2;

  constructor(
    private caseService: CaseService,
    private localTimeService: CaseLocalTimeService,
    private dialogViewerService: CaseDialogViewerService,
    private dialogStateService: DialogStateService
  ) {
  }

  ngOnInit() {
    this.dateVal = moment.utc();
    this.subscription = this.caseService.dateTime.subscribe(now => {
      this.dateVal = now;
    });

    this.subscription2 = this.localTimeService.getLocalTimeZone().subscribe(localTimeZone => {
      if (localTimeZone) {
        this.city = localTimeZone.city;
        this.country = localTimeZone.country;
        this.countryCodeAlpha2 = localTimeZone.countryCodeAlpha2 || this.seccaCase?.incident?.currentCountryCodeAlpha2;
        this.timeZoneId = localTimeZone.timeZoneId;
      } else {
        this.city = null;
        this.country = null;
        this.countryCodeAlpha2 = null;
        this.timeZoneId = null;
      }
    });
  }

  openCountryInfo() {
    const countryBasics: CaseCountryInfoDialogInitData = {
      countryCode: this.countryCodeAlpha2,
      country: this.country,
      caseId: Number(this.seccaCase.id),
      caseNumber: this.seccaCase.caseNumber
    };
    const matDialogRef = this.dialogViewerService.openCountryInfo(countryBasics);
    if (matDialogRef) {
      setTimeout(() => {
        this.dialogStateService.sendToFront(matDialogRef.id);
      }, 0);
    }
  }

}
