import {HttpErrorResponse} from '@angular/common/http';

export class ErrorModalDialogConfiguration {
  header: string;
  title: string;
  text: string;
  footer: string;
  isFooter: boolean;
  isBody: boolean;
  ok: string;
  errorCode: string;
  id: string;
  url: string;
  dateAndTime: Date;
  errors: Error[] = [];
  httpErrors: HttpErrorResponse[] = [];
  contactSupport: string;
  isUrl: boolean = true;
  isId: boolean = true;
  isError: boolean = true;

  constructor(init?: Partial<ErrorModalDialogConfiguration>) {
    Object.assign(this, init);
  }
}
