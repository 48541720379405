import { TranslateInPipe } from './pipes/translateIn-pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '../utils/utils.module';
import { InputLocationComponent } from './components/input-location/input-location.component';
import { InputComponent } from './components/input/input.component';
import { DropDownWithHoverComponent } from './components/drop-down-with-hover/drop-down-with-hover.component';
import { DropDownStakeholderWithIconComponent } from './components/drop-down-stakeholder-with-icon/drop-down-stakeholder-with-icon.component';
import { DropDownUsersStaticComponent } from './components/drop-down-users-static/drop-down-users-static.component';
import { DropDownComponent } from './components/drop-down/drop-down.component';
import { SortDirective } from './directives/sort.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DropDownInputComponent } from './components/drop-down-input/drop-down-input.component';
import { TextBoxComponent } from './components/text-box/text-box.component';
import { InputCalenderComponent } from './components/input-calender/input-calender.component';
import { InputCalendarComponent } from './components/input-calendar/input-calendar.component';
import { InputTimeComponent } from './components/input-time/input-time.component';
import { DropDownAutoCompleteComponent } from './components/drop-down-auto-complete/drop-down-auto-complete.component';
import { NormalizePipe } from './pipes/normalize.pipe';
import { DropDownCountriesAutoComplete } from './components/drop-down-countries-auto-complete/drop-down-countries-auto-complete.component';
import { TimezoneOffset } from './pipes/TimezoneOffset.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { HtmlAttributeModifierPipe } from './pipes/html-attribute-modifier-pipe';
import { TextUppercasePipe } from './pipes/text-uppercase.pipe';
import { InputCreditCardComponent } from './components/input-credit-card/input-credit-card.component';
import { InputValutaComponent } from './components/input-valuta/input-valuta.component';
import { DropDownCreditorsComponent } from '@secca/board/components/board-task/drop-down-creditors/drop-down-creditors.component';
import { DropDownServiceOrderComponent } from './components/drop-down-service-order/drop-down-service-order.component';
import { DropDownWithCountryFlagComponent } from './components/drop-down-with-country-flag/drop-down-with-country-flag.component';
import { DropDownServiceOrderCoverageComponent } from './components/drop-down-service-order-coverage/drop-down-service-order-coverage.component';
import { MomentPipe } from './pipes/moment-pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { TimeDiffPipe } from './pipes/time-diff.pipe';
import { InputDateTimeComponent } from './components/input-date-time/input-date-time.component';
import { DropDownWithIconComponent } from './components/drop-down-with-icon/drop-down-with-icon.component';
import { PhoneDropDownComponent } from './components/phone-drop-down/phone-drop-down.component';
import { InputPatternDirective } from './directives/input-pattern.directive';
import { DropDownCustomerProfileComponent } from './components/drop-down-customer-profile/drop-down-customer-profile.component';
import { InputWithValueTypeComponent } from './components/input-with-value-type/input-with-value-type.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleLocationService } from '@secca/core/services/google-location.service';
import { LocationService } from '@secca/core/services/location.service';
import { PermissionDirective } from './directives/permission.directive';
import { DisableInputDirective } from './directives/disable-input.directive';
import { MultipleSelectComponent } from './components/multiple-select/multiple-select.component';
import { RichTextViewComponent } from './components/rich-text-view/rich-text-view.component';
import { CaseLockOverlayComponent } from './components/case-lock-overlay/case-lock-overlay.component';
import { DialogSelectableDirective } from './directives/dialog-selectable.directive';
import { DialogMinimizableDirective } from './directives/dialog-minimizable.directive';
import { AlertBoxComponent } from './components/alert-box/alert.box.component';
import { InputFocusDirective } from './directives/input-focus.directive';
import { TaskMessageAttachmentComponent } from '@secca/board/components/board-task/board-task-table/task-message-modal/task-message-attachment/task-message-attachment.component';
import { TaskConsentModalComponent } from '@secca/board/components/board-task/board-task-table/task-consent-modal/task-consent-modal.component';
import { TaskMessageLeftPanelComponent } from '@secca/board/components/board-task/board-task-table/task-message-left-panel/task-message-left-panel.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { IconChekboxRowComponent } from './components/icon-chekbox-row/icon-chekbox-row.component';
import { MultipleSelectDropdownComponent } from './components/multiple-select-dropdown/multiple-select-dropdown.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { SwitchMultiCasePipe } from './helpers/switch-multi-case.pipe';
import { UserPictureComponent } from './components/user-picture/user-picture.component';
import { ObjectTypeTranslatePipePipe } from './pipes/object-type-translator.pipe';
import { StakeholderIconComponent } from '@secca/case/components/case-new/stakeholder-icon/stakeholder-icon.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RichTextEditorComponent } from '@secca/case/components/case-output-management/components/message-dialog/rich-text-editor/rich-text-editor.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { TaskTextBoxComponent } from './components/task-text-box/task-text-box.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SubObjectTypeTranslatePipe } from './pipes/sub-object-type-translator.pipe';
import { TaskWebModalMainPanelComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-main-panel/task-web-modal-main-panel.component';
import { TaskModalStaticComponent } from '@secca/case/components/case-task/task-modal-static/task-modal-static.component';
import { TaskEditorComponent } from '@secca/case/components/case-task/task-editor/task-editor.component';
import { CaseHandlerSelectorComponent } from '@secca/case/components/case-task/case-handler-selector/case-handler-selector.component';
import { TaskReassignComponent } from '@secca/board/components/board-task/task-reassign/task-reassign.component';
import { SingleChoiceComponent } from '@secca/case/components/case-medical/medical-components/cehckbox-single-select/single-choice.component';
import { EmployeesComponent } from '@secca/case/components/case-basics/case-basics-info/case-basics-info-user/employees/employees.component';
import { TaskWebMedicalReportPanelComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-medical-report-panel/task-web-medical-report-panel.component';
import { OpenInvoicePdfComponent } from './components/open-invoice-pdf/open-invoice-pdf.component';
import { TaskWebPropertiesComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-properties/task-web-properties.component';
import { TaskDialogComponent } from '@secca/case/components/case-task/task-dialog/task-dialog.component';
import { CaseBasicsInsuranceProfileComponent } from '@secca/case/components/case-basics/case-basics-insurance/case-basics-insurance-profile/case-basics-insurance-profile.component';
import { TaskWebDetailsPanelComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-content-panel/task-web-details-panel.component';
import { TaskWebRefundsPanelComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-refunds-panel/task-web-refunds-panel.component';
import { TaskWebModalHeaderComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-modal-header/task-web-modal-header.component';
import { TaskWebCoTravellersPanelComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-co-travellers-panel/task-web-co-travellers-panel.component';
import { TaskWebLuggagePanelComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-luggage-panel/task-web-luggage-panel.component';
import { TaskWebCoTravellerComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-co-travellers-panel/task-web-co-traveller/task-web-co-traveller.component';
import { DialogDragAreaDirective } from './directives/dialog-drag-area.directive';
import { DialogModalDirective } from './directives/dialog-modal.directive';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TeamPictureComponent } from './components/team-picture/team-picture.component';
import { NgClickOutsideModule } from 'ng-click-outside2';
import { TaskMessageModalMoveTaskComponent } from '@secca/board/components/board-task/board-task-table/task-message-modal/task-message-modal-move-task/task-message-modal-move-task.component';
import { TaskMessageModalMovePaymentComponent } from '@secca/board/components/board-task/board-task-table/task-message-modal/task-message-modal-move-payment/task-message-modal-move-payment.component';
import { TaskWebPropertyComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-property/task-web-property.component';
import { TaskWebBankDetailsPanelComponent } from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-bank-details-panel/task-web-bank-details-panel.component';
import { MessageFormReceiverComponent } from '@secca/case/components/case-output-management/components/message-dialog/message-form/message-form-receiver/message-form-receiver.component';
import { MultipleChoiceComponent } from '@secca/case/components/case-medical/medical-components/cehckbox-multiple-select/multiple-choice.component';
import { YesNoQuestionComponent } from '@secca/case/components/case-medical/medical-components/yes-no-question/yes-no-question.component';
import { TaskHoverComponent } from '@secca/board/components/board-task/board-task-table/task-hover/task-hover.component';
import { StakeholderTooltipDirective } from '@secca/case/components/case-new/stakeholder-tooltip/stakeholder-tooltip-directive';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { StakeholderTooltipComponent } from '@secca/case/components/case-new/stakeholder-tooltip/stakeholder-tooltip.component';
import { KeyboardShortcutsDialogComponent } from '@secca/shared/components/keyboard-shortcuts-dialog/keyboard-shortcuts-dialog.component';
import { KeyboardShortcutsModalComponent } from '@secca/shared/components/keyboard-shortcuts-modal/keyboard-shortcuts-modal.component';
import { DialogKeyboardEnabledDirective } from './directives/dialog-keyboard-enabled.directive';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { PreviewDocumentDialogComponent } from '@secca/shared/components/preview-document-dialog/preview-document-dialog.component';
import { HoverFileScanComponent } from '@secca/case/components/case-documents/case-document-table/case-document-table-hover-filescan/hover-file-scan.component';
import { RecentCasesModalComponent } from './components/recent-cases-modal/recent-cases-modal.component';
import { RecentCasesDialogComponent } from './components/recent-cases-dialog/recent-cases-dialog.component';
import {
  CaseSummaryNoteLinkedCaseModalComponent
} from "@secca/case/components/case-summery/case-summary-list/case-summary-note/case-summary-note-linked-case-modal/case-summary-note-linked-case-model.component";
import {
  CaseSummaryNoteLinkedCaseDialogComponent
} from "@secca/case/components/case-summery/case-summary-list/case-summary-note/case-summary-note-linked-case-dialog/case-summary-note-linked-case-dialog.component";
import { CharCountDirective } from './directives/char-count.directive';
import { DropDownWithAllCausesComponent } from './components/drop-down-with-all-causes/drop-down-with-all-causes.component';
import { MultipleSelectCustomerProfileComponent } from './components/multiple-select-customer-profile/multiple-select-customer-profile.component';
import { PreventDoubleClickDirective } from './directives/prevent-double-click.directive';
import {TaskWebQuestionnaireComponent} from '@secca/board/components/board-task/board-task-incoming-cases-table/task-web-modal/task-web-questionnaire-panel/task-web-questionnaire.component';
import { TaskMessageMoveDialogComponent } from '@secca/board/components/board-task/board-task-table/task-message-move-dialog/task-message-move-dialog.component';
import { DropMenuComponent } from './components/drop-menu/drop-menu.component';

import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { TaskModalStaticHeaderComponent } from '@secca/case/components/case-task/task-modal-static/task-modal-static-header/task-modal-static-header.component';
import { TaskModalStaticRecoveryHeaderComponent } from '@secca/case/components/case-task/task-modal-static/task-modal-static-recovery-header/task-modal-static-recovery-header.component';
import { EconomyStatusBarComponent } from './components/economy-status-bar/economy-status-bar.component';
import { OpenRecoveryPaymentDocumentComponent } from './components/open-recovery-payment-document/open-recovery-payment-document.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
  declarations: [
    InputComponent,
    InputLocationComponent,
    DropDownComponent,
    DropDownUsersStaticComponent,
    DropDownStakeholderWithIconComponent,
    DropDownWithHoverComponent,
    SortDirective,
    DropDownInputComponent,
    TextBoxComponent,
    InputCalenderComponent,
    InputCalendarComponent,
    InputTimeComponent,
    NormalizePipe,
    DropDownAutoCompleteComponent,
    DropDownCountriesAutoComplete,
    TimezoneOffset,
    TextUppercasePipe,
    SafeHtmlPipe,
    HtmlAttributeModifierPipe,
    InputValutaComponent,
    InputCreditCardComponent,
    DropDownWithCountryFlagComponent,
    DropDownServiceOrderComponent,
    DropDownCreditorsComponent,
    DropDownServiceOrderCoverageComponent,
    NumberFormatPipe,
    MomentPipe,
    TimeDiffPipe,
    InputDateTimeComponent,
    DropDownWithIconComponent,
    InputPatternDirective,
    PhoneDropDownComponent,
    DropDownCustomerProfileComponent,
    InputWithValueTypeComponent,
    PermissionDirective,
    DisableInputDirective,
    MultipleSelectComponent,
    RichTextViewComponent,
    CaseLockOverlayComponent,
    DialogSelectableDirective,
    DialogMinimizableDirective,
    InputFocusDirective,
    AlertBoxComponent,
    TaskMessageAttachmentComponent,
    TaskConsentModalComponent,
    TaskMessageLeftPanelComponent,
    IconChekboxRowComponent,
    MultipleSelectDropdownComponent,
    ToggleSwitchComponent,
    SwitchMultiCasePipe,
    UserPictureComponent,
    ObjectTypeTranslatePipePipe,
    StakeholderIconComponent,
    RichTextEditorComponent,
    TaskTextBoxComponent,
    PaginationComponent,
    SubObjectTypeTranslatePipe,
    TaskWebModalMainPanelComponent,
    TaskModalStaticComponent,
    TaskEditorComponent,
    CaseHandlerSelectorComponent,
    TaskReassignComponent,
    SingleChoiceComponent,
    EmployeesComponent,
    TaskWebMedicalReportPanelComponent,
    OpenInvoicePdfComponent,
    TaskWebPropertiesComponent,
    TaskDialogComponent,
    CaseBasicsInsuranceProfileComponent,
    TaskWebDetailsPanelComponent,
    TaskWebRefundsPanelComponent,
    TaskWebModalHeaderComponent,
    TaskWebCoTravellersPanelComponent,
    TaskWebLuggagePanelComponent,
    TaskWebCoTravellerComponent,
    DialogDragAreaDirective,
    DialogModalDirective,
    TeamPictureComponent,
    TaskMessageModalMoveTaskComponent,
    TaskMessageModalMovePaymentComponent,
    TaskWebPropertyComponent,
    TaskWebBankDetailsPanelComponent,
    MessageFormReceiverComponent,
    MultipleChoiceComponent,
    YesNoQuestionComponent,
    TaskHoverComponent,
    StakeholderTooltipDirective,
    StakeholderTooltipDirective,
    StakeholderTooltipComponent,
    KeyboardShortcutsDialogComponent,
    KeyboardShortcutsModalComponent,
    DialogKeyboardEnabledDirective,
    PreviewDocumentDialogComponent,
    HoverFileScanComponent,
    RecentCasesModalComponent,
    RecentCasesDialogComponent,
    CaseSummaryNoteLinkedCaseDialogComponent,
    CaseSummaryNoteLinkedCaseModalComponent,
    TranslateInPipe,
    CharCountDirective,
    DropDownWithAllCausesComponent,
    RecentCasesDialogComponent,
    MultipleSelectCustomerProfileComponent,
    PreventDoubleClickDirective,
    TaskWebQuestionnaireComponent,
    TaskMessageMoveDialogComponent,
    DropMenuComponent,
    TaskModalStaticHeaderComponent,
    TaskModalStaticRecoveryHeaderComponent,
    TaskModalStaticHeaderComponent,
    TaskModalStaticRecoveryHeaderComponent,
    TaskMessageMoveDialogComponent,
    DropMenuComponent,
    EconomyStatusBarComponent,
    OpenRecoveryPaymentDocumentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UtilsModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    GoogleMapsModule,
    NgMultiSelectDropDownModule,
    NgxCurrencyModule,
    PdfViewerModule,
    EditorModule,
    DragDropModule,
    NgClickOutsideModule,
    NgbNavModule,
    NgxMaskModule.forChild(),
    FlexLayoutModule,
    NgbPopoverModule
  ],
  exports: [
    InputComponent,
    InputLocationComponent,
    DropDownComponent,
    DropDownUsersStaticComponent,
    DropDownStakeholderWithIconComponent,
    DropDownWithHoverComponent,
    SortDirective,
    DropDownInputComponent,
    TextBoxComponent,
    InputCalenderComponent,
    InputCalendarComponent,
    InputTimeComponent,
    NormalizePipe,
    DropDownCountriesAutoComplete,
    DropDownAutoCompleteComponent,
    TimezoneOffset,
    TextUppercasePipe,
    SafeHtmlPipe,
    HtmlAttributeModifierPipe,
    InputValutaComponent,
    InputCreditCardComponent,
    DropDownWithCountryFlagComponent,
    DropDownServiceOrderComponent,
    DropDownCreditorsComponent,
    DropDownServiceOrderCoverageComponent,
    NumberFormatPipe,
    MomentPipe,
    TimeDiffPipe,
    InputDateTimeComponent,
    DropDownWithIconComponent,
    InputPatternDirective,
    PhoneDropDownComponent,
    DropDownCustomerProfileComponent,
    InputWithValueTypeComponent,
    PermissionDirective,
    DisableInputDirective,
    MultipleSelectComponent,
    RichTextViewComponent,
    CaseLockOverlayComponent,
    DialogSelectableDirective,
    DialogMinimizableDirective,
    InputFocusDirective,
    AlertBoxComponent,
    TaskMessageAttachmentComponent,
    TaskConsentModalComponent,
    TaskMessageLeftPanelComponent,
    IconChekboxRowComponent,
    MultipleSelectDropdownComponent,
    ToggleSwitchComponent,
    SwitchMultiCasePipe,
    UserPictureComponent,
    ObjectTypeTranslatePipePipe,
    StakeholderIconComponent,
    RichTextEditorComponent,
    EditorModule,
    PaginationComponent,
    SubObjectTypeTranslatePipe,
    TaskWebModalMainPanelComponent,
    TaskModalStaticComponent,
    TaskEditorComponent,
    CaseHandlerSelectorComponent,
    TaskReassignComponent,
    SingleChoiceComponent,
    EmployeesComponent,
    TaskWebMedicalReportPanelComponent,
    OpenInvoicePdfComponent,
    TaskWebPropertiesComponent,
    TaskDialogComponent,
    CaseBasicsInsuranceProfileComponent,
    TaskWebDetailsPanelComponent,
    TaskWebRefundsPanelComponent,
    TaskWebModalHeaderComponent,
    TaskWebCoTravellersPanelComponent,
    TaskWebLuggagePanelComponent,
    TaskWebCoTravellerComponent,
    DialogDragAreaDirective,
    DialogModalDirective,
    TaskMessageModalMoveTaskComponent,
    TaskMessageModalMovePaymentComponent,
    DragDropModule,
    TeamPictureComponent,
    NgClickOutsideModule,
    PdfViewerModule,
    TaskWebPropertyComponent,
    TaskWebBankDetailsPanelComponent,
    MessageFormReceiverComponent,
    MultipleChoiceComponent,
    YesNoQuestionComponent,
    TaskHoverComponent,
    StakeholderTooltipComponent,
    StakeholderTooltipDirective,
    KeyboardShortcutsDialogComponent,
    KeyboardShortcutsModalComponent,
    DialogKeyboardEnabledDirective,
    FlexLayoutModule,
    PreviewDocumentDialogComponent,
    HoverFileScanComponent,
    CaseSummaryNoteLinkedCaseModalComponent,
    CaseSummaryNoteLinkedCaseDialogComponent,
    TranslateInPipe,
    CharCountDirective,
    DropDownWithAllCausesComponent,
    MultipleSelectCustomerProfileComponent,
    PreventDoubleClickDirective,
    TaskWebQuestionnaireComponent,
    NgbPopoverModule,
    TaskModalStaticHeaderComponent,
    TaskModalStaticRecoveryHeaderComponent,
    TaskMessageMoveDialogComponent,
    DropMenuComponent,
    NgbPopoverModule,
    TaskModalStaticHeaderComponent,
    TaskModalStaticRecoveryHeaderComponent,
    NgbPopoverModule,
    EconomyStatusBarComponent,
    OpenRecoveryPaymentDocumentComponent
  ],
  providers: [
    GoogleLocationService,
    LocationService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class SharedModule { }
