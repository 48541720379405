<div class="cases-container">
    <div class="primary-table-container">
      <table class="primary-table fixed-header-table">
        <thead>
          <tr>
            <td (click)="sortBy('customerProfileName')" [ngClass]="sortedBy('customerProfileName')">{{"board-case-table-customer" | translate}}</td>
            <td (click)="sortBy('caseNumber')" [ngClass]="sortedBy('caseNumber')">{{"board-case-table-case-number" | translate}}</td>
            <td (click)="sortBy('endUserFullName')" [ngClass]="sortedBy('endUserFullName')">{{"board-case-table-end-user-name" | translate}}</td>
            <td (click)="sortBy('currentLocationLocality')" [ngClass]="sortedBy('currentLocationLocality')">{{"board-case-table-current-location-city" | translate}}</td>
            <td (click)="sortBy('currentLocationCountry')" [ngClass]="sortedBy('currentLocationAlpha2')">{{"board-case-table-current-location-country" | translate}}</td>
            <td (click)="sortBy('homeCity')" [ngClass]="sortedBy('homeCity')">{{"board-case-table-home-city" | translate}}</td>
            <td (click)="sortBy('firstRepatriationDate')" [ngClass]="sortedBy('firstRepatriationDate')">{{"board-case-table-current-transport-order" | translate}}</td>
            <td (click)="sortBy('expectedRepatriationForm')" [ngClass]="sortedBy('expectedRepatriationForm')">{{ 'board-case-table-repatriation-form-exp' | translate }} </td>
            <td>{{ 'board-case-table-medical-case-details' | translate }}</td>
            <td (click)="sortBy('firstTransportServiceOrderFlightLegDate')" [ngClass]="sortedBy('firstTransportServiceOrderFlightLegDate')">{{"board-case-table-repatriation-date" | translate}}</td>
            <td (click)="sortBy('readyFrom')" [ngClass]="sortedBy('readyFrom')" >{{ 'board-case-table-medical-ready-from' | translate }}</td>
            <td (click)="sortBy('coTransported')" [ngClass]="sortedBy('coTransported')">{{"board-case-table-co-transport" | translate}}</td>
            <td (click)="sortBy('relatedICCCaseNumber')" [ngClass]="sortedBy('relatedICCCaseNumber')">{{"board-case-table-icc-case-number" | translate}}</td>
          </tr>
        </thead>
        <tbody (scroll)="onCaseTabelScroll()" id="caseTransportTabel" [class.zoomed]="settingsService.isZoomed()">
            <tr *ngFor="let boardTransportCase of boardTransportCases; index as i"  (click)="redirectToCase(boardTransportCase.id)" [routerLink]="['/case',boardTransportCase.id]">
                <td class="customer-image-column">
                    <img class="customer-image" *ngIf="boardTransportCase.customerProfileId"
                      [src]="getBrandImagesUrlForProfile(boardTransportCase.customerProfileId)"
                      [ngbPopover]="boardTransportCase.customerProfileName"
                      placement="auto"
                      triggers="hover">
                </td>
                <td>
                    <span [ngClass]="{'case-locked': boardTransportCase.caseLockedBy && boardTransportCase.caseLockedBy != loggedInUser.id}">{{boardTransportCase.caseNumber}}</span>
                </td>
                <td>
                    {{boardTransportCase.endUserFullName}}
                </td>
                <td class="long-column">
                    {{boardTransportCase.currentLocationLocality}}
                </td>
                <td class="long-column">
                    {{boardTransportCase.currentLocationCountry}}
                </td>

                <td class="long-column">
                  {{boardTransportCase.homeCity}}
                </td>
                <td class="long-column pe-2">
                  {{boardTransportCase.firstRepatriationDate  | date :'dd MMM'}}
                </td>
                <td class="long-column">
                  {{getRepatriationType(boardTransportCase.expectedRepatriationForm)}}
                </td>
                <td class="long-column">
                  <div *ngIf="boardTransportCase.relatedToMajorIncidentCase === true" class="case-status case-details-major-incident"></div>
                  <div *ngIf="boardTransportCase.relatedToICCCase === true" class="case-status case-details-internal-coordination"></div>
                  <div *ngIf="boardTransportCase.medicalServiceorderType === CommitmentTypes.REGULAR_MEDICAL_ASSESSMENT" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-REGULAR" | translate}} </div>
                  <div *ngIf="boardTransportCase.medicalServiceorderType === CommitmentTypes.LIGHT_MEDICAL_REVIEW" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-LIGHT" | translate}} </div>
                  <div *ngIf="boardTransportCase.medicalServiceorderType === CommitmentTypes.NIGHT_SHIFT_MEDICAL_ASSESSMENT" class="case-status case-details-medical-service-order"> {{"board-task-table-case-details-NIGHT" | translate}} </div>
                  <div *ngIf="boardTransportCase.medicalServiceorderType === CommitmentTypes.CLAIMS_MEDICAL_REVIEW" class="case-status case-details-claims-service-order"> {{"board-task-table-case-details-CLAIMS" | translate}} </div>
                  <div *ngIf="boardTransportCase.caseDetailsAIR === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-AIR" | translate}} </div>
                  <div *ngIf="boardTransportCase.caseDetailsSTR === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-STR" | translate}} </div>
                  <div *ngIf="boardTransportCase.caseDetailsEXT === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-EXT" | translate}} </div>
                  <div *ngIf="boardTransportCase.caseDetailsSIT === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-SIT" | translate}} </div>
                  <div *ngIf="boardTransportCase.caseDetailsREG === true" class="case-status case-details-repatriation-form"> {{"board-task-table-case-details-REG" | translate}} </div>
                  <div *ngIf="boardTransportCase.caseDetailsDOC === true || boardTransportCase.caseDetailsNUR === true || boardTransportCase.caseDetailsNME === true" class="case-status case-details-escort"> {{ caseDetailsEscort(boardTransportCase) }} </div>
                  <div *ngIf="boardTransportCase.caseDetailsOXY === true" class="case-status case-details-oxygen"> {{"board-task-table-case-details-OXY" | translate}} </div>
                  <div *ngIf="boardTransportCase.caseDetailsEQU === true" class="case-status case-details-equipment"> {{"board-task-table-case-details-EQU" | translate}} </div>
                </td>
                <td class="long-column pe-2">
                  {{boardTransportCase.firstTransportServiceOrderFlightLegDate  | date :'dd MMM HH:mm'}}
                </td>
                <td >
                  <div>
                    <div class="long-column pe-2">
                      {{boardTransportCase.readyFrom | date: 'dd MMM YYYY' }}
                      <div *ngIf="boardTransportCase.arrivalDeadline" (mouseenter)="setShowEntryMenu(i)" (mouseleave)="clearShowEntryMenu(i)" class="aarrival-deadline" >
                        {{boardTransportCase.arrivalDeadline | date: 'dd MMM YYYY' }}
                      </div>
                    </div>
                    <div class="pop-over" *ngIf="getShowEntryMenu(i)">
                      <div class="pop-over-triangle"></div>
                      {{'board-task-table-arrival-deadline-popover-text' | translate: {'arrivalDeadline': boardTransportCase.arrivalDeadline | date | lowercase} }}
                    </div>
                  </div>
                </td>
                <td class="long-column pe-2">
                  {{ coTransported(boardTransportCase.coTransported) }}
                </td>
                <td class="long-column pe-2">
                  {{ boardTransportCase.relatedICCCaseNumber }}
                </td>
            </tr>
        </tbody>
      </table>
    </div>
</div>
