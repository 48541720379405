import { DateHelper } from '@secca/shared/helpers/date-helper';
import { SubMedicalEscort } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subMedicalEscort';
import { HelperService } from './helper.service';
import { CaseStakeholder } from '@secca/shared/models/caseStakeholder';
import { GroundTranportServiceOrderExtension } from '@secca/shared/models/service-order/ground-transport-service-order-extension';
import { GroundAmbulanceServiceOrderExtension } from '@secca/shared/models/service-order/ground-ambulance-service-order-extension';
import { TreatmentServiceOrderExtension } from '@secca/shared/models/service-order/treatment-service-order-extension';
import { ServiceTypeEnum, StakeholderTypeEnum, StatusTypes } from '@secca/shared/models/enums';
import { ServiceOrder, ServiceOrderAdapter } from '@secca/shared/models/service-order/service-order';
import { SettingsService } from './settings.service';
import { BaseService } from './base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ServiceOrderExtension } from '@secca/shared/models/service-order/service-order-extension';
import { CaseLockHttpService } from './case-lock-http.service';
import { MortalRemainsServiceOrderExtension } from '@secca/shared/models/service-order/mortal-remains-service-order-extension';
import { ReferalToCustomerServiceOrderExtension } from '@secca/shared/models/service-order/referal-to-customer-service-order-extension';
import { GeneralAdviceServiceOrderExtension } from '@secca/shared/models/service-order/general-advice-service-order-extension';
import { AccommodationServiceOrderExtension } from '@secca/shared/models/service-order/accommodation-service-order-extension';
import { BaseDurationServiceOrderExtension } from '@secca/shared/models/service-order/base-duration-service-order-extension';
import { MoneyTransferServiceOrderExtension } from '@secca/shared/models/service-order/money-transfer-service-order-extension';
import { RegularFlightNonMedicalServiceOrderExtension } from '@secca/shared/models/service-order/regular-flight-non-medical-service-order-extension';
import { RegularFlightMedicalServiceOrderExtension } from '@secca/shared/models/service-order/regular-flight-medical-service-order-extension';
import { LuggageServiceOrderExtension } from '@secca/shared/models/service-order/luggage-service-order-extension';
import { AmbulanceFlightServiceOrderExtension } from '@secca/shared/models/service-order/ambulance-flight-service-order-extension';
import { MedicalEscortServiceOrderExtension } from '@secca/shared/models/service-order/medical-escort-service-order-extension';
import { AdviceOnInsuranceTermsServiceOrderExtension } from '@secca/shared/models/service-order/advice-on-insurance-terms';
import { CaseValidationService } from '@secca/core/services/case-validation.service';
import { CasePlansService } from '@secca/case/components/case-plans/case-plans.service';
import { PlanService } from '@secca/core/services/plan.service';
import { AssistanceOrContactServiceOrderExtension } from '@secca/shared/models/service-order/assistance-or-contact-service-order-extension';
import { OtherRefundRequestServiceOrderExtension } from '@secca/shared/models/service-order/other-refund-request-service-order-extension';
import { DropdownWithIconDisableDictionary } from '@secca/shared/models/dropdownWithIconDisableDictionary';
import { AmbulanceFlightCloner } from './ambulance-flight-cloner.service';
import { AdviceCallRegistrationServiceOrderExtension } from '@secca/shared/models/service-order/advice-call-registration-service-order-extension';
import { AssessmentUsedInServiceOrdersAdapter, AssessmentUsedInServiceOrders } from '@secca/shared/models/medical-models/assessmentUsedInServiceOrders';
import { SubStakeholders } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholders';
import { PlanDisplayHelper } from '@secca/case/components/case-plans/plan/plan-display-helper';
import { ServiceOrderType, ServiceOrderTypeAdapter } from '@secca/shared/models/service-order-type';
import { MedicalPreAssessmentServiceOrderExtension } from '@secca/shared/models/service-order/medical-pre-assessment-service-order-extension';
import { InvestigationServiceOrderExtension } from '@secca/shared/models/service-order/investigation-service-order-extension';
import { TranslationServiceOrderExtension } from '@secca/shared/models/service-order/translation-service-order-extension';
import { PreDepartureAdviceServiceOrderExtension } from '@secca/shared/models/service-order/medical-pre-departure-advice-service-order-extension';
import {
  CoordinationTransportServiceOrderExtension
} from '@secca/shared/models/service-order/coordination-transport-service-order-extension';
import { MedicalFlightAuthorizationServiceOrderExtension } from '@secca/shared/models/service-order/medical-flight-authorization-service-order-extension';
import { ReservedServiceOrderId } from '@secca/shared/models/service-order/reserved-service-order-id';
import { CoordinationCaseService } from './coordination-case.service';
import { SubStakeholder } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subStakeholder';
import { SubTravelInfo } from '@secca/case/components/case-plans/case-plans/add-service-order/models/subTravelInfo';
import { ICCAmbulanceFlight } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-ambulanceFlight';
import { ICCFlightLegRelevantForCase } from '@secca/case/components/case-plans/case-plans/add-service-order/models/icc-flightLegsRelevantForCase';
import { ServiceOrderCloneHelper } from '@secca/core/services/service-order-clone-helper';
import { MajorIncidentServiceOrderExtension } from '@secca/shared/models/service-order/major-incident-service-order-extension';

@Injectable({
  providedIn: 'root',
})
export class ServiceOrderService extends BaseService {

  constructor(
    private http: HttpClient,
    private serviceOrderAdapter: ServiceOrderAdapter,
    private serviceOrderTypeAdapter: ServiceOrderTypeAdapter,
    private settingsService: SettingsService,
    private caseLockHttpService: CaseLockHttpService,
    private helperService: HelperService,
    private caseValidationService: CaseValidationService,
    private casePlansService: CasePlansService,
    private planService: PlanService,
    private assessmentUsedInServiceOrdersAdapter: AssessmentUsedInServiceOrdersAdapter,
    private coordinationCaseService: CoordinationCaseService
  ) {
    super(settingsService);
  }

  private caseServiceOrders: BehaviorSubject<ServiceOrder[]> = new BehaviorSubject(undefined);

  public getServiceOrderTypes(showClaims: boolean = false): Observable<DropdownWithIconDisableDictionary[]> {
    return this.http
      .get(this.baseURL + 'service-orders/service-order-types')
      .pipe(
        map((data: any[]) =>
          data.map(item => this.mapMasterlistServiceTypeToServiceOrderTypeWithIcons(item, showClaims, false, false))
            .filter(svctype => svctype !== null)
        )
      );
  }

  public getServiceOrderTypesPerCase(caseId: string): Observable<ServiceOrderType[]>{
      return this.http.get<any[]>(this.baseURL + 'service-orders/service-order-types/' + caseId)
      .pipe(map((data: any[]) => data.map(item => this.serviceOrderTypeAdapter.adapt(item))));
  }

  public getServiceOrderTypesWitIcons(caseId: string, showClaims: boolean = false, showMedicalFlightAuthorizationAgreement: boolean = false): Observable<DropdownWithIconDisableDictionary[]> {
    return this.getServiceOrderTypesPerCase(caseId)
      .pipe(
        map((data: ServiceOrderType[]) =>
          data.map(item => this.mapMasterlistServiceTypeToServiceOrderTypeWithIcons(item, showClaims, showMedicalFlightAuthorizationAgreement, true)).filter(svctype => svctype !== null)
        )
      );
  }

  public getAllServiceOrderTypes(): Observable<ServiceOrderType[]> {
    return this.http
      .get(this.baseURL + 'service-orders/service-order-types')
      .pipe(
        map((data: any[]) =>
          data.map(item => this.serviceOrderTypeAdapter.adapt(item))
        )
      );
  }

  mapMasterlistServiceTypeToServiceOrderTypeWithIcons(
    masterlistServiceType: ServiceOrderType,
    showClaims: boolean,
    showMedicalFlightAuthorizationAgreement: boolean,
    showDisabled
  ): DropdownWithIconDisableDictionary {
    const allServiceOrderTypes = [
      {
        id: ServiceTypeEnum.MEDICAL_ASSESSMENT,
        iconPath: '/assets/icons/service-order-type/Medical Assessment.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.MEDICAL_PRE_ASSESSMENT,
        iconPath: '/assets/icons/service-order-type/Medical Pre Assessment.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.TREATMENT,
        iconPath: '/assets/icons/service-order-type/Treatment.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.ACCOMMODATION,
        iconPath: '/assets/icons/service-order-type/Accomodation.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL,
        iconPath: '/assets/icons/service-order-type/Regular Flight - Non-medical.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL,
        iconPath: '/assets/icons/service-order-type/Regular Flight - Medical.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.MEDICAL_ESCORT,
        iconPath: '/assets/icons/service-order-type/Medical Escort Fee.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.AMBULANCE_FLIGHT,
        iconPath: '/assets/icons/service-order-type/Ambulance Flight.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.GROUND_AMBULANCE,
        iconPath: '/assets/icons/service-order-type/Ambulance.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.GROUND_TRANSPORT,
        iconPath: '/assets/icons/service-order-type/Ground Transport.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.HANDLING_OF_MORTAL_REMAINS,
        iconPath: '/assets/icons/service-order-type/Handling of mortal remains.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.ASSISTANCE_OR_CONTACT,
        iconPath: '/assets/icons/service-order-type/Assistance or Contact.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS,
        iconPath: '/assets/icons/service-order-type/Advise on insurance terms.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.GENERAL_ADVICE,
        iconPath: '/assets/icons/service-order-type/General Advice.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.REFERRAL_TO_CUSTOMER,
        iconPath: '/assets/icons/service-order-type/Referral to Customer.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.LUGGAGE,
        iconPath: '/assets/icons/service-order-type/Luggage.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.MONEY_TRANSFER,
        iconPath: '/assets/icons/service-order-type/Money Transfer.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.OTHER_REFUND_REQUEST,
        iconPath: '/assets/icons/service-order-type/OtherRefund.svg',
        disabled: showDisabled ? !showClaims || !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.ADVICE_CALL_REGISTRATION,
        iconPath: '/assets/icons/service-order-type/Advice call registration.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.INVESTIGATION,
        iconPath: '/assets/icons/service-order-type/Investigation.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.TRANSLATION,
        iconPath: '/assets/icons/service-order-type/Translation.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.PRE_DEPARTURE_TRAVEL_SERVICE,
        iconPath: '/assets/icons/service-order-type/Pre-departure advice.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.COORDINATION_TRANSPORT,
        iconPath: '/assets/icons/service-order-type/Coordination transport.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.MAJOR_INCIDENT,
        iconPath: '/assets/icons/service-order-type/major incident.svg',
        disabled: showDisabled ? !masterlistServiceType.allowed : false
      },
      {
        id: ServiceTypeEnum.MEDICAL_FLIGHT_AUTHORIZATION,
        iconPath: '/assets/icons/service-order-type/Medical Flight Authorization.svg',
        disabled: showDisabled ? !showMedicalFlightAuthorizationAgreement || !masterlistServiceType.allowed : false
      }
    ];
    const type = allServiceOrderTypes.find(serviceOrderType => serviceOrderType.id === masterlistServiceType.serviceTypeEnumValue);
    if (type) {
      const hoverText = masterlistServiceType.allowed ? null : 'service-order-service-type-disabled';

      return new DropdownWithIconDisableDictionary(type.id, masterlistServiceType.serviceTypeName, type.iconPath, type.disabled, hoverText);
    }
    return null;
  }

  public extensionFactory(serviceOrderType: ServiceOrderType): ServiceOrderExtension {
    let extensionInstance: ServiceOrderExtension = null;
    switch (serviceOrderType.serviceTypeEnumValue) {
      case ServiceTypeEnum.TREATMENT:
        extensionInstance = new TreatmentServiceOrderExtension();
        break;
      case ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL:
        extensionInstance = new RegularFlightNonMedicalServiceOrderExtension();
        break;
      case ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL:
        extensionInstance = new RegularFlightMedicalServiceOrderExtension();
        break;
      case ServiceTypeEnum.ASSISTANCE_OR_CONTACT:
        extensionInstance = new AssistanceOrContactServiceOrderExtension();
        break;
      case ServiceTypeEnum.AMBULANCE_FLIGHT:
        extensionInstance = new AmbulanceFlightServiceOrderExtension();
        break;
      case ServiceTypeEnum.MEDICAL_ESCORT:
        extensionInstance = new MedicalEscortServiceOrderExtension();
        break;
      case ServiceTypeEnum.HANDLING_OF_MORTAL_REMAINS:
        extensionInstance = new MortalRemainsServiceOrderExtension();
        break;
      case ServiceTypeEnum.REFERRAL_TO_CUSTOMER:
        extensionInstance = new ReferalToCustomerServiceOrderExtension();
        break;
      case ServiceTypeEnum.GENERAL_ADVICE:
        extensionInstance = new GeneralAdviceServiceOrderExtension();
        break;
      case ServiceTypeEnum.GROUND_AMBULANCE:
        extensionInstance = new GroundAmbulanceServiceOrderExtension();
        break;
      case ServiceTypeEnum.MONEY_TRANSFER:
        extensionInstance = new MoneyTransferServiceOrderExtension();
        break;
      case ServiceTypeEnum.LUGGAGE:
        extensionInstance = new LuggageServiceOrderExtension();
        break;
      case ServiceTypeEnum.ACCOMMODATION:
        extensionInstance = new AccommodationServiceOrderExtension();
        break;
      case ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS:
        extensionInstance = new AdviceOnInsuranceTermsServiceOrderExtension();
        break;
      case ServiceTypeEnum.GROUND_TRANSPORT:
        extensionInstance = new GroundTranportServiceOrderExtension();
        break;
      case ServiceTypeEnum.OTHER_REFUND_REQUEST:
        extensionInstance = new OtherRefundRequestServiceOrderExtension();
        break;
      case ServiceTypeEnum.ADVICE_CALL_REGISTRATION:
        extensionInstance = new AdviceCallRegistrationServiceOrderExtension();
        break;
      case ServiceTypeEnum.MEDICAL_PRE_ASSESSMENT:
        extensionInstance = new MedicalPreAssessmentServiceOrderExtension();
        break;
      case ServiceTypeEnum.INVESTIGATION:
        extensionInstance = new InvestigationServiceOrderExtension();
        break;
      case ServiceTypeEnum.TRANSLATION:
        extensionInstance = new TranslationServiceOrderExtension();
        break;
      case ServiceTypeEnum.PRE_DEPARTURE_TRAVEL_SERVICE:
        extensionInstance = new PreDepartureAdviceServiceOrderExtension();
        break;
      case ServiceTypeEnum.COORDINATION_TRANSPORT:
        extensionInstance = new CoordinationTransportServiceOrderExtension();
        break;
      case ServiceTypeEnum.MAJOR_INCIDENT:
        extensionInstance = new MajorIncidentServiceOrderExtension();
        break;
      case ServiceTypeEnum.MEDICAL_FLIGHT_AUTHORIZATION:
        extensionInstance = new MedicalFlightAuthorizationServiceOrderExtension();
        break;
      default:
        break;
    }
    if (extensionInstance){
      extensionInstance.setCommitmentType(serviceOrderType.defaultCommitmentType);
    }
    return extensionInstance;
  }

  public createServiceOrder(serviceOrder: ServiceOrder): Observable<ServiceOrder> {
    return this.caseLockHttpService
      .post(this.baseURL + 'service-orders', serviceOrder, {
        headers: this.jsonHeaders,
      })
      .pipe(tap(_ => this.caseValidationService.sendVerifyCaseNumberAssigned(serviceOrder.caseId.toString())))
      .pipe(
        map((data: any) => {
          const adapted = this.serviceOrderAdapter.adapt(data);
          this.casePlansService.sendNewServiceOrder(adapted);
          this.sendNewCoverage(serviceOrder.caseId).subscribe(result => {
            this.casePlansService.sendCoverages(result);
          });
          return adapted;
        })
      );
  }

  public markAsDeletedServiceOrder(id: number, caseId: number): Observable<ServiceOrder> {
    return this.caseLockHttpService.delete(this.baseURL + 'service-orders/' + id).pipe(
      map((data: any) => {
        const adapted = this.serviceOrderAdapter.adapt(data);
        this.casePlansService.sendDeletedServiceOrder(adapted);
        this.sendNewCoverage(caseId).subscribe(result => {
          this.casePlansService.sendCoverages(result);
        });
        return adapted;
      })
    );
  }

  public getServiceOrdersForCase(caseId: number): Observable<ServiceOrder[]> {
    return this.http
      .get<ServiceOrder[]>(this.baseURL + 'service-orders/by-case/' + caseId)
      .pipe(map((data: any[]) => data.map(item => this.serviceOrderAdapter.adapt(item))));
  }

  public getServiceOrdersForSupplierInvoice(caseId: number): Observable<ServiceOrder[]> {
    return this.http
      .get<ServiceOrder[]>(this.baseURL + 'service-orders/by-case-for-supplier-invoices/' + caseId)
      .pipe(map((data: any[]) => data.map(item => this.serviceOrderAdapter.adapt(item))));
  }

  public getServiceOrdersForCaseByType(caseId: number, serviceTypeEnum: ServiceTypeEnum): Observable<ServiceOrder[]> {
    return this.http
      .get<ServiceOrder[]>(this.baseURL + 'service-orders/by-case-and-type/' + caseId + '/' + serviceTypeEnum)
      .pipe(map((data: any[]) => data.map(item => this.serviceOrderAdapter.adapt(item))));
  }

  public sendCaseServiceOrders(serviceOrders: ServiceOrder[]) {
    this.caseServiceOrders.next(serviceOrders);
  }

  public getCaseServiceOrders(): Observable<ServiceOrder[]> {
    return this.caseServiceOrders.asObservable();
  }

  public getServiceOrdersUsedInMedicalAssessments(assessment: number[]): Observable<AssessmentUsedInServiceOrders[]> {
    return this.http
      .post<AssessmentUsedInServiceOrders>(this.baseURL + 'service-orders/service-orders-used-in-medical-assessment', assessment)
      .pipe(map((data: any) => data.map(item => this.assessmentUsedInServiceOrdersAdapter.adapt(item))));
  }

  public editServiceOrder(serviceOrder: ServiceOrder, sendGopActivated: boolean = false): Observable<ServiceOrder> {
    const httpParams = new HttpParams().set('sendGopActivated', sendGopActivated.toString());
    return this.caseLockHttpService
      .post<ServiceOrder>(this.baseURL + 'service-orders/edit', serviceOrder, {
        headers: this.jsonHeaders,
        params: httpParams,
      })
      .pipe(
        map(data => {
          const adapted = this.serviceOrderAdapter.adapt(data);
          adapted.serviceId = serviceOrder.serviceId;
          this.casePlansService.sendEditedServiceOrder(serviceOrder);
          this.sendNewCoverage(serviceOrder.caseId).subscribe(result => {
            this.casePlansService.sendCoverages(result);
          });
          return adapted;
        })
      );
  }

  public createGop(serviceOrder: ServiceOrder): Observable<any> {
    return this.http.post(this.baseURL + 'service-orders/templates/create-and-store/gop', serviceOrder,
      { headers: this.jsonHeaders, responseType: 'text' } );
  }

  public previewGop(serviceOrder: ServiceOrder): Observable<any> {
    return this.http
      .post(this.baseURL + 'service-orders/templates/create/gop', serviceOrder, {
        responseType: 'arraybuffer',
      })
      .pipe(
        map((res: any) => {
          return new Blob([res], {
            type: 'application/pdf',
          });
        })
      );
  }

  public getServiceOrder(id: number): Observable<ServiceOrder> {
    if (!id) {
      throw 'ServiceOrder id not set';
    }
    return this.http
      .get(this.baseURL + 'service-orders/' + id, {
        headers: this.jsonHeaders,
      })
      .pipe(map(item => this.serviceOrderAdapter.adapt(item)));
  }


  public getServiceOrderByCaseIdAndServiceOrderid(caseId: number, serviceOrderId: string): Observable<ServiceOrder> {
    return this.http
    .get<ServiceOrder>(this.baseURL + `service-orders/case/${caseId}/service-order/${serviceOrderId}`, {
      headers: this.jsonHeaders,
    })
    .pipe(map(item => !!item ? this.serviceOrderAdapter.adapt(item) : item));
  }

  public findServiceOrderCountOnCase(caseId: number): Observable<any> {
    return this.http.get(this.baseURL + 'service-orders/count/' + caseId, { headers: this.jsonHeaders });
  }

  public isCoveragesUsedOnExistingServiceOrders(caseId: number): Observable<any> {
    return this.http.get(this.baseURL + 'service-orders/using-coverages/' + caseId, { headers: this.jsonHeaders });
  }

  public cloneCoordinationCaseServiceOrders(serviceOrders: ServiceOrder[], stakeholders: CaseStakeholder[]) {
    const coordinationCaseServiceOrders = serviceOrders.filter(serviceOrder => serviceOrder.type === ServiceTypeEnum.COORDINATION_TRANSPORT);
    const clonedServiceOrders = [];
    coordinationCaseServiceOrders.forEach(coordinationCaseServiceOrder => {
      const extension = coordinationCaseServiceOrder.extension as CoordinationTransportServiceOrderExtension;
      const flightStakeholders = coordinationCaseServiceOrder.status === StatusTypes.COMMITTED ? extension.iccConfirmationFlightStakeholders.stakeholders : extension.iccExpectedFlightStakeholders.stakeholders;
      const coTravellerWasAdded = [];
      flightStakeholders.forEach(flightStakeholder => {
        const caseStakeholder = stakeholders.find(a => a.id === flightStakeholder.stakeholderId);
        const clonedCoTraveller: ServiceOrder = this.cloneCoTravellerServiceOrder(coordinationCaseServiceOrder, caseStakeholder);
        if (clonedCoTraveller != null && !coTravellerWasAdded.includes(clonedCoTraveller.laneNumber) ) {
                clonedServiceOrders.push(clonedCoTraveller);
                coTravellerWasAdded.push(clonedCoTraveller.laneNumber);
              }
      })
    });
    return clonedServiceOrders;
  }


  public cloneAmbulanceFlightServiceOrders(serviceOrders: ServiceOrder[], stakeholders: CaseStakeholder[], iccServiceOrders: ServiceOrder[] = []) {
    const ambulanceFlightServiceOrders = serviceOrders.filter(serviceOrder => serviceOrder.type === ServiceTypeEnum.AMBULANCE_FLIGHT);
    const clonedServiceOrders = [];
    ambulanceFlightServiceOrders.forEach(ambulanceFlightServiceOrder => {
      const iccServiceOrder: ServiceOrder = this.coordinationCaseService.linkedToIcc(ambulanceFlightServiceOrder, iccServiceOrders);
      const extension = ambulanceFlightServiceOrder.extension as AmbulanceFlightServiceOrderExtension;
      let iccExtension;
      if (iccServiceOrder){
        ambulanceFlightServiceOrder.isLinkedToIcc = true;
        iccExtension =  iccServiceOrder.extension as CoordinationTransportServiceOrderExtension;

        extension.confirmationFlight = iccExtension.getTravelInfoForCase(ambulanceFlightServiceOrder.caseId).filter(extension =>
          extension.relevantForCaseIds.filter(relevant => +relevant.linkedCaseId === ambulanceFlightServiceOrder.caseId && relevant.relevantForCase === true).length !== 0
        );

        if (!extension.confirmationFlight ||  extension.confirmationFlight.length == 0) {
          extension.confirmationFlight = [new SubTravelInfo()];
        }

        let ambFlight: ICCAmbulanceFlight = iccExtension.getConfirmationAmbulanceFlightforCase(ambulanceFlightServiceOrder.caseId);
        if (ambFlight) {
          extension.confirmationGroundTransportFromFlightNotIncluded  = ambFlight.groundTransportFromFlightNotIncluded;
          extension.confirmationGroundTransportToFlightNotIncluded  = ambFlight.groundTransportToFlightNotIncluded;
        }
        if (ambFlight && ambFlight.groundTransportToFlight && !ambFlight.groundTransportToFlightNotIncluded) {
          extension.subConfirmationGroundTransportToFlight = ambFlight.subGroundTransportToFlight;
          extension.confirmationGroundTransportToFlight = ambFlight.subGroundTransportToFlight.flightLegs;
          extension.confirmationGroundTransportToFlightNotIncluded  = ambFlight.groundTransportToFlightNotIncluded;
        }
        if (ambFlight && ambFlight.groundTransportFromFlight && !ambFlight.groundTransportFromFlightNotIncluded) {
          extension.subConfirmationGroundTransportFromFlight = ambFlight.subGroundTransportFromFlight;
          extension.confirmationGroundTransportFromFlight = ambFlight.subGroundTransportFromFlight.flightLegs;
          extension.confirmationGroundTransportFromFlightNotIncluded  = ambFlight.groundTransportFromFlightNotIncluded;

        }

      }

      let ambulanceFlightCloner = new AmbulanceFlightCloner(ambulanceFlightServiceOrder, this.helperService);
      let clonedAmbFlight = ambulanceFlightCloner.cloneForGroundTransport();
      if (clonedAmbFlight) {
        clonedServiceOrders.push(...clonedAmbFlight);
      }
      const allServiceOrders: ServiceOrder[] = [ambulanceFlightServiceOrder, ...clonedAmbFlight];
      allServiceOrders.forEach(endUserCloned => {
        const extension = endUserCloned.extension as AmbulanceFlightServiceOrderExtension;
        let flightStakeholders = ambulanceFlightServiceOrder.status === StatusTypes.COMMITTED
          ? extension.confirmationFlightStakeholders
          : extension.flightStakeholders;

        const coTravellerWasAdded = [];
        if (iccServiceOrder) {
          flightStakeholders = this.resolveSubStakeholdersFromIccSO(iccExtension.getStakeHoldersForEndUserServiceOrder(endUserCloned.id,ambulanceFlightServiceOrder.status), stakeholders).stakeholders;
        }

        flightStakeholders.forEach(stakeholder => {
          const caseStakeholder = stakeholders.find(a => a.id === stakeholder.stakeholderId);
          const clonedCoTraveller: ServiceOrder = this.cloneCoTravellerServiceOrder(endUserCloned, caseStakeholder);
          if (clonedCoTraveller != null && !coTravellerWasAdded.includes(clonedCoTraveller.laneNumber) ) {
            if (iccServiceOrder){
              clonedCoTraveller.isLinkedToIcc = true;
            }

            clonedServiceOrders.push(clonedCoTraveller);
            coTravellerWasAdded.push(clonedCoTraveller.laneNumber);
          }
        });
      });
    });
    return clonedServiceOrders;
  }

  public cloneRegularFlightServiceOrders(serviceOrders: ServiceOrder[], stakeholders: CaseStakeholder[], iccServiceOrders: ServiceOrder[] = []) {
    const regularFlightMedicalServiceOrders = serviceOrders.filter(
      serviceOrder => serviceOrder.type === ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL || serviceOrder.type === ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL
    );
    const clonedServiceOrders = [];
    regularFlightMedicalServiceOrders.forEach(regularFlightMedical => {

      const coTravellerWasAdded = [];
      const extension = regularFlightMedical.extension as RegularFlightMedicalServiceOrderExtension;
      const iccServiceOrder: ServiceOrder = this.coordinationCaseService.linkedToIcc(regularFlightMedical, iccServiceOrders)
      let iccExtension;
      if (iccServiceOrder){
        regularFlightMedical.isLinkedToIcc = true;
        iccExtension =  iccServiceOrder.extension as CoordinationTransportServiceOrderExtension;
        extension.confirmationTravelInfo = iccExtension.getTravelInfoForCase(regularFlightMedical.caseId).filter(extension =>
            extension.relevantForCaseIds.filter(relevant => +relevant.linkedCaseId === regularFlightMedical.caseId && relevant.relevantForCase === true).length !== 0
        );
      }
      const endUserCloned = this.cloneEndUserServiceOrder(regularFlightMedical);
      clonedServiceOrders.push(endUserCloned);
      let subStakeholders: SubStakeholders;
      let medicalEscorts: SubMedicalEscort[]

      if(!iccServiceOrder){
        subStakeholders = regularFlightMedical.status === StatusTypes.COMMITTED ? extension.confirmationSubStakeholders : extension.expectedSubStakeholders;
        medicalEscorts = regularFlightMedical.status === StatusTypes.COMMITTED ? extension.confirmationMedicalEscorts : extension.expectedMedicalEscorts;
      } else {

        subStakeholders = this.resolveSubStakeholdersFromIccSO(iccExtension.getStakeHoldersForEndUserServiceOrder(regularFlightMedical.id, regularFlightMedical.status), stakeholders);
        medicalEscorts = [];
        extension.confirmationMedicalEscorts = [];
        extension.expectedMedicalEscorts = [];
      }

      subStakeholders.stakeholders.forEach(stakeholder => {
        const caseStakeholder = stakeholders.find(a => a.id === stakeholder.stakeholderId);
        if ( this.isStakeholderEndUser(caseStakeholder) ) {
          coTravellerWasAdded.push(PlanDisplayHelper.END_USER_LANE);
        }
        else {
          const clonedCoTraveller: ServiceOrder = this.cloneCoTravellerServiceOrder(endUserCloned, caseStakeholder);
          if (iccServiceOrder){
            clonedCoTraveller.isLinkedToIcc = true;
          }
          if (clonedCoTraveller != null && !coTravellerWasAdded.includes(clonedCoTraveller.laneNumber)) {
            (clonedCoTraveller.extension as RegularFlightMedicalServiceOrderExtension).selectedStakeholderIds = [+caseStakeholder.id];
            clonedServiceOrders.push(clonedCoTraveller);
            coTravellerWasAdded.push(clonedCoTraveller.laneNumber);
          }
        }
      });
      medicalEscorts?.forEach(medicalEscort => {
        const clonedMedicalEscortOutboundTrip = this.cloneMedicalEscortOutboundTrip(endUserCloned, medicalEscort);
        if (clonedMedicalEscortOutboundTrip != null) {
          clonedServiceOrders.push(clonedMedicalEscortOutboundTrip);
        }
        const clonedMedicalEscortReturnTrip = this.cloneMedicalEscortReturnTrip(endUserCloned, medicalEscort);
        if (clonedMedicalEscortReturnTrip != null) {
          clonedServiceOrders.push(clonedMedicalEscortReturnTrip);
        }
        // we need an invisible clone of the accommodation for it to be positioned correctly
        const clonedAccommodationForPositioning = ServiceOrderCloneHelper.cloneMedicalEscortAccommodation(endUserCloned, medicalEscort);
        if (clonedAccommodationForPositioning) {
          clonedAccommodationForPositioning.laneNumber = PlanDisplayHelper.NON_VISIBLE_LANE;
          clonedServiceOrders.push(clonedAccommodationForPositioning);
        }
        const clonedAccommodation = ServiceOrderCloneHelper.cloneMedicalEscortAccommodation(endUserCloned, medicalEscort);
        if (clonedAccommodation) {
          clonedServiceOrders.push(clonedAccommodation);
        }
      });

      const clonedEndUserRepatriation = this.cloneEndUserRepatriation(endUserCloned, extension);
      if (clonedEndUserRepatriation != null) {
        clonedServiceOrders.push(clonedEndUserRepatriation);
      }
      if ( !coTravellerWasAdded.includes(PlanDisplayHelper.END_USER_LANE) ) {
        // No end user, mark as non-visible, but still use it to calculate position for the other lanes
        endUserCloned.useEndUserLaneForPlacementCalculation = true;
        endUserCloned.laneNumber = PlanDisplayHelper.NON_VISIBLE_LANE;
      }
      regularFlightMedical.isDuplicated = true;
    });
    return clonedServiceOrders;
  }

  private resolveSubStakeholdersFromIccSO( stakeholderIds: string[], caseStakeholders: CaseStakeholder[]): SubStakeholders {
    const subStakeHolders: SubStakeholder[] = stakeholderIds.map(stId => {
      const caseStakeHolder: CaseStakeholder = caseStakeholders.find(st => stId = st.id);
      return new SubStakeholder({stakeholderId: stId,
                                 stakeholderType: caseStakeHolder.stakeholderType
      })
    });
    return new SubStakeholders(subStakeHolders)
  }

  public cloneOtherServiceOrdersWithStakeholders(serviceOrders: ServiceOrder[], stakeholders: CaseStakeholder[]) {
    const stakeholderServiceOrders = serviceOrders.filter(
      serviceOrder => serviceOrder.type === ServiceTypeEnum.GROUND_TRANSPORT ||
                      (serviceOrder.type === ServiceTypeEnum.GROUND_AMBULANCE && !serviceOrder.parentServiceId) // exclude ambulance flight - ground ambulance
    );
    const clonedServiceOrders = [];
    stakeholderServiceOrders.forEach(stakeholderServiceOrder => {
      const extension: any = stakeholderServiceOrder.extension;
      const endUserCloned = this.cloneEndUserServiceOrder(stakeholderServiceOrder);
      clonedServiceOrders.push(endUserCloned);

      const coTravellerWasAdded = [];
      extension.subStakeholders?.stakeholders.forEach(stakeholder => {
        const caseStakeholder = stakeholders.find(a => a.id === stakeholder.stakeholderId);
        if ( this.isStakeholderEndUser(caseStakeholder) ) {
          coTravellerWasAdded.push(PlanDisplayHelper.END_USER_LANE);
        }
        else {
          const clonedCoTraveller: ServiceOrder = this.cloneCoTravellerServiceOrder(endUserCloned, caseStakeholder);
          if (clonedCoTraveller != null && !coTravellerWasAdded.includes(clonedCoTraveller.laneNumber)) {
            clonedServiceOrders.push(clonedCoTraveller);
            coTravellerWasAdded.push(clonedCoTraveller.laneNumber);
          }
        }
      });

      if ( !coTravellerWasAdded.includes(PlanDisplayHelper.END_USER_LANE) ) {
        // No end user, mark as non-visible, but still use it to calculate position for the other lanes
        endUserCloned.useEndUserLaneForPlacementCalculation = true;
        endUserCloned.laneNumber = PlanDisplayHelper.NON_VISIBLE_LANE;
      }

      stakeholderServiceOrder.isDuplicated = true;
    });

    return clonedServiceOrders;
  }

  public cloneDurationServiceOrders(serviceOrders: ServiceOrder[], stakeholders: CaseStakeholder[]) {
    const clonedServiceOrders = serviceOrders.filter(
      serviceOrder => serviceOrder.type !== ServiceTypeEnum.ACCOMMODATION && serviceOrder.type !== ServiceTypeEnum.TREATMENT
    );
    const durationServiceOrders = serviceOrders.filter(
      serviceOrder => serviceOrder.type === ServiceTypeEnum.ACCOMMODATION || serviceOrder.type === ServiceTypeEnum.TREATMENT
    );

    durationServiceOrders.forEach(durationServiceOrder => {
      const extension: any = durationServiceOrder.extension;
      let displayDurationId = 0;

      extension.durations.forEach(duration => {
        const endUserCloned = this.cloneEndUserServiceOrder(durationServiceOrder);

        // Durations
        let baseDuration = endUserCloned.extension as BaseDurationServiceOrderExtension;
        baseDuration.displayDurationId = displayDurationId;
        if (endUserCloned.status !== StatusTypes.CANCELLED && endUserCloned.status !== StatusTypes.NOT_COVERED) {
          if (duration.isGopPeriodsValid() && endUserCloned.status === StatusTypes.COMMITTED) {
            endUserCloned.status = StatusTypes.COMMITTED;
          } else {
            endUserCloned.status = StatusTypes.EXPECTED;
          }
        }
        displayDurationId++;
        clonedServiceOrders.push(endUserCloned);

        // Co-travellers
        const coTravellerWasAdded = [];
        extension.stakeholders?.forEach(stakeholder => {
          const caseStakeholder = stakeholders.find(a => a.id === stakeholder.stakeholderId);
          if ( this.isStakeholderEndUser(caseStakeholder) ) {
            coTravellerWasAdded.push(PlanDisplayHelper.END_USER_LANE);
          }
          else {
            const clonedCoTraveller: ServiceOrder = this.cloneCoTravellerServiceOrder(endUserCloned, caseStakeholder);
            if (clonedCoTraveller != null && !coTravellerWasAdded.includes(clonedCoTraveller.laneNumber) ) {
              clonedServiceOrders.push(clonedCoTraveller);
              coTravellerWasAdded.push(clonedCoTraveller.laneNumber);
            }
          }
        });

        if ( durationServiceOrder.type !== ServiceTypeEnum.TREATMENT && !coTravellerWasAdded.includes(PlanDisplayHelper.END_USER_LANE) ) {
          // No end user, mark as non-visible, but still use it to calculate position for the other lanes
          endUserCloned.useEndUserLaneForPlacementCalculation = true;
          endUserCloned.laneNumber = PlanDisplayHelper.NON_VISIBLE_LANE;
        }
      });
    });

    return clonedServiceOrders;
  }

  public isStakeholderEndUser(stakeholder: CaseStakeholder): boolean {
    if ( !stakeholder ) {
      return false;
    }
    if (stakeholder.hasRole(StakeholderTypeEnum.policyHolder)) {
      return stakeholder.enduserIsPolicyholder;
    }
    if (stakeholder.hasRole(StakeholderTypeEnum.reporter)) {
      return stakeholder.enduserIsReporter;
    }
    return stakeholder.hasRole(StakeholderTypeEnum.endUser);
  }

  public isStakeholderCoTraveller(stakeholder: CaseStakeholder): boolean {
    if ( !stakeholder ) {
      return false;
    }
    if (stakeholder.hasRole(StakeholderTypeEnum.policyHolder)) {
      return !stakeholder.enduserIsPolicyholder;
    }
    if (stakeholder.hasRole(StakeholderTypeEnum.reporter)) {
      return !stakeholder.enduserIsReporter;
    }
    return stakeholder.hasRole(StakeholderTypeEnum.person) ||
           stakeholder.hasRole(StakeholderTypeEnum.nonMedicalEscort);
  }

  public isStakeholderMedicalEscort(stakeholder: CaseStakeholder): boolean {
    return stakeholder.hasRole(StakeholderTypeEnum.escortDoctor) ||
           stakeholder.hasRole(StakeholderTypeEnum.escortNurse);
  }

  private cloneEndUserServiceOrder(serviceOrder: ServiceOrder): ServiceOrder {
    const endUserServiceOrder = ServiceOrder.cloneDeep(serviceOrder);
    endUserServiceOrder.laneNumber = 0;
    endUserServiceOrder.serviceId = this.helperService.getRandomGuid();

    return endUserServiceOrder;
  }

  private cloneCoTravellerServiceOrder(serviceOrder: ServiceOrder, caseStakeholder: CaseStakeholder): any {
    if (caseStakeholder && this.isStakeholderCoTraveller(caseStakeholder)) {
      const coTravellerServiceOrder = ServiceOrder.cloneDeep(serviceOrder);
      coTravellerServiceOrder.laneNumber = 1;
      coTravellerServiceOrder.caseStakeholderIds = [+caseStakeholder.id];
      coTravellerServiceOrder.serviceId = this.helperService.getRandomGuid();
      coTravellerServiceOrder.coTravellersEndUserServiceId = serviceOrder.serviceId;

      return coTravellerServiceOrder;
    }
    else if (caseStakeholder && this.isStakeholderMedicalEscort(caseStakeholder)) {
      const coTravellerServiceOrder = ServiceOrder.cloneDeep(serviceOrder);
      coTravellerServiceOrder.laneNumber = 2;
      coTravellerServiceOrder.caseStakeholderIds = [+caseStakeholder.id];
      coTravellerServiceOrder.serviceId = this.helperService.getRandomGuid();
      coTravellerServiceOrder.coTravellersEndUserServiceId = serviceOrder.serviceId;

      return coTravellerServiceOrder;
    }
    else {
      return null;
    }
  }

  private cloneMedicalEscortOutboundTrip(regularFlightMedical: ServiceOrder, medicalEscort: SubMedicalEscort): ServiceOrder {
    if (
      !medicalEscort.outboundTripNotNeeded &&
      medicalEscort.outboundTrips.length > 0 &&
      medicalEscort.outboundTrips[0].departureDate != null &&
      medicalEscort.outboundTrips[0].departureFrom?.freeText &&
      medicalEscort.outboundTrips[0].arrivalAt?.freeText
    ) {
      const medicalEscortOutboundTripServiceOrder = ServiceOrder.cloneDeep(regularFlightMedical);
      const medicalEscortExtension: RegularFlightMedicalServiceOrderExtension =
        medicalEscortOutboundTripServiceOrder.extension as RegularFlightMedicalServiceOrderExtension;
      let startTravelInfoToManipulate = medicalEscortExtension.expectedTravelInfo;
      let endTravelInfoToManipulate = medicalEscortExtension.expectedTravelInfo;
      if (regularFlightMedical.status === StatusTypes.COMMITTED) {
        startTravelInfoToManipulate = medicalEscortExtension.confirmationTravelInfo[0];
        endTravelInfoToManipulate = medicalEscortExtension.confirmationTravelInfo[medicalEscortExtension.confirmationTravelInfo.length - 1];
      }
      startTravelInfoToManipulate.departureFrom = medicalEscort.outboundTrips[0].departureFrom;
      endTravelInfoToManipulate.arrivalAt = medicalEscort.outboundTrips[medicalEscort.outboundTrips.length - 1].arrivalAt;
      medicalEscortOutboundTripServiceOrder.laneNumber = 2;
      startTravelInfoToManipulate.departureDate = medicalEscort.outboundTrips[0].departureDate;
      endTravelInfoToManipulate.arrivalDate =
        medicalEscort.outboundTrips[medicalEscort.outboundTrips.length - 1].arrivalDate == null
          ? DateHelper.setTimeAs23Hour59Minutes(medicalEscort.outboundTrips[0].departureDate)
          : medicalEscort.outboundTrips[medicalEscort.outboundTrips.length - 1].arrivalDate;

      medicalEscortOutboundTripServiceOrder.serviceId = this.helperService.getRandomGuid();
      medicalEscortOutboundTripServiceOrder.useEndUserLaneForPlacementCalculation = true;
      return medicalEscortOutboundTripServiceOrder;
    } else {
      return null;
    }
  }

  private cloneEndUserRepatriation(endUserCloned: ServiceOrder, extension: RegularFlightMedicalServiceOrderExtension): ServiceOrder {
    if (
      (endUserCloned.status === StatusTypes.EXPECTED && extension.expectedMedicalEscorts?.length > 0) ||
      (endUserCloned.status === StatusTypes.COMMITTED && extension.confirmationMedicalEscorts?.length > 0)
    ) {
      const repatriationWithEndUserServiceOrder = ServiceOrder.cloneDeep(endUserCloned);
      repatriationWithEndUserServiceOrder.laneNumber = 2;
      repatriationWithEndUserServiceOrder.serviceId = this.helperService.getRandomGuid();
      repatriationWithEndUserServiceOrder.coTravellersEndUserServiceId = endUserCloned.serviceId;
      return repatriationWithEndUserServiceOrder;
    } else {
      return null;
    }
  }

  private cloneMedicalEscortReturnTrip(regularFlightMedical: ServiceOrder, medicalEscort: SubMedicalEscort): ServiceOrder {
    if (
      !medicalEscort.returnTripNotNeeded &&
      medicalEscort.returnTrips.length > 0 &&
      medicalEscort.returnTrips[0].departureDate != null &&
      medicalEscort.returnTrips[0].departureFrom?.freeText &&
      medicalEscort.returnTrips[0].arrivalAt?.freeText
    ) {
      const medicalEscortReturnTripServiceOrder = ServiceOrder.cloneDeep(regularFlightMedical);
      const medicalEscortExtension: RegularFlightMedicalServiceOrderExtension =
        medicalEscortReturnTripServiceOrder.extension as RegularFlightMedicalServiceOrderExtension;
      let startTravelInfoToManipulate = medicalEscortExtension.expectedTravelInfo;
      let endTravelInfoToManipulate = medicalEscortExtension.expectedTravelInfo;
      if (regularFlightMedical.status === StatusTypes.COMMITTED) {
        startTravelInfoToManipulate = medicalEscortExtension.confirmationTravelInfo[0];
        endTravelInfoToManipulate = medicalEscortExtension.confirmationTravelInfo[medicalEscortExtension.confirmationTravelInfo.length - 1];
      }
      startTravelInfoToManipulate.departureFrom = medicalEscort.returnTrips[0].departureFrom;
      startTravelInfoToManipulate.departureDate = medicalEscort.returnTrips[0].departureDate;
      endTravelInfoToManipulate.arrivalAt = medicalEscort.returnTrips[medicalEscort.returnTrips.length - 1].arrivalAt;
      endTravelInfoToManipulate.arrivalDate =
        medicalEscort.returnTrips[medicalEscort.returnTrips.length - 1].arrivalDate == null
          ? DateHelper.setTimeAs23Hour59Minutes(medicalEscort.returnTrips[0].departureDate)
          : medicalEscort.returnTrips[medicalEscort.returnTrips.length - 1].arrivalDate;
      medicalEscortReturnTripServiceOrder.laneNumber = 2;
      medicalEscortReturnTripServiceOrder.serviceId = this.helperService.getRandomGuid();
      medicalEscortReturnTripServiceOrder.useEndUserLaneForPlacementCalculation = true;
      return medicalEscortReturnTripServiceOrder;
    } else {
      return null;
    }
  }

  public getETicketUrl(): string {
    return 'https://portal.get-e.com/portal/login';
  }

  private sendNewCoverage(caseId: number) {
    return this.planService.getCoveragesForCase(caseId.toString());
  }

  public getServiceOrdersForCoordinationCase(coordCaseId: number): Observable<ServiceOrder[]> {
   return this.http
     .get<ServiceOrder[]>(this.baseURL + 'coordination/service-orders/linked/' + coordCaseId)
     .pipe(map((data: any[]) => data.map(item => this.serviceOrderAdapter.adapt(item))));
  }

  reserveServiceOrderId(caseId: number): Observable<ReservedServiceOrderId> {
    return this.http
      .get<ReservedServiceOrderId>(this.baseURL + 'service-orders/reserve-service-order-id/' + caseId, {
        headers: this.jsonHeaders
      });
  }
}
