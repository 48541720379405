import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './settings.service';
import { ProviderSearch } from '../../shared/models/ProviderSearch';
import { Observable} from 'rxjs';
import { ProviderResponse } from '../../shared/models/ProviderResponse';
import { ProviderModifiedResponseDto } from '@secca/shared/models/provider-modified-status';
import { Response, SupplierSearchResponse, CountrySearchResponse } from '@secca/shared/models/SupplierSearchResponse';
import { ProviderLookup } from '@secca/shared/models/SupplierLookup';
import { SupplierServicesResponse } from '@secca/shared/models/SupplierServicesResponse';
import { ApiResponse } from '@secca/shared/models/SupplierSearchCountries';

@Injectable({
  providedIn: 'root'
})
export class ProviderService extends BaseService {

  constructor(private http: HttpClient, private settingsService: SettingsService) {
    super(settingsService);
  }

  public getProviderURL(): string {
    return this.settingsService.getProviderURL();
  }

  public createProviderSearch(searchSimple: ProviderSearch): Observable<ProviderResponse> {
    return this.http
      .post<ProviderResponse>(this.baseURL + 'providers/search-params', searchSimple, {
        headers: this.jsonHeaders
      });
  }

  public providerModified(caseId: number, checkTime: Date, caseStakeholderIds: number[]): Observable<ProviderModifiedResponseDto> {
    const input: any = { caseId, checkTime, caseStakeholderIds };

    return this.http
      .post<ProviderModifiedResponseDto>(this.baseURL + 'providers/modified', input, {
        headers: this.jsonHeaders
      });
  }

  public searchSuppliers(seccaSearchParams: string): Observable<SupplierSearchResponse> {
    return this.http.get<SupplierSearchResponse>(this.baseURL + 'travelcare/search/secca?' + seccaSearchParams, {
       headers: this.jsonHeaders
     });
  }

  public suggesterKeywordSelecterOnSupplierServices(keyword: string): Observable<Response> {
    const apiUrl = this.baseURL + 'travelcare/search/suggest/with_categories?term=' + keyword;
    return this.http.get<Response>(apiUrl, {headers: this.jsonHeaders});
  }

  public searchCountry(code: string): Observable<CountrySearchResponse> {
    return this.http.get<CountrySearchResponse>(`${this.baseURL}travelcare/search/country?code=${code}`, {
      headers: this.jsonHeaders
    });
  }

  public addSupplierToCase(supplierData: ProviderLookup): Observable<number> {
    return this.http.post<number>(this.baseURL + 'providers', supplierData, {
        headers: this.jsonHeaders
      });
  }

  public searchSuppliersServices(): Observable<SupplierServicesResponse> {
    return this.http.get<SupplierServicesResponse>(this.baseURL + 'integration/supplierServices_servicetypes', {
      headers: this.jsonHeaders
    });
  }

  public searchCompetences(): Observable<SupplierServicesResponse> {
    return this.http.get<SupplierServicesResponse>(this.baseURL + 'integration/supplierServices_compentences', {
      headers: this.jsonHeaders
    });
  }

  countryNameAndAlpha2Api(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseURL + 'integration/countries', {
      headers: this.jsonHeaders
    })
  }
}
