<div appDialogSelectable appDialogKeyboardEnabled [appDialogMinimizable]="MinimizableDialogType.CaseCountryInfo">

  <mat-dialog-content (cdkDragStarted)="dialogBoundryService.handleDragStart($event)"
                      (cdkDragEnded)="dialogBoundryService.handleDragEnd($event)"
                      cdkDragRootElement=".cdk-overlay-pane" cdkDrag>
    <div appDialogDragArea cdkDragHandle></div>

    <app-country-info
      (closeCountryInfo)="close()"
      [countryBasics]="countryBasics"
      [countryInfo]="countryInfo">
    </app-country-info>

    <app-drop-down-countries-auto-complete
      [name]="'case-country-info-select-country' | translate"
      [showCode]="false"
      [autoCompleteTypeEnum]="AutoCompleteTypeEnum.Countries"
      [countryName]="countryBasics?.countryName"
      [title]="countryBasics?.countryName"
      [(selectedItemId)]="countryBasics"
      (selectedItemIdChange)="countryChange($event)">
    </app-drop-down-countries-auto-complete>
  </mat-dialog-content>
</div>
