import {CoordinationTypeEnum, ServiceTypeEnum, UserDepartmentEnum, UserTeam} from 'src/app/shared/models/enums';
import { StakeholderTypeAdapter } from '../../modules/case/components/case-stakeholders/stakeholderType';
import { CountryDetails, CountryDetailsAdapter } from '../../shared/models/country';
import { CaseUserSearchUserWrapper, SearchUser } from '../../shared/models/searchUser';
import { UserDto, UserDtoAdapter } from '../../shared/models/userDto';
import { ChannelDropdownAdapter, DropdownAdapter, DropdownDictionary, TeamDropdownAdapter } from './../../shared/models/dropdownDictionary';
import { Department } from '../../shared/models/department';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Data } from '../mocks/data';
import { map } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import { PolicyLookupMethodType } from '@secca/shared/models/policy-lookup-response';
import { TranslateService } from '@ngx-translate/core';
import { ServiceTypeCommitmentTypeMapping } from '@secca/shared/models/mappings';
import { TeamDto } from '@secca/shared/models/teamDto';
import { Note } from '@secca/shared/models/note';
import { DropdownWithHoverDictionary } from '@secca/shared/models/dropdownWithHoverDictionary';
import { CommitmentTypeService } from './commitment-type-service';
import { ServiceOrderType } from '@secca/shared/models/service-order-type';
import { CommitmentType } from '@secca/shared/models/commitment-type';
import {DeleteReason} from '@secca/shared/models/deleteReason';
import { ReasonForCancellation } from '@secca/shared/models/reason-for-cancellation';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService extends BaseService {
  constructor(
    private http: HttpClient,
    private dropdownAdapter: DropdownAdapter,
    private settingsService: SettingsService,
    private countryDetailsAdapter: CountryDetailsAdapter,
    private stakeholderTypeAdapter: StakeholderTypeAdapter,
    private userDtoAdapter: UserDtoAdapter,
    private teamDropdownAdapter: TeamDropdownAdapter,
    private caseUserSearchUserWrapper: CaseUserSearchUserWrapper,
    private translateService: TranslateService,
    private commitmentTypeService: CommitmentTypeService,
    private channelDropdownAdapter: ChannelDropdownAdapter
  ) {
    super(settingsService);
  }

  private loggedUser = new BehaviorSubject<UserDto>(undefined);

  private cachedStakeHolderTypes = new BehaviorSubject(undefined);

  private allCachedUserTeams;
  private cachedStakeHolderTypesLoading = false;

  public getAllUsers(): Observable<UserDto[]> {
    return this.http
      .get<any[]>(this.baseURL + 'users', {
        headers: this.jsonHeaders,
      })
      .pipe(map((data: any[]) => data.map(item => this.userDtoAdapter.adapt(item))));
  }

  public getDepartments(): Observable<Department[]> {
    return this.http.get<Department[]>(this.baseURL + 'department');
  }

  public getGenders(): DropdownDictionary[] {
    return Data.getGenders();
  }

  public getAllCountries(): Observable<CountryDetails[]> {
    return this.http
      .get<any[]>(this.baseURL + 'countries')
      .pipe(map((data: any[]) => data.map(item => this.countryDetailsAdapter.adapt(item))));
  }

  public searchCountries(query: string): Observable<CountryDetails[]> {
    return this.http
      .get<any[]>(this.baseURL + 'countries/search/' + query)
      .pipe(map((data: any[]) => data.map(item => this.countryDetailsAdapter.adapt(item))));
  }

  public getCountryByAlpha2(query: string): Observable<CountryDetails> {
    return this.http
      .get<CountryDetails>(this.baseURL + 'countries/searchByAlpha2/' + query)
      .pipe(map(item => this.countryDetailsAdapter.adapt(item)));
  }

  public getCaseCause(): Observable<DropdownDictionary[]> {
    return this.http.get<DropdownDictionary[]>(this.baseURL + 'caseCause');
  }

  public sendStakeholdersTypes(stakeHolderTypes: any[]) {
    this.cachedStakeHolderTypes.next(stakeHolderTypes);
  }

  public getStakeholdersTypes(): Observable<any[]> {
    if (this.cachedStakeHolderTypes.value == null && !this.cachedStakeHolderTypesLoading) {
      this.cachedStakeHolderTypesLoading = true;
      this.fetchStakeholderTypes().subscribe(
        result => {
          this.cachedStakeHolderTypes.next(result);
          this.cachedStakeHolderTypesLoading = false;
          return this.cachedStakeHolderTypes.asObservable();
        },
        error => console.log(error)
      );
    }
    return this.cachedStakeHolderTypes.asObservable();
  }

  private fetchStakeholderTypes(): Observable<any[]> {
    return this.http
      .get<any[]>(this.baseURL + 'stakeholders/stakeholdersTypes')
      .pipe(map((data: any[]) => data.map(item => this.stakeholderTypeAdapter.adapt(item))));
  }

  public getAccommodations(): Observable<DropdownDictionary[]> {
    return this.http.get<DropdownDictionary[]>(this.baseURL + 'travelplans/accommodation-list');
  }

  public getMeansOfTransportation(): Observable<DropdownDictionary[]> {
    return this.http.get<DropdownDictionary[]>(this.baseURL + 'travelplans/means-of-transportation-list');
  }

  public getCustomers(): Observable<DropdownDictionary[]> {
    return this.http.get<DropdownDictionary[]>(this.baseURL + 'customer');
  }

  public getInsuranceLookupMethodList(): Observable<PolicyLookupMethodType[]> {
    return this.http.get<PolicyLookupMethodType[]>(this.baseURL + 'insurances/insurance-lookup-method-list');
  }

  public getInsuranceLevelList(): Observable<DropdownDictionary[]> {
    return this.http.get<DropdownDictionary[]>(this.baseURL + 'insurances/insurance-level-list');
  }

  public getCaseStatuses(): DropdownDictionary[] {
    return Data.getCaseStatuses().map(item => this.dropdownAdapter.adapt(item));
  }

  public getFileTypes(): DropdownDictionary[] {
    return Data.getFileTypes().map(item => this.dropdownAdapter.adapt(item));
  }

  public getLoggedInUser(): Observable<UserDto> {
    if (this.loggedUser.value == null) {
      this.getLoggedInUserFromServer().subscribe(
        result => {
          if(JSON.stringify(this.loggedUser.value) != JSON.stringify(result)) {
            this.loggedUser.next(result);
          }
        },
        error => console.log(error)
      );
    }
    return this.loggedUser.asObservable();
  }

  private getLoggedInUserFromServer(): Observable<UserDto> {
    return this.http.get<UserDto>(this.baseURL + 'users/getloggedinuser');
  }

  public getAllActiveCaseHandlerTeamsDD(): Observable<DropdownDictionary[]> {
    return this.getAllActiveCaseHandlerTeams().pipe(
      map((data: any[]) => data.map(item => this.teamDropdownAdapter.adapt(item))));
  }

  public getAllActiveTeamsExcludingOtherDepartmentDD(): Observable<DropdownDictionary[]> {
    return this.getAllActiveTeamsExcludingOtherDepartment().pipe(
      map((data: any[]) => data.map(item => this.teamDropdownAdapter.adapt(item))));
  }

  public getAllActiveCaseHandlerTeams(): Observable<TeamDto[]> {
    return this.getAllActiveTeamsList().pipe(
              map((data: TeamDto[]) =>
                    data.filter(item => item.caseHandler || item.adtCode === UserTeam.all)));
  }

  public getAllActiveTeamsExcludingOtherDepartment(caseId?: string): Observable<TeamDto[]> {
    return this.getAllActiveTeamsList(caseId).pipe(
              map((data: TeamDto[]) =>
                    data.filter(item => item.departmentId !== UserDepartmentEnum.OTHER)));
  }

  public getAllActiveTeamsList(caseId?: string): Observable<TeamDto[]> {
    return this.getAllActiveTeamsListFromTheServer(caseId);
  }

  public getAllActiveTeamsListDD(caseId?: string): Observable<DropdownDictionary[]> {
    return this.getAllActiveTeamsListFromTheServer().pipe(
              map((data: any[]) => data.map(item => this.teamDropdownAdapter.adapt(item))));
  }

  public getAllTeamsList(): Observable<TeamDto[]> {
    if (!this.allCachedUserTeams) {
      this.allCachedUserTeams = new ReplaySubject<any>(1);

      this.getAllTeamsListFromTheServer().subscribe(
        result => {
          this.allCachedUserTeams.next(result);
        },
        error => console.log(error)
      );
    }

    return this.allCachedUserTeams;
  }

  private getAllTeamsListFromTheServer(caseId?: string): Observable<TeamDto[]> {
    return this.http.get<any[]>(this.baseURL + `users/all-teams`);
  }

  private getAllActiveTeamsListFromTheServer(caseId?: string): Observable<TeamDto[]> {
    if (caseId) {
      return this.http.get<any[]>(this.baseURL + `users/all-active-teams/${caseId}`);
    }
    return this.http.get<any[]>(this.baseURL + `users/all-active-teams`);
  }

  public searchUsersSimplified(term: string): Observable<SearchUser[]> {
    const searchUser = {
      firstName: term,
      initials: term,
      surname: term,
      simplifiedSearch: true,
    };
    return this.http.post<any[]>(this.baseURL + 'searches/users', searchUser, {
      headers: this.jsonHeaders,
    });
  }

  public searchUser(userId: string): Observable<SearchUser> {
    return this.http
      .get<SearchUser>(this.baseURL + 'users/by-user-id/' + userId)
      .pipe(map(result => this.caseUserSearchUserWrapper.wrap(result)));
  }

  public searchUserById(id: number): Observable<SearchUser> {
    return this.http.get<SearchUser>(this.baseURL + 'users/by-id/' + id).pipe(map(result => this.caseUserSearchUserWrapper.wrap(result)));
  }

  public searchUsers(term: string): Observable<SearchUser[]> {
    const searchUser = {
      firstName: term,
      initials: term,
      surname: term,
      simplifiedSearch: false,
      excludedTeams: [UserTeam.other],
    };
    return this.http.post<any[]>(this.baseURL + 'searches/users', searchUser, {
      headers: this.jsonHeaders,
    });
  }

  public searchUsersExcludeDepartments(term: string, excluded: string[]): Observable<SearchUser[]> {
    const searchUser = {
      firstName: term,
      initials: term,
      surname: term,
      simplifiedSearch: false,
      excludedDepartmentIds: excluded,
    };
    return this.http.post<any[]>(this.baseURL + 'searches/users', searchUser, {
      headers: this.jsonHeaders,
    });
  }

  public searchUsersExact(userId: number): Observable<SearchUser[]> {
    const searchUser = {
      firstName: '',
      initials: '',
      surname: '',
      simplifiedSearch: false,
      id: userId
    };
    return this.http.post<any[]>(this.baseURL + 'searches/users', searchUser, {
      headers: this.jsonHeaders,
    });
  }

  public searchUsersForTeam(team: string): Observable<UserDto[]> {
    return this.http
      .get<UserDto[]>(this.baseURL + 'users/' + team)
      .pipe(map((data: any[]) => data.map(item => this.userDtoAdapter.adapt(item))));
  }

  public searchUsersForTeams(teams: string[]): Observable<UserDto[]> {
    return this.http
      .get<UserDto[]>(this.baseURL + 'users/by-adtcode-list/' + teams)
      .pipe(map((data: any[]) => data.map(item => this.userDtoAdapter.adapt(item))));
  }

  public deleteNote(noteId: number) {
    return this.http.post<Note>(this.baseURL + 'notes/delete/' + noteId, {
      headers: this.jsonHeaders,
    });
  }

  public deleteSupoCupoNote(noteId: number, reason: string) {
    const deleteReasonObj = new DeleteReason({reason});
    return this.http.put<Note>(this.baseURL + 'notes/delete-note-cupo-supo-delete-reason/' + noteId, deleteReasonObj, {
      headers: this.jsonHeaders,
    });
  }

  public getDictionaryEntriesAsStringObject(url: string): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + url, {
      headers: this.jsonHeaders,
    });
  }

  public getCommitmentTypeDictionary(commitmentTypes: CommitmentType[]): DropdownWithHoverDictionary[] {
    return commitmentTypes.map(item => this.createDictionaryWithHoverObject(item));
  }

  public getCoordinationTypeDictionary(): DropdownDictionary[] {
    return Object.keys(CoordinationTypeEnum).map(item => this.createDictionaryObject(item, 'coordination-case-type-'));
  }

  private createDictionaryWithHoverObject(value: any) {
    return new DropdownWithHoverDictionary(value, value.hoverText);
  }

  private createDictionaryObject(value: string, prefix: string) {
    return new DropdownDictionary(value, this.translateService.instant(prefix + value));
  }

  public getExchangeCurrenciesSupported(): Observable<any> {
    return this.http.get<any>(this.baseURL + 'exchange/exchange-currencies-supported');
  }

  public getExchangeRefundCurrenciesSupported(): Observable<any> {
    return this.http.get<any>(this.baseURL + 'exchange/exchange-refund-currencies-supported');
  }

  public getBaseCurrenciesSupported(): Observable<any> {
    return this.http.get<any>(this.baseURL + 'exchange/base-currencies-supported');
  }

  public getExchangeRecoveryCurrenciesSupported(): Observable<any> {
    //return this.http.get<any>(this.baseURL + 'exchange/exchange-recovery-currencies-supported/');
    return this.http.get<any>(this.baseURL + 'exchange/exchange-refund-currencies-supported/');
  }

  public getReasonForCancellation(): Observable<DropdownDictionary[]> {
    return this.http
      .get<ReasonForCancellation[]>(this.baseURL + 'masterlist/reason-for-cancellation')
      .pipe(map((data: any[]) => data.filter(item => item.active).map(item => new DropdownDictionary(item.reasonForCancellationCode, item.reasonForCancellationName))));
  }

  public getActiveChannelTypes(): Observable<DropdownDictionary[]> {
    return this.http
      .get(this.baseURL + `channel-types/active`)
      .pipe(map((data: any[]) => data.filter(item => item).map(item => this.channelDropdownAdapter.adapt(item))));
  }
}

