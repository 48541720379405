import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';

export class PostHandlingsDto {
  webTaskToRemove: number;
  messageTaskToRemove: number;
  taskToDone: number;
  feature: string;
  removeSentMail: boolean;
  sendToReporter: boolean;
  deleteReason: string;
  recoveryId: number;


  public constructor(init?: Partial<PostHandlingsDto>) {
    Object.assign(this, init);
  }
}

@Injectable({
  providedIn: 'root',
})
export class EmailPosthandlingsAdapter implements Adapter<PostHandlingsDto> {
  constructor() {}

  adapt(item: any): PostHandlingsDto {
    return new PostHandlingsDto({
      webTaskToRemove: item.webTaskToRemove,
      messageTaskToRemove: item.messageTaskToRemove,
      taskToDone: item.taskToDone,
      removeSentMail: item.removeSentMail,
      sendToReporter: item.sentToReporter,
      feature: item.feature,
      recoveryId: item.recoveryId,
    });
  }
}
