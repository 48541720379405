import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter';
import { Country } from '@secca/shared/models/country';
import * as moment from 'moment';
import { DaySerializer } from './day-serializer';

export class Person {
    id: number;
    firstName: string;
    surname: string;

    dateOfBirth: moment.Moment;
    age: number;
    genderCalculated: boolean;
    dateOfBirthCalculated: boolean;
    dateOfDeath: moment.Moment;
    email: string;
    hasEmail: boolean;
    emailStatus: string;
    preferredChannelCode: string;
    preferredLanguageCode: string;
    remarks: string;
    nationalId: string;
    nationalIdCountry: Country;
    nationalIdValid: boolean;
    passportNumber: string;
    passportExpiryDate: moment.Moment;
    gender: string;
    ssnValidated: boolean;
    deactivated: boolean;
    ssnLookupPerformed: boolean;
    ssnLookupPossible: boolean;
    ssnLookupError: string;
    nameInPassport: boolean;

    public constructor(init?: Partial<Person>) {
        Object.assign(this, init);
        if (!init || !init.nationalIdCountry) {
            this.nationalIdCountry = new Country();
            this.nationalIdCountry.code = '';
            this.nationalIdCountry.name = '';
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class PersonAdapter implements Adapter<Person> {
    adapt(item: any): Person {
      return new Person({
            id: item.id,
            firstName: item.firstName,
            surname: item.surname,
            dateOfBirth: DaySerializer.deserialize(item.dateOfBirth),
            age: item.age,
            dateOfBirthCalculated: item.dateOfBirthCalculated,
            dateOfDeath: DaySerializer.deserialize(item.dateOfDeath),
            email: item.email,
            hasEmail: item.hasEmail,
            emailStatus: item.emailStatus,
            preferredChannelCode: item.preferredChannelCode,
            preferredLanguageCode: item.preferredLanguageCode,
            remarks: item.remarks,
            nationalId: item.nationalId,
            nationalIdCountry: item.nationalIdCountry,
            nationalIdValid: item.nationalIdValid,
            passportNumber: item.passportNumber,
            passportExpiryDate: DaySerializer.deserialize(item.passportExpiryDate),
            gender: item.gender,
            genderCalculated: item.genderCalculated,
            ssnValidated: item.ssnValidated,
            deactivated: item.deactivated,
            ssnLookupPerformed: item.ssnLookupPerformed,
            ssnLookupPossible: item.ssnLookupPossible,
            ssnLookupError: item.ssnLookupError,
            nameInPassport: item.nameInPassport,
        });
    }
}
export class UpdateDateOfBirthOrAgeOrGenderOrNationalId {
    dateOfBirth: moment.Moment;
    age: number;
    dateOfBirthCalculated: boolean;
    nationalId: string;
    nationalIdCountry: Country;
    nationalIdValid: boolean;
    gender: string;
    genderCalculated: boolean;
    ssnValidated: boolean;
    ssnLookupPerformed: boolean;
    ssnLookupPossible: boolean;
    legalGuardian: boolean;
    legalGuardianChangeable: boolean;

    public constructor(init?: Partial<UpdateDateOfBirthOrAgeOrGenderOrNationalId>) {
        Object.assign(this, init);
        if (!init || !init.nationalIdCountry) {
            this.nationalIdCountry = new Country();
            this.nationalIdCountry.code = '';
            this.nationalIdCountry.name = '';
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class UpdateDateOfBirthOrAgeOrGenderOrNationalIdAdapter implements Adapter<UpdateDateOfBirthOrAgeOrGenderOrNationalId> {
    adapt(item: any): UpdateDateOfBirthOrAgeOrGenderOrNationalId {
      return new UpdateDateOfBirthOrAgeOrGenderOrNationalId({
            dateOfBirth: DaySerializer.deserialize(item.dateOfBirth),
            age: item.age,
            dateOfBirthCalculated: item.dateOfBirthCalculated,
            nationalId: item.nationalId,
            nationalIdCountry: item.nationalIdCountry,
            nationalIdValid: item.nationalIdValid,
            gender: item.gender,
            genderCalculated: item.genderCalculated,
            ssnValidated: item.ssnValidated,
            ssnLookupPerformed: item.ssnLookupPerformed,
            ssnLookupPossible: item.ssnLookupPossible,
            legalGuardian: item.legalGuardian,
            legalGuardianChangeable: item.legalGuardianChangeable,
        });
    }
}
