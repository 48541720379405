import { GroundAmbulanceServiceOrderExtensionAdapter } from './ground-ambulance-service-order-extension';
import { GroundTransportServiceOrderExtensionAdapter } from './ground-transport-service-order-extension';
import { ServiceOrderStatus, ServiceTypeEnum, StatusTypes } from './../enums';
import { Adapter } from '../../interfaces/adapter';
import { Injectable } from '@angular/core';
import { SupplierInvoice } from '../supplierInvoice';
import { ServiceOrderExtension } from './service-order-extension';
import { RegularFlightNonMedicalServiceOrderExtensionAdapter } from './regular-flight-non-medical-service-order-extension';
import { Location } from '@secca/shared/models/location';
import { MedicalAssessmentServiceOrderAdapter } from '@secca/shared/models/service-order/medical-assessment-service-order-extension';
import { TreatmentServiceOrderExtensionAdapter } from './treatment-service-order-extension';
import { cloneDeep } from 'lodash-es';
import { MortalRemainsServiceOrderExtensionAdapter } from './mortal-remains-service-order-extension';
import { ReferalToCustomerServiceOrderExtensionAdapter } from './referal-to-customer-service-order-extension';
import { GeneralAdviceServiceOrderExtensionAdapter } from './general-advice-service-order-extension';
import { MoneyTransferServiceOrderExtensionAdapter } from './money-transfer-service-order-extension';
import { RegularFlightMedicalServiceOrderExtensionAdapter } from '@secca/shared/models/service-order/regular-flight-medical-service-order-extension';
import { AccommodationServiceOrderExtensionAdapter } from '@secca/shared/models/service-order/accommodation-service-order-extension';
import { LuggageServiceOrderExtensionAdapter } from './luggage-service-order-extension';
import { MedicalEscortServiceOrderExtensionAdapter } from '@secca/shared/models/service-order/medical-escort-service-order-extension';
import { AmbulanceFlightServiceOrderExtensionAdapter } from '@secca/shared/models/service-order/ambulance-flight-service-order-extension';
import { AdviceOnInsuranceTermsServiceOrderExtensionAdapter } from './advice-on-insurance-terms';
import { SupplierExpensesAppendix } from '../supplierExpensesAppendix';
import * as moment from 'moment-timezone';
import { AssistanceOrContactServiceOrderExtensionAdapter } from "@secca/shared/models/service-order/assistance-or-contact-service-order-extension";
import { DateSerializer } from '../date-serializer';
import { OtherRefundRequestServiceOrderExtensionAdapter } from "@secca/shared/models/service-order/other-refund-request-service-order-extension";
import { AdviceCallRegistrationServiceOrderExtensionAdapter } from './advice-call-registration-service-order-extension';
import { ServiceOrderRefundViewModel } from '@secca/case/components/case-economy/case-economy-service-orders/service-order-refund-view-model';
import { PlanDisplayHelper } from '@secca/case/components/case-plans/plan/plan-display-helper';
import { MedicalPreAssessmentServiceOrderAdapter } from './medical-pre-assessment-service-order-extension';
import { TranslationServiceOrderExtensionAdapter } from '@secca/shared/models/service-order/translation-service-order-extension';
import { InvestigationServiceOrderExtensionAdapter } from '@secca/shared/models/service-order/investigation-service-order-extension';
import { PreDepartureAdviceServiceOrderAdapter, PreDepartureAdviceServiceOrderExtension } from './medical-pre-departure-advice-service-order-extension';
import {
  CoordinationTransportServiceOrderExtensionAdapter
} from '@secca/shared/models/service-order/coordination-transport-service-order-extension';
import { MedicalFlightAuthorizationServiceOrderExtension, MedicalFlightAuthorizationServiceOrderExtensionAdapter } from './medical-flight-authorization-service-order-extension';
import { MajorIncidentServiceOrderExtensionAdapter } from './major-incident-service-order-extension';

//TODO: rename it to ServiceOrderPlan when finish new service orders
export class ServiceOrder {
  id: number;
  caseId: number;
  type: ServiceTypeEnum;
  remarks: string;
  supplierId: number;
  coverageId: number;
  coverageTypeId: number;
  expectedCostCurrency: string;
  expectedCostValue: string;
  exchangedCostCurrency: string;
  exchangedCostValue: string;
  status: StatusTypes;
  requestSent: boolean;
  lockedForEdit: boolean;

  modifiedBy: string;
  modifiedOn: moment.Moment;
  deletedOn: moment.Moment;
  serviceOrderId: string;
  lastEditedByInitials: string;
  caseStakeholderIds: number[] = [];
  // extension
  extension: ServiceOrderExtension;

  // seems always to have one value
  invoicesStatus: ServiceOrderStatus = ServiceOrderStatus.expected;
  lastGop: string;
  // these are only sometimes being set
  supplierInvoices: SupplierInvoice[];
  supplierExpensesAppendixes: SupplierExpensesAppendix[];

  // these are only sometimes being set
  refunds: ServiceOrderRefundViewModel[];
  // supplierExpensesAppendixes: SupplierExpensesAppendix[];

  // used only by plans, shouldnt be here:
  serviceId: string;
  parentServiceId: string;
  coTravellersEndUserServiceId: string;
  overlappingGroupId: string;
  overlappingPosition: number;
  overlappingTempPosition: number;
  candidateOverlappingPosition: number;
  numberOfOverlappingServiceOrders: number;
  extendedHeight = 0;
  cellPosOffset = 0;
  cellHeight = 0;
  laneNumber: number;
  hidden = false;
  isDuplicated = false;
  useEndUserLaneForPlacementCalculation = false;
  isLinkedToIcc = false;

  public constructor(init?: Partial<ServiceOrder>) {
    Object.assign(this, init);
  }

  static createExtension(type: ServiceTypeEnum): ServiceOrderExtension {
    const adapter: ServiceOrderAdapter = new ServiceOrderAdapter();
    return adapter.adaptExtension(type, {});
  }

  public static cloneDeep(serviceOrder: ServiceOrder): ServiceOrder {
    return cloneDeep(serviceOrder);
  }

  getCaseStakeholderIds(): number[] {
    return this.caseStakeholderIds;
  }

  getStartDate(): moment.Moment {
    return this.extension.getStartDate(this.status);
  }

  getStartDisplayDate(): moment.Moment {
    return this.extension.getStartDisplayDate(this.status);
  }

  getStartLocation(): Location {
    return this.extension.getStartLocation(this.status);
  }

  hasCoTravellers(): boolean {
    return this.extension.hasCoTravellers(this.status);
  }

  getEndLocation(): Location {
    return this.extension.getEndLocation(this.status);
  }

  getEndDate(): moment.Moment {
    return this.extension.getEndDate(this.status);
  }

  getEndDisplayDate(): moment.Moment {
    return this.extension.getEndDisplayDate(this.status);
  }

  getStartDateLocal(): Date {
    const date = this.extension.getStartDate(this.status)?.toDate();
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  getEndDateLocal(): Date {
    const date = this.extension.getEndDate(this.status)?.toDate()
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  getSelectedStakeholderIds(): string[] {
    return this.extension.getSelectedStakeholderIds(this.status);
  }
}

@Injectable({
  providedIn: 'root',
})
export class ServiceOrderAdapter implements Adapter<ServiceOrder> {
  constructor() {}
  adapt(item: any): ServiceOrder {
    return new ServiceOrder({
      id: item.id,
      serviceId: null,
      caseStakeholderIds: item.caseStakeholderIds,
      type: this.getType(item.type),
      caseId: item.caseId != null ? item.caseId : null,
      serviceOrderId: item.serviceOrderId,
      modifiedBy: item.modifiedBy,
      modifiedOn: DateSerializer.deserialize(item.modifiedOn),
      lastEditedByInitials: item.lastEditedByInitials,
      lastGop: item.lastGop,
      extension: this.adaptExtension(this.getType(item.type), item.extension),
      coverageTypeId: item.coverageTypeId,
      expectedCostCurrency: item.expectedCostCurrency,
      expectedCostValue: item.expectedCostValue == null ? '0' : item.expectedCostValue.toString(),
      exchangedCostCurrency: item.exchangedCostCurrency,
      exchangedCostValue: item.exchangedCostValue == null ? null : item.exchangedCostValue.toString(),
      status: item.status,
      supplierId: item.supplierId,
      remarks: item.remarks,
      coverageId: item.coverageId,
      supplierInvoices: [],
      supplierExpensesAppendixes: [],
      requestSent: item.requestSent,
      lockedForEdit: item.lockedForEdit,
      deletedOn: DateSerializer.deserialize(item.deletedOn),
      laneNumber: this.getType(item.type) === ServiceTypeEnum.MEDICAL_ESCORT ? PlanDisplayHelper.MEDICAL_ESCORT_LANE : PlanDisplayHelper.END_USER_LANE,
      useEndUserLaneForPlacementCalculation: this.getType(item.type) === ServiceTypeEnum.MEDICAL_ESCORT
    });
  }
  private getType(type: any): ServiceTypeEnum {
    return ServiceTypeEnum[type as keyof typeof ServiceTypeEnum];
  }
  adaptExtension(type: ServiceTypeEnum, extension: any): ServiceOrderExtension {
    if (type === ServiceTypeEnum.REGULAR_FLIGHT_NON_MEDICAL) {
      const adapter = new RegularFlightNonMedicalServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.REGULAR_FLIGHT_MEDICAL) {
      const adapter = new RegularFlightMedicalServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.AMBULANCE_FLIGHT) {
      const adapter = new AmbulanceFlightServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.MEDICAL_ASSESSMENT) {
      const adapter = new MedicalAssessmentServiceOrderAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.MEDICAL_PRE_ASSESSMENT) {
      const adapter = new MedicalPreAssessmentServiceOrderAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.MEDICAL_ESCORT) {
      const adapter = new MedicalEscortServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.TREATMENT) {
      const adapter = new TreatmentServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.ASSISTANCE_OR_CONTACT) {
      const adapter = new AssistanceOrContactServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.GROUND_AMBULANCE) {
      const adapter = new GroundAmbulanceServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.GROUND_TRANSPORT) {
      const adapter = new GroundTransportServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.HANDLING_OF_MORTAL_REMAINS) {
      const adapter = new MortalRemainsServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.REFERRAL_TO_CUSTOMER) {
      const adapter = new ReferalToCustomerServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.GENERAL_ADVICE) {
      const adapter = new GeneralAdviceServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.ADVICE_CALL_REGISTRATION) {
      const adapter = new AdviceCallRegistrationServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.MONEY_TRANSFER) {
      const adapter = new MoneyTransferServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.ACCOMMODATION) {
      const adapter = new AccommodationServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.OTHER_REFUND_REQUEST) {
      const adapter = new OtherRefundRequestServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.LUGGAGE) {
      const adapter = new LuggageServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.ADVISE_ON_INSURANCE_TERMS) {
      const adapter = new AdviceOnInsuranceTermsServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.INVESTIGATION) {
      const adapter = new InvestigationServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.TRANSLATION) {
      const adapter = new TranslationServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.PRE_DEPARTURE_TRAVEL_SERVICE) {
      const adapter = new PreDepartureAdviceServiceOrderAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.COORDINATION_TRANSPORT) {
      const adapter = new CoordinationTransportServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.MEDICAL_FLIGHT_AUTHORIZATION) {
      const adapter = new MedicalFlightAuthorizationServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    if (type === ServiceTypeEnum.MAJOR_INCIDENT) {
      const adapter = new MajorIncidentServiceOrderExtensionAdapter();
      return adapter.adapt(extension);
    }
    else {
      return null;
    }
  }
}

@Injectable({
  providedIn: 'root',
})
//TODO: rename it to ServiceOrderMerger when finish new service orders
export class ServiceOrderModelMerger {
  constructor() {}
  merge(item: ServiceOrder, serviceTypeExtension: any): ServiceOrder {
    const finalServiceOrderModel = new ServiceOrder();
    Object.assign(finalServiceOrderModel, item);
    finalServiceOrderModel.extension = serviceTypeExtension;
    return finalServiceOrderModel;
  }
}
