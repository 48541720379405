import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {SettingsService} from './settings.service';
import {MomentHelperService} from "@secca/core/services/moment-helper.service";

@Injectable({
  providedIn: 'root'
})
export class ExchangeRateService extends BaseService {

  constructor(private http: HttpClient,
              private momentHelderService: MomentHelperService,
              private settingsService: SettingsService) {
    super(settingsService);
  }

  public getExchangeRateNow(source: String, target: String): Observable<any> {
    let date = MomentHelperService.getSeccaTime().format("YYYY-MM-DD")
    return this.http.get<any>(this.baseURL + 'exchange/rates/' + source + '/' + target + '/' + date);
  }
}
